<script setup lang="ts">
import { onMounted, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    modelValue: string | number | null;
    disabled?: boolean;
    appendText?: string;
    icon?: string;
    appendIcon?: string;
    placeholder?: string;
    type?: string;
    name?: string;
    autocomplete?: string;
    autofocus?: boolean;
    dense?: boolean;
  }>(),
  {
    modelValue: null,
    disabled: false,
    appendText: undefined,
    icon: undefined,
    appendIcon: undefined,
    placeholder: undefined,
    type: undefined,
    name: undefined,
    autocomplete: undefined,
    autofocus: false,
    dense: false,
  },
);
const emit = defineEmits([
  "update:modelValue",
  "blur",
  "click:icon",
  "click:appendText",
  "click:appendIcon",
]);

const value = ref<string | number | null>(props.modelValue);
const currentlyFocused = ref(false);
const input = ref();

watch(
  () => props.modelValue,
  () => {
    value.value = props.modelValue;
  },
);

const updateValue = () => {
  emit("update:modelValue", value.value);
};

onMounted(() => {
  if (props.autofocus) {
    (input.value as HTMLInputElement).focus();
  }
});

defineExpose({ click: () => input.value.focus() });
</script>

<template>
  <div
    class="flex items-center bg-white rounded border hover:bg-marine-25"
    :class="[
      currentlyFocused ? 'border-indigo-300' : 'hover:border-gray-300',
      dense ? 'p-2 rounded' : 'px-4 py-2.5',
    ]"
  >
    <i
      v-if="icon"
      class="mdi text-marine-600 pl-3 mdi-16px"
      :class="icon"
      @click="emit('click:icon', $event)"
    />
    <input
      ref="input"
      v-model="value"
      :name="name"
      :type="type"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      class="text-left min-w-0 border-none h-6 bg-transparent flex-1"
      :step="type === 'number' ? 0.01 : undefined"
      @focus="currentlyFocused = true"
      @blur="
        currentlyFocused = false;
        emit('blur');
      "
      @input="updateValue"
    />

    <div
      v-if="appendText"
      class="w-6"
      @click="emit('click:appendText', $event)"
    >
      {{ appendText }}
    </div>

    <i
      v-if="appendIcon"
      class="mdi text-marine-600 pr-3 mdi-16px"
      :class="appendIcon"
      @click="emit('click:appendIcon', $event)"
    />
  </div>
</template>
