import { createPinia } from "pinia";
import { createApp } from "vue";

import { registerDesignSystem } from "./components/designSystem";

import App from "./App.vue";
import usei18n from "./lib/i18n";
import { initIntercom } from "./lib/intercom";
import { initPosthog } from "./lib/posthog";
import { initSentry } from "./lib/sentry";
import router from "./router";

import "@mdi/font/css/materialdesignicons.css";
import "inter-ui/inter.css";
import "./index.css";

const app = createApp(App);
registerDesignSystem(app);
initSentry(app, router);
initPosthog(router);

app.use(usei18n);
app.use(createPinia());
app.use(router);

app.mount("#app");

if (import.meta.env.MODE === "production") {
  initIntercom();
}
