<script setup lang="ts">
import { computed, ref } from "vue";

import PasswordInputPill from "./PasswordInputPill.vue";

const props = defineProps<{
  modelValue: string | undefined;
}>();
const emit = defineEmits(["update:modelValue", "update:valid", "submit"]);

const password = ref(props.modelValue);
const showPassword = ref(false);
const passwordConfirm = ref();
const showPasswordConfirm = ref(false);

const passwordMismatchError = ref(false);

const passwordContainsUppercaseLetter = computed(() => {
  if (!password.value) return false;
  return /[A-Z]/.test(password.value);
});

const passwordContainsLowercaseLetter = computed(() => {
  if (!password.value) return false;
  return /[a-z]/.test(password.value);
});

const passwordContainsNumber = computed(() => {
  if (!password.value) return false;
  return /[0-9]/.test(password.value);
});

const passwordContainsSpecialCharacter = computed(() => {
  if (!password.value) return false;
  return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password.value);
});

const passwordLength = computed(() => {
  if (!password.value) return false;
  return password.value.length >= 8;
});

const passwordValid = computed(() => {
  if (password.value === undefined) return false;
  else if (password.value !== passwordConfirm.value) return false;
  else if (!passwordContainsUppercaseLetter.value) return false;
  else if (!passwordContainsLowercaseLetter.value) return false;
  else if (!passwordContainsNumber.value) return false;
  else if (!passwordContainsSpecialCharacter.value) return false;
  else if (!passwordLength.value) return false;

  return true;
});

const handleUpdate = () => {
  passwordMismatchError.value = false;
  emit("update:modelValue", password.value);
  emit("update:valid", passwordValid.value);
};

const handleSubmit = () => {
  emit("submit");
};

const handleBlur = () => {
  if (
    passwordConfirm.value !== undefined &&
    password.value !== undefined &&
    password.value.length !== 0 &&
    password.value !== passwordConfirm.value
  ) {
    passwordMismatchError.value = true;
  }
};
</script>

<template>
  <TransitionGroup name="slide-top" tag="div" class="relative min-h-[260px]">
    <TTextField
      key="password"
      v-model="password"
      :placeholder="$t('auth.password')"
      :type="showPassword ? 'text' : 'password'"
      class="mt-4"
      name="password"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-icon="showPassword = !showPassword"
      @keydown.enter="handleSubmit"
      @update:model-value="handleUpdate"
    />
    <div
      v-if="password !== undefined && password.length === 0"
      key="empty"
      class="mt-3 font-medium text-red-400"
    >
      {{ $t("auth.passwordEmpty") }}
    </div>
    <div
      v-else-if="password !== undefined"
      key="validation"
      class="mt-3 flex flex-wrap gap-2.5"
    >
      <PasswordInputPill :valid="passwordContainsUppercaseLetter">
        {{ $t("auth.oneUppercaseNeeded") }}
      </PasswordInputPill>
      <PasswordInputPill :valid="passwordContainsLowercaseLetter">
        {{ $t("auth.oneLowercaseNeeded") }}
      </PasswordInputPill>
      <PasswordInputPill :valid="passwordContainsSpecialCharacter">
        {{ $t("auth.oneSpecialCharacterNeeded") }}
      </PasswordInputPill>
      <PasswordInputPill :valid="passwordContainsNumber">
        {{ $t("auth.oneNumberNeeded") }}
      </PasswordInputPill>
      <PasswordInputPill :valid="passwordLength">
        {{ $t("auth.eightCharactersNeeded") }}
      </PasswordInputPill>
    </div>

    <TTextField
      key="password-confirmation"
      v-model="passwordConfirm"
      :placeholder="$t('auth.confirmPassword')"
      :type="showPasswordConfirm ? 'text' : 'password'"
      class="mt-4"
      name="password-confirmation"
      :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append-icon="showPasswordConfirm = !showPasswordConfirm"
      @update:model-value="handleUpdate"
      @keydown.enter="handleSubmit"
      @blur="handleBlur"
    />
    <div
      v-if="passwordMismatchError"
      key="empty"
      class="mt-3 font-medium text-red-400"
    >
      {{ $t("auth.passwordMismatch") }}
    </div>
  </TransitionGroup>
</template>
