import CrossIcon from '@/assets/imgs/icons/cross.svg';
import InfoFullIcon from '@/assets/imgs/icons/info-full.svg';
import WarningTriangleOutlineIcon from '@/assets/imgs/icons/warning-triangle-outline.svg';
import ArrowUpIcon from '@/assets/imgs/icons/arrow-up.svg';
import ArrowDownIcon from '@/assets/imgs/icons/arrow-down.svg';
import ArrowLeftIcon from '@/assets/imgs/icons/arrow-left.svg';
import ArrowRightIcon from '@/assets/imgs/icons/arrow-right.svg';
import ArrowDoubleIcon from '@/assets/imgs/icons/arrow-double.svg';
import NavArrowDownIcon from '@/assets/imgs/icons/nav-arrow-down.svg';
import NavArrowUpIcon from '@/assets/imgs/icons/nav-arrow-up.svg';
import InfoEmptyIcon from '@/assets/imgs/icons/info-empty.svg';
import LockIcon from '@/assets/imgs/icons/lock.svg';

export enum IconType {
    cross = 'cross',
    infoFull = 'info-full',
    warningTriangleOutline = 'warning-triangle-outline',
    arrowUp = 'arrow-up',
    arrowDown = 'arrow-down',
    arrowLeft = 'arrow-left',
    arrowRight = 'arrow-right',
    arrowDouble = 'arrow-double',
    navArrowDown = 'nav-arrow-down',
    navArrowUp = 'nav-arrow-up',
    infoEmpty = 'info-empty',
    lock = 'lock'
}

export enum IconSize {
    xs = 'var(--icon-size-xs)',
    sm = 'var(--icon-size-sm)',
    md = 'var(--icon-size-md)',
    lg = 'var(--icon-size-lg)',
    xl = 'var(--icon-size-xl)',
}

export const ICONS = {
    [IconType.cross]: CrossIcon,
    [IconType.infoFull]: InfoFullIcon,
    [IconType.warningTriangleOutline]: WarningTriangleOutlineIcon,
    [IconType.arrowUp]: ArrowUpIcon,
    [IconType.arrowDown]: ArrowDownIcon,
    [IconType.arrowLeft]: ArrowLeftIcon,
    [IconType.arrowRight]: ArrowRightIcon,
    [IconType.arrowDouble]: ArrowDoubleIcon,
    [IconType.navArrowDown]: NavArrowDownIcon,
    [IconType.navArrowUp]: NavArrowUpIcon,
    [IconType.infoEmpty]: InfoEmptyIcon,
    [IconType.lock]: LockIcon,
};
