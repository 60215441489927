<template>
  <div class="absolute h-full">
    <div class="z-30 flex justify-between px-4 pt-4 lg:hidden">
      <div />
      <TIcon icon="cross" class="cursor-pointer" @click="closeModal" />
    </div>
    <div
      class="flex h-full flex-col overflow-scroll lg:grid lg:grid-cols-10 lg:overflow-hidden"
    >
      <div class="lg:col-span-6 lg:max-h-[730px]">
        <SellModalTransaction
          v-model="transaction"
          @modal-close="closeModal"
          @next-step="continueToNextStep"
          @show-error="showError"
        />
      </div>
      <div class="lg:col-span-4 lg:h-full lg:overflow-hidden">
        <div class="p-4 pb-10 lg:h-full lg:pb-4">
          <SellModalTransactionPreview
            v-model="transaction"
            @next-step="continueToNextStep"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SellModalTransactionPreview from "@/containers/modals/sell/SellModalTransactionPreview.vue";
import SellModalTransaction from "@/containers/modals/sell/SellModalTransaction.vue";
import SellTransaction from "@/models/sell-transaction";
import { SellModalStep } from "@/constants/sell-modal-constants";

const transaction = defineModel<SellTransaction>({ required: true });

const emit = defineEmits(["modal-close", "next-step", "show-error"]);

const continueToNextStep = () => {
  emit("next-step", SellModalStep.bankInfo);
};

const showError = (message: string) => {
  emit("show-error", message);
};

const closeModal = () => {
  emit("modal-close");
};
</script>
