<template>
  <span id="tooltip" v-bind="$attrs" ref="tooltipElement">
    <slot />
  </span>

  <div ref="tooltipContent" :class="defaultTooltipClasses">
    <slot name="content">{{ text }}</slot>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import tippy, { type Placement } from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

const props = withDefaults(
  defineProps<{
    text?: string;
    placement?: Placement;
    tooltipClasses?: string;
    showArrow?: boolean;
    interactive?: boolean;
    variant?: "primary" | "primary-light";
  }>(),
  {
    placement: "auto",
    tooltipClasses: "",
    showArrow: true,
    interactive: false,
    variant: "primary",
    text: "",
  },
);

const tooltipElement = ref();
const tooltipContent = ref();

const defaultTooltipClasses = "text-sm font-medium";

onMounted(() => {
  tippy(tooltipElement.value, {
    content: tooltipContent.value,
    theme: props.variant,
    allowHTML: true,
    arrow: props.showArrow,
    placement: props.placement,
    interactive: props.interactive,
    animation: "scale",
  });
});
</script>
