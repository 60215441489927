<script setup lang="ts">
import { isAxiosError } from "axios";
import { onMounted, ref } from "vue";
import { useStorage } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router";

import axios from "@/lib/axios";

import Logo from "@/components/Logo.vue";
import PasswordInput from "@/components/auth/PasswordInput.vue";
import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import SignUpSlides from "@/containers/onboarding/SignUpSlides.vue";
import GoogleLogo from "@/components/misc/GoogleLogo.vue";

import background from "@/assets/background.png";

const route = useRoute();
const router = useRouter();

const displaySlides = useStorage("displaySlides", true);
const referalCode = useStorage<string | undefined>("referalCode", undefined);

const email = ref<string>();
const password = ref<string>();
const passwordValid = ref(false);
const loading = ref(false);

const dataError = ref(false);
const duplicateError = ref(false);

onMounted(() => {
  if (route.query.referal) {
    referalCode.value = route.query.referal as string;
  }
});

const signup = async () => {
  if (!password.value) return;
  else if (!passwordValid.value) return;

  loading.value = true;
  try {
    await axios.post("/auth/signup", {
      email: email.value,
      password: password.value,
      referal_code: referalCode.value,
    });
    await router.push("/");
  } catch (e) {
    if (isAxiosError(e)) {
      if (e.response?.status === 422) {
        dataError.value = true;
      } else if (e.response?.status === 409) {
        duplicateError.value = true;
      }
    }
  } finally {
    loading.value = false;
  }
};

const signupWithGoogle = () => {
  let location = `${axios.defaults.baseURL}auth/google/login`;
  if (route.query.referal) {
    location = location.concat(`?referal_code=${route.query.referal}`);
  }
  window.location.href = location;
};
</script>

<template>
  <div
    class="h-full w-full bg-cover"
    :style="{ backgroundImage: displaySlides ? `url(${background})` : '' }"
  >
    <SignUpSlides v-if="displaySlides" @completed="displaySlides = false" />

    <Transition appear name="fade-slow">
      <div v-if="!displaySlides" class="flex h-full w-full overflow-hidden p-4">
        <OnboardingSidebar current-step="sign-up" />

        <div
          class="relative flex h-screen flex-1 flex-col items-center overflow-y-auto py-1 sm:px-8"
        >
          <div class="flex w-full max-w-lg items-center justify-center">
            <a href="https://homaio.com">
              <Logo />
            </a>
          </div>

          <div class="mt-16 flex w-full flex-1 justify-center">
            <div
              class="relative h-full w-full max-w-xl py-16 text-marine-800 md:min-w-[576px]"
            >
              <div class="font-display text-5xl font-bold">
                {{ $t("auth.becomeCarbonInvestor.first") }}
                <br />
                {{ $t("auth.becomeCarbonInvestor.second") }}
              </div>
              <div class="mt-3 font-display font-medium">
                {{ $t("auth.alreadyHaveAccount") }}
                <RouterLink
                  :to="{ name: 'login' }"
                  class="font-bold text-indigo-400"
                >
                  {{ $t("auth.signIn") }}
                </RouterLink>
              </div>

              <TBtn
                class="mt-10 w-full"
                large
                outlined
                @click="signupWithGoogle"
              >
                <div class="flex justify-center">
                  <GoogleLogo />
                </div>
              </TBtn>

              <div class="my-5 flex w-full items-center">
                <div class="h-[1px] flex-1 bg-marine-200" />
                <div class="px-4 text-marine-400">
                  {{ $t("auth.continueWith") }}
                </div>
                <div class="h-[1px] flex-1 bg-marine-200" />
              </div>

              <div class="mt-4">
                <TTextField
                  v-model="email"
                  :placeholder="$t('auth.email')"
                  name="email"
                  autofocus
                  @update:model-value="
                    dataError = false;
                    duplicateError = false;
                  "
                />
                <PasswordInput
                  v-model="password"
                  @update:valid="passwordValid = $event"
                  @submit="signup"
                />
              </div>

              <TBtn
                class="w-full"
                large
                :disabled="!email || !password || !passwordValid || loading"
                @click="signup"
              >
                <template v-if="!loading">
                  {{ $t("global.continue") }}
                </template>
                <template v-else>
                  {{ $t("auth.signingUp") }}
                </template>
              </TBtn>

              <div
                v-if="dataError"
                class="mt-4 w-full rounded bg-red-400 px-6 py-4 font-medium text-white shadow"
              >
                {{ $t("auth.emailOrPasswordIncorrect") }}
              </div>
              <div
                v-else-if="duplicateError"
                class="mt-4 w-full rounded bg-red-400 px-6 py-4 font-medium text-white shadow"
              >
                {{ $t("auth.emailAlreadyRegistred") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<style scoped>
.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1.4s ease;
}

.fade-slow-enter-from,
.fade-slow-leave-to {
  opacity: 0;
}

.fade-slow-enter-to,
.fade-slow-leave-from {
  opacity: 1;
}
</style>
