<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6569 6.34313C17.9497 6.63603 17.9497 7.1109 17.6569 7.40379L13.0607 12L17.6569 16.5962C17.9497 16.8891 17.9497 17.3639 17.6569 17.6568C17.364 17.9497 16.8891 17.9497 16.5962 17.6568L12 13.0606L7.40381 17.6568C7.11091 17.9497 6.63604 17.9497 6.34315 17.6568C6.05025 17.3639 6.05025 16.8891 6.34315 16.5962L10.9393 12L6.34315 7.40379C6.05025 7.1109 6.05025 6.63603 6.34315 6.34313C6.63604 6.05024 7.11091 6.05024 7.40381 6.34313L12 10.9393L16.5962 6.34313C16.8891 6.05024 17.364 6.05024 17.6569 6.34313Z"
      fill="white"
    />
  </svg>
</template>
