import { createRouter, createWebHistory } from "vue-router";

import { useAuthStore } from "@/stores/auth";
import { usePortfolioStore } from "@/stores/portfolio";

import HomeView from "@/views/HomeView.vue";

import LoginView from "@/views/auth/LoginView.vue";
import ResetPasswordView from "@/views/auth/ResetPasswordView.vue";
import VerifyView from "@/views/auth/VerifyView.vue";

import DepositView from "@/views/onboarding/DepositView.vue";
import FirstInvestView from "@/views/onboarding/FirstInvestView.vue";
import KYCView from "@/views/onboarding/KYCView.vue";
import ProfileCreationView from "@/views/onboarding/ProfileCreationView.vue";
import SignatureView from "@/views/onboarding/SignatureView.vue";
import SignupView from "@/views/onboarding/SignupView.vue";
import WaitingClosingView from "@/views/onboarding/WaitingClosingView.vue";
import WaitlistView from "@/views/onboarding/WaitlistView.vue";

import NotFoundView from "@/views/NotFoundView.vue";
import PortfolioView from "@/views/PortfolioView.vue";
import {useKycStore} from "@/stores/kyc";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginView,
      meta: {
        auth: true,
        fullscreen: true,
      },
    },
    {
      path: "/reset",
      name: "reset",
      component: ResetPasswordView,
      meta: {
        auth: true,
        fullscreen: true,
      },
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupView,
      meta: {
        auth: true,
        fullscreen: true,
      },
    },
    {
      path: "/verify",
      name: "verify",
      component: VerifyView,
      meta: {
        auth: true,
        fullscreen: true,
      },
    },

    {
      path: "/onboarding/profile",
      name: "onboarding-profile",
      component: ProfileCreationView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/waitlist",
      name: "onboarding-waitlist",
      component: WaitlistView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/invest",
      name: "onboarding-invest",
      component: FirstInvestView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/kyc",
      name: "onboarding-kyc",
      component: KYCView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/signature",
      name: "onboarding-signature",
      component: SignatureView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/deposit",
      name: "onboarding-deposit",
      component: DepositView,
      meta: {
        fullscreen: true,
      },
    },
    {
      path: "/onboarding/closing",
      name: "onboarding-closing",
      component: WaitingClosingView,
      meta: {
        fullscreen: true,
      },
    },

    {
      path: "/",
      name: "home",
      component: HomeView,
    },
    {
      path: "/portfolio",
      name: "portfolio",
      component: PortfolioView,
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404",
      component: NotFoundView,
      meta: {
        fullscreen: true,
      },
    },
  ],
});

let dataLoaded = false;

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  const kycStore = useKycStore();
  const portfolioStore = usePortfolioStore();

  const user = await authStore.loadUser();
  if (!user) {
    if (!to.meta.auth) {
      next({ name: "login" });
      return;
    }
  } else {
    await kycStore.loadKyc()
    await portfolioStore.loadPortfolio();
    // @ts-ignore
    if (window.Intercom) {
      // @ts-ignore
      window.Intercom("update", {
        email: user.email,
        user_id: user.id,
        phone: user.phone,
      });
    }

    if (to.name === "verify") {
      next();
      return;
    } else if (to.meta.auth) {
      await authStore.logout();
      next();
      return;
    } else if (user.status !== "active") {
      if (user.status === "signed_up" && to.name !== "onboarding-profile") {
        next({ name: "onboarding-profile" });
        return;
      } else if (
        user.status === "waiting" &&
        to.name !== "onboarding-waitlist"
      ) {
        next({ name: "onboarding-waitlist" });
        return;
      } else if (
        user.status === "onboarded" &&
        to.name !== "onboarding-invest"
      ) {
        next({ name: "onboarding-invest" });
        return;
      } else if (
        user.status === "kyc_pending" &&
        to.name !== "onboarding-kyc"
      ) {
        next({ name: "onboarding-kyc" });
        return;
      } else if (
        user.status === "kyc_done" &&
        !["onboarding-kyc", "onboarding-signature"].includes(to.name as string)
      ) {
        next({ name: "onboarding-kyc" });
        return;
      } else if (
        user.status === "signature_pending" &&
        !["onboarding-kyc", "onboarding-signature"].includes(to.name as string)
      ) {
        next({ name: "onboarding-kyc" });
        return;
      } else if (
        user.status === "deposit_pending" &&
        to.name !== "onboarding-deposit"
      ) {
        next({ name: "onboarding-deposit" });
        return;
      } else if (
        user.status === "waiting_closing" &&
        to.name !== "onboarding-closing"
      ) {
        next({ name: "onboarding-closing" });
        return;
      }
    } else if (!dataLoaded) {
      const portfolioStore = usePortfolioStore();
      portfolioStore.fetchTransactions();
      portfolioStore.fetchLastPrice();
      dataLoaded = true;
    }
  }

  next();
});

export default router;
