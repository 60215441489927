import { type Portfolio } from "@/types/portfolio";
import { type EstimatedSellingData } from "@/types/estimated-selling-data";

export default class SellTransaction {
  maxBond: number;
  euaToSell: number;
  euaPrice: number;
  bondToSell: number;
  fee: number;
  transactionLocked: boolean;
  bankInfoVerified: boolean;
  hasError: boolean;
  errorMessage: string;

  private constructor(transactionData: {
    maxBond: number;
    euaPerBond: number;
    euaPrice: number;
    bondToSell: number;
    fee: number;
    transactionLocked: boolean;
    bankVerified: boolean;
    hasError: boolean;
    errorMessage: string;
  }) {
    this.maxBond = transactionData.maxBond;
    this.euaToSell = transactionData.euaPerBond;
    this.euaPrice = transactionData.euaPrice;
    this.bondToSell = transactionData.bondToSell;
    this.fee = transactionData.fee;
    this.transactionLocked = transactionData.transactionLocked;
    this.bankInfoVerified = transactionData.bankVerified;
    this.hasError = transactionData.hasError;
    this.errorMessage = transactionData.errorMessage;
  }

  static fromDefault(): SellTransaction {
    return new SellTransaction({
      maxBond: 100,
      euaPerBond: 20.23,
      euaPrice: 12.26,
      bondToSell: 0,
      fee: 0,
      transactionLocked: false,
      bankVerified: false,
      hasError: false,
      errorMessage: "",
    });
  }

  static fromPortfolio(portfolio: Portfolio): SellTransaction {
    return new SellTransaction({
      maxBond: portfolio.availableBonds,
      euaPerBond: 0,
      euaPrice: 0,
      bondToSell: 0,
      fee: 0,
      transactionLocked: false,
      bankVerified: false,
      hasError: false,
      errorMessage: "",
    });
  }

  updateWithEstimation(estimation: EstimatedSellingData) {
    this.euaPrice = estimation.sellingPrice;
    this.euaToSell = estimation.sellingAssetsQuantity;
    this.fee = estimation.custodyFees;
  }

  get estimatedValue(): string {
    return this.euaPrice.toFixed(2);
  }
}
