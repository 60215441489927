<script setup lang="ts">
import { ref } from "vue";

import type { Upload } from "@/types/upload";

const emit = defineEmits<{
  (event: "upload", upload: Upload | undefined): void;
}>();

const overDrop = ref(false);
const fileUploadInput = ref<HTMLElement | null>(null);
const fileName = ref<string>();

const handleDropEvent = (event: DragEvent) => {
  overDrop.value = false;
  if (event.dataTransfer) {
    emitFile(event.dataTransfer.files[0]);
  }
};

const handleFileInputEvent = (event: Event) => {
  if (event.target) {
    emitFile((event.target as HTMLInputElement).files![0]);
  }
};

const emitFile = (file: File) => {
  fileName.value = file.name;
  const reader = new FileReader();
  reader.addEventListener("load", (event) => {
    emit("upload", {
      url: event.target!.result as string,
      base64: (event.target!.result! as string).replace(
        /^data:?[A-z/]*;base64,/,
        "",
      ),
      name: file.name,
    });
  });
  reader.readAsDataURL(file);
};

const removeFile = () => {
  fileName.value = undefined;
  emit("upload", undefined);
};
</script>

<template>
  <div
    class="w-full rounded-lg border-2 border-dashed flex items-center justify-center flex-col transition-colors"
    :class="[
      fileName ? 'bg-indigo-50 py-8' : 'cursor-pointer py-6',
      overDrop
        ? 'border-indigo-300 bg-indigo-50'
        : 'border-marine-200 hover:border-indigo-300',
    ]"
    @drop.prevent="handleDropEvent($event)"
    @dragover.prevent="overDrop = true"
    @dragleave.prevent="overDrop = false"
    @click="fileUploadInput!.click()"
  >
    <input
      ref="fileUploadInput"
      name="upload-file"
      type="file"
      hidden
      onclick="this.value = null;"
      @change="handleFileInputEvent($event)"
    />

    <template v-if="!fileName">
      <i class="mdi mdi-upload text-2xl text-marine-600" />
      <div class="mt-2 font-semibold text-marine-600 text-sm">
        Drag and drop here
      </div>
      <div class="font-semibold text-marine-400 text-xs">
        or click to select from your computer
      </div>
      <div class="mt-4 text-marine-400 text-xs">
        We accept PDF, PNG and JPEG files.
      </div>
    </template>
    <template v-else>
      <i class="mdi mdi-file text-2xl text-marine-700" />
      <div class="mt-2 font-semibold text-marine-700 text-sm">
        {{ fileName }}
      </div>
      <div
        class="mt-4 text-marine-400 text-xs cursor-pointer"
        @click.stop="removeFile"
      >
        Remove file
      </div>
    </template>
  </div>
</template>
