<script setup lang="ts">
import dayjs from "dayjs";
import { ref } from "vue";

import axios from "@/lib/axios";

import Referal from "@/containers/onboarding/Referal.vue";
import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import OnboardingHeader from "@/containers/onboarding/OnboardingHeader.vue";

const nextBatchEnd = ref<dayjs.Dayjs>();

const position = ref<number>();
const referalsSent = ref<number>();
const referalsActivated = ref<number>();

const getWaitlistStatus = async () => {
  try {
    const request = await axios.get("/user/waitlist");
    position.value = request.data.position;
    referalsSent.value = request.data.referalsSent;
    referalsActivated.value = request.data.referalsActivated;
  } catch (e) {
    console.error(e);
  }
};

getWaitlistStatus();

const getActiveBatch = async () => {
  try {
    const request = await axios.get("/batches/active");
    nextBatchEnd.value = dayjs(request.data.closingDate);
  } catch (e) {
    console.error(e);
  }
};

getActiveBatch();

const formatPosition = (position: number) => {
  const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

  const suffixes = new Map([
    ["one", "st"],
    ["two", "nd"],
    ["few", "rd"],
    ["other", "th"],
  ]);

  const rule = pr.select(Math.floor(position));
  const suffix = suffixes.get(rule);
  return `${position.toFixed()}${suffix}`;
};
</script>

<template>
  <div class="flex h-full w-full">
    <OnboardingSidebar current-step="waitlist" />

    <div class="flex flex-1 flex-col items-center p-6 sm:p-8">
      <OnboardingHeader />

      <div class="mt-16 flex w-full flex-1 justify-center">
        <div
          class="relative h-full w-full max-w-2xl py-16 text-marine-800 md:min-w-[672px]"
        >
          <div class="max-w-2xl font-display">
            <div class="text-4xl font-bold text-marine-800">
              Just a moment... ⏳
            </div>
            <div class="mt-3 text-sm font-medium text-marine-500">
              We are currently restricting each investment round to 150
              investors to provide the best possible experience.
            </div>
          </div>

          <div class="mt-8 max-w-2xl">
            <div class="text-2xl font-medium text-marine-700">
              You are currently in
              <span class="font-bold">
                <TCounter
                  v-if="position !== undefined"
                  :value="position"
                  :formatter="formatPosition"
                />
                position
              </span>
              to access our next carbon investment opportunity!
            </div>
            <div class="mt-3 text-sm text-marine-400">
              We will you keep you posted when it is your turn to enter an
              investment round!
            </div>
          </div>

          <div
            class="mb-6 mt-12 items-center rounded border bg-white p-4 md:flex"
          >
            <div class="font-semibold text-marine-500">Next round closing</div>
            <div class="flex-1" />
            <div
              v-if="nextBatchEnd"
              class="mt-2 font-medium text-marine-400 md:mt-0"
            >
              {{ nextBatchEnd.format("DD MMM YYYY") }}
            </div>
            <div v-else class="h-6 w-20 rounded bg-marine-100" />
          </div>

          <Referal
            v-if="referalsSent !== undefined && referalsActivated !== undefined"
            :referals-sent="referalsSent"
            :referals-activated="referalsActivated"
            @referal="getWaitlistStatus"
          />
        </div>
      </div>
    </div>
  </div>
</template>
