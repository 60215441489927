<script setup lang="ts">
import { computed } from "vue";
import Warning from "@/components/Warning.vue";
import Loader from "@/components/Loader.vue";

const props = withDefaults(
  defineProps<{
    title: string;
    text: string;
    type: "success" | "error" | "warning" | "info";
    confirmText: string;
    cancelText: string;
  }>(),
  {
    type: "success",
  },
);

const emit = defineEmits(["cancel", "confirm"]);

const confirm = () => {
  emit("confirm");
};

const cancel = () => {
  emit("cancel");
};

const iconComponent = computed(() => {
  const icons = {
    warning: Warning,
    info: Loader,
    success: Loader,
  };

  const key = props.type as keyof typeof icons;

  return icons[key];
});

const bgColor = computed(() => {
  const colors = {
    success: "bg-[#E0F4F0] border border-[#DEE2F7]",
    info: "bg-[#F2F3FC] border border-[#DEE2F7]",
    error: "bg-red-25",
    warning: "bg-[#FFF7ED]",
  };

  return colors[props.type];
});

const titleColor = computed(() => {
  const colors = {
    success: "text-[#1A5F46]",
    info: "text-[#4458D5]",
    error: "text-red-25",
    warning: "text-[#F97316]",
  };

  return colors[props.type];
});

const textColor = computed(() => {
  const colors = {
    success: "text-[#1A5F46]",
    info: "text-[#7080DE]",
    error: "text-red-25",
    warning: "text-[#FB923C]",
  };

  return colors[props.type];
});

const confirmBtn = computed(() => {
  const colors = {
    success: "bg-green-25",
    error: "bg-red-25",
    info: "text-marine-600 border border-marine-100",
    warning: "bg-[#F97316] text-white",
  };

  return colors[props.type];
});

const onlyConfirmButton = computed(() => {
  return !props.cancelText;
});
</script>

<template>
  <div
    :class="`flex w-full flex-row flex-wrap justify-center gap-4 rounded-lg p-6 ${bgColor}`"
  >
    <div><component :is="iconComponent" :class="textColor" /></div>
    <div class="flex grow basis-4/6 flex-col">
      <div :class="`font-display font-bold ${titleColor}`">
        {{ props.title }}
      </div>
      <div :class="`font-medium ${textColor} text-sm`">
        {{ props.text }}
      </div>
    </div>
    <div
      class="flex flex-row gap-2"
      :class="{
        'basis-1/5': onlyConfirmButton,
        'basis-1/4': !onlyConfirmButton,
      }"
    >
      <button
        v-if="props.cancelText"
        class="block rounded-md border border-marine-100 px-4 py-3 font-display text-sm font-bold"
        @click="cancel"
      >
        {{ props.cancelText }}
      </button>
      <button
        v-if="props.confirmText"
        :class="`block rounded-lg ${confirmBtn} grow px-4 py-3 font-display text-sm font-bold tracking-[0.16px] `"
        @click.stop="confirm"
      >
        {{ props.confirmText }}
      </button>
    </div>
  </div>
</template>
