import axios from "@/lib/axios";
import { defineStore } from "pinia";

import { ref } from "vue";
import { type EstimatedSellingData } from "@/types/estimated-selling-data";
import { type PlaceSellOrderBody } from "@/types/place-sell-order-body";

export const useOrdersStore = defineStore("orders", () => {
  const latestOrder = ref();
  const currentSellOrder = ref();

  const getLatestOrder = async () => {
    try {
      const orders = await axios.get("/orders/");
      latestOrder.value = orders.data.at(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const getCurrentSellOrder = async () => {
    try {
      const order = await axios.get("/orders/current/sell");
      currentSellOrder.value = order;
    } catch (e) {
      console.error(e);
    }
  };

  const sellEua = async (bondsQuantity: number): Promise<boolean> => {
    try {
      const payload: PlaceSellOrderBody = {
        bonds: bondsQuantity,
        product: "eua",
      };
      await axios.post("/orders/sell", payload);
    } catch (e) {
      return false;
    }

    return true;
  };

  const getEstimatedSellingData = async (
    bonds: number,
  ): Promise<EstimatedSellingData | null> => {
    try {
      const result = await axios.get("/orders/sell/estimate", {
        params: { bonds },
      });
      return result.data as EstimatedSellingData;
    } catch (e) {
      console.error(e);
    }

    return null;
  };

  const deleteOrder = async (orderId: number) => {
    try {
      await axios.delete(`/orders/${orderId}`);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getLatestOrder,
    latestOrder,
    deleteOrder,
    getEstimatedSellingData,
    sellEua,
    currentSellOrder,
    getCurrentSellOrder,
  };
});
