<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";

import LineChart from "@/components/charts/LineChart.vue";

import type { MarketPrice } from "@/types/products";
import { TransactionAccount, TransactionType } from "@/types/transaction";

import { usePortfolioStore } from "@/stores/portfolio";

import axios from "@/lib/axios";
import {
  getCurrencyFormatter,
  getNumberFormatter,
  getPercentageFormatter,
} from "@/utils/numbers";

const portfolioStore = usePortfolioStore();
const { transactions } = storeToRefs(portfolioStore);

enum Timespan {
  MONTH = "month",
  YTD = "ytd",
  YEAR = "year",
  TWO_YEARS = "two_years",
  FIVE_YEARS = "five_years",
}

const timespan = ref<Timespan>(Timespan.MONTH);
const prices = ref<MarketPrice[]>();

const fetchPrices = async () => {
  prices.value = undefined;
  try {
    const request = await axios.get("/products/eua/price/history", {
      params: { timespan: timespan.value },
      cancelPreviousRequests: true,
    });
    prices.value = request.data;
  } catch (e) {
    console.error(e);
  }
};

fetchPrices();

const lineChart = ref<InstanceType<typeof LineChart> | null>(null);
const mousePositionValue = computed(() => {
  return lineChart.value?.mousePositionValue;
});

// Values

const portfolioQuantities = computed(() => {
  if (!prices.value) return;
  else if (!transactions.value) return;

  return prices.value.map((price) => {
    const EURquantity = transactions
      .value!.filter((t) => t.date <= price.timestamp)
      .reduce((s, t) => {
        if (t.to_account === TransactionAccount.EURO) return s + t.to_amount;
        else if (t.from_account === TransactionAccount.BATCH)
          return s - t.from_amount;
        return s;
      }, 0);
    const EUAquantity = transactions
      .value!.filter((t) => t.date <= price.timestamp)
      .reduce((s, t) => {
        if (t.to_account === TransactionAccount.EUA) return s + t.to_amount;
        else if (t.from_account === TransactionAccount.EUA)
          return s - t.from_amount;
        return s;
      }, 0);
    return { eua: EUAquantity, eur: EURquantity };
  });
});

const portfolioValues = computed(() => {
  if (!prices.value) return;
  else if (!portfolioQuantities.value) return;

  return prices.value.map((price, index) => {
    return {
      timestamp: price.timestamp,
      value:
        price.price * portfolioQuantities.value![index].eua +
        portfolioQuantities.value![index].eur,
    };
  });
});

// Global KPI

const diffFromStart = computed(() => {
  if (!portfolioValues.value) return 0;
  else if (!transactions.value) return 0;

  if (!mousePositionValue.value) {
    const totalInvested = transactions.value
      .filter((t) => t.type === TransactionType.DEPOSIT)
      .filter(
        (t) => dayjs(t.date) >= dayjs(portfolioValues.value![0].timestamp),
      )
      .reduce((s, t) => s + t.to_amount, 0);
    return (
      portfolioValues.value[portfolioValues.value.length - 1].value -
      portfolioValues.value[0].value -
      totalInvested
    );
  } else {
    const totalInvested = transactions.value
      .filter((t) => t.type === TransactionType.DEPOSIT)
      .filter(
        (t) =>
          dayjs(t.date) >= dayjs(portfolioValues.value![0].timestamp) &&
          dayjs(t.date) <= mousePositionValue.value!.date,
      )
      .reduce((s, t) => s + t.to_amount, 0);
    return (
      mousePositionValue.value.value -
      portfolioValues.value[0].value -
      totalInvested
    );
  }
});

const diffFromStartPercentage = computed(() => {
  if (diffFromStart.value === undefined) return 0;
  else if (!transactions.value) return 0;

  if (!mousePositionValue.value) {
    const totalInvested = transactions.value
      .filter((t) => t.type === TransactionType.DEPOSIT)
      .reduce((s, t) => s + t.to_amount, 0);
    return diffFromStart.value / totalInvested;
  } else {
    const totalInvested = transactions.value
      .filter((t) => t.type === TransactionType.DEPOSIT)
      .filter((t) => dayjs(t.date) <= mousePositionValue.value!.date)
      .reduce((s, t) => s + t.to_amount, 0);
    return diffFromStart.value / totalInvested;
  }
});
</script>

<template>
  <div class="w-full rounded-lg border bg-white p-4 shadow-sm sm:p-6">
    <div class="flex items-center font-display font-semibold text-marine-700">
      {{ $t("portfolio.historical") }}
    </div>
    <div class="mt-6 flex flex-col gap-3 md:flex-row md:items-center">
      <div v-if="portfolioValues !== undefined">
        <span class="text-3xl font-bold text-marine-800 sm:text-4xl">
          <template v-if="!mousePositionValue">
            {{
              getCurrencyFormatter("EUR").format(
                portfolioValues[portfolioValues.length - 1].value,
              )
            }}
          </template>
          <template v-else>
            {{ getCurrencyFormatter("EUR").format(mousePositionValue.value) }}
          </template>
        </span>
        <span
          v-if="diffFromStart !== 0"
          class="ml-2 text-xs font-bold text-green-400 sm:text-sm"
          :class="diffFromStart > 0 ? 'text-green-400' : 'text-red-400'"
        >
          <template v-if="diffFromStart > 0">
            +{{ getCurrencyFormatter("EUR").format(diffFromStart) }} (+{{
              getPercentageFormatter().format(diffFromStartPercentage)
            }})
          </template>
          <template v-else>
            {{ getCurrencyFormatter("EUR").format(diffFromStart) }} ({{
              getPercentageFormatter().format(diffFromStartPercentage)
            }})
          </template>
        </span>
      </div>
      <div v-else class="h-10 w-56 rounded bg-gray-100"></div>

      <div class="flex-1" />

      <div
        class="flex items-center overflow-x-auto rounded-lg border border-gray-300 font-display font-semibold shadow-sm"
      >
        <button
          v-for="(availableTimespan, index) in [
            {
              value: Timespan.MONTH,
              text: $t('portfolio.timespans.month.text', 1),
              smallText: $t('portfolio.timespans.month.smallText', 1),
            },
            {
              value: Timespan.YTD,
              text: $t('portfolio.timespans.ytd.text'),
              smallText: $t('portfolio.timespans.ytd.smallText'),
            },
            {
              value: Timespan.YEAR,
              text: $t('portfolio.timespans.year.text', 1),
              smallText: $t('portfolio.timespans.year.smallText', 1),
            },
            {
              value: Timespan.TWO_YEARS,
              text: $t('portfolio.timespans.year.text', 2),
              smallText: $t('portfolio.timespans.year.smallText', 2),
            },
            {
              value: Timespan.FIVE_YEARS,
              text: $t('portfolio.timespans.year.text', 5),
              smallText: $t('portfolio.timespans.year.smallText', 5),
            },
          ]"
          :key="availableTimespan.value"
          type="button"
          class="inline-flex h-9 flex-grow items-center justify-center whitespace-nowrap border-gray-300 px-5 text-sm leading-normal transition"
          :class="[
            timespan === availableTimespan.value
              ? 'bg-marine-800 text-white'
              : 'bg-transparent text-marine-700 hover:bg-marine-25 hover:text-marine-900',
            index === 0 ? '' : 'border-l',
          ]"
          @click="
            timespan = availableTimespan.value;
            fetchPrices();
          "
        >
          <span class="hidden lg:inline">
            {{ availableTimespan.text }}
          </span>
          <span class="lg:hidden">
            {{ availableTimespan.smallText }}
          </span>
        </button>
      </div>
    </div>

    <LineChart
      ref="lineChart"
      class="mt-4"
      :values="portfolioValues"
      :legend-formatter="
        (date: dayjs.Dayjs) =>
          Timespan.MONTH == timespan ? date.format('MMM D') : date.format('MMM')
      "
    >
      <template #tooltip="{ value }">
        <div class="whitespace-nowrap text-sm font-medium text-marine-400">
          {{ value.date.format("DD MMM YYYY") }}
          <template v-if="Timespan.MONTH === timespan">
            {{ value.date.format("HH:mm") }}
          </template>
        </div>
        <div v-if="portfolioQuantities && prices" class="mt-3">
          <div class="flex items-center text-marine-600">
            <i class="mdi mdi-chart-line-variant mr-2" />
            EUA
            <span class="ml-4 font-medium">
              {{
                getNumberFormatter().format(
                  portfolioQuantities[value.index].eua,
                )
              }}
            </span>
            <span class="ml-4 font-medium text-marine-400">
              ({{
                getCurrencyFormatter("EUR").format(prices[value.index].price)
              }})
            </span>
          </div>
        </div>
      </template>
    </LineChart>
  </div>
</template>
