<template>
  <div class="block">
    <template v-for="step in steps" :key="step.key">
      <div class="flex items-center gap-4">
        <div>
          <div
            v-if="showTopConnector(step)"
            class="mb-[3px] flex w-2 justify-center"
          >
            <div class="h-full min-h-4 w-[1.5px] bg-marine-50" />
          </div>
          <div class="h-[10px] w-[10px] rounded-full bg-marine-50" />
          <div
            v-if="showBottomConnector(step)"
            class="mt-[3px] flex w-2 justify-center"
          >
            <div class="h-full min-h-4 w-[1.5px] bg-marine-50" />
          </div>
        </div>
        <div
          class="flex gap-1"
          :class="{
            'mt-[-20px]': firstStepKey === step.key,
            'mb-[-20px]': lastStepKey === step.key,
          }"
        >
          <p class="text-marine-600">
            {{ step.label }}
            <span v-if="step.suffix" class="font-semibold text-marine-800">{{
              step.suffix
            }}</span>
          </p>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

type Step = { key: string; label: string; suffix?: string };

const props = withDefaults(
  defineProps<{
    steps: Step[];
    showLastConnector?: boolean;
  }>(),
  {
    steps: () => [],
    showLastConnector: false,
  },
);

const lastStepKey = computed(() => {
  const lastIndex = props.steps.length - 1;
  return props.steps[lastIndex].key;
});

const firstStepKey = computed(() => {
  return props.steps[0].key;
});

const showBottomConnector = (step: Step) => {
  return step.key !== lastStepKey.value || props.showLastConnector;
};

const showTopConnector = (step: Step) => {
  return step.key !== firstStepKey.value;
};
</script>
