<script setup lang="ts">
import { storeToRefs } from "pinia";

import Avatar from "@/components/auth/Avatar.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";

import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const logout = async () => {
  await authStore.logout();

  // @ts-ignore
  if (window.Intercom) {
    // @ts-ignore
    window.Intercom("shutdown");
  }
  window.location.href = "/";
};
</script>

<template>
  <div class="flex items-center text-sm">
    <div class="mr-4 mt-1 text-right hidden sm:block">
      <div class="font-semibold text-marine-900">
        {{ user?.firstName }}
        {{ user?.lastName }}
      </div>
      <div class="text-marine-500 font-medium">
        {{ $t("auth.carbonInvestor") }}
      </div>
    </div>
    <TMenu
      :items="[{ value: 'log-out', text: $t('auth.logOut') }]"
      @click:item="logout"
    >
      <Avatar />
    </TMenu>
    <LocaleSelector />
  </div>
</template>
