<template>
  <div class="flex h-full flex-col">
    <div class="hidden justify-between py-4 pl-4 lg:flex">
      <TIcon icon="arrowLeft" class="cursor-pointer" @click.stop="goPrevStep" />
      <TIcon icon="cross" class="cursor-pointer" @click.stop="closeModal" />
    </div>
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div class="h-full space-y-4 overflow-y-scroll px-4 py-4 md:px-12">
        <h2 class="mb-10 font-display text-xl font-[600] text-marine-900">
          {{ $t("sell.modal.bankInfo.title") }}
        </h2>
        <p class="mt-10">
          {{ $t("sell.modal.bankInfo.subtitle") }}
        </p>
        <div
          class="mt-4 flex w-full gap-6 rounded-lg border border-marine-50 bg-white p-6"
        >
          <div
            class="flex flex-col gap-3 text-sm font-semibold text-marine-500"
          >
            <p>{{ $t("sell.modal.bankInfo.holder") }}</p>
            <p>{{ $t("sell.modal.bankInfo.bankName") }}</p>
            <p>{{ $t("sell.modal.bankInfo.iban") }}</p>
          </div>

          <div class="flex flex-col gap-2 text-marine-500">
            <p>{{ kyc.bankHolder }}</p>
            <p>{{ kyc.bankName }}</p>
            <p>{{ kyc.iban }}</p>
          </div>
        </div>
        <div class="mt-10 flex flex-col gap-2 lg:flex-row">
          <TButton
            class="w-full"
            variant="primary-light"
            @click="goToUpdateBankInfo"
            >{{ $t("sell.modal.bankInfo.iWantToUpdate") }}</TButton
          >
          <TButton class="w-full" @click="continueToNextStep">{{
            $t("sell.modal.bankInfo.ibanCorrect")
          }}</TButton>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import TButton from "@/components/designSystem/TButton.vue";
import { useKycStore } from "@/stores/kyc";
import { SellModalStep } from "@/constants/sell-modal-constants";
import { type UserKyc } from "@/types/user-kyc";

const kycStore = useKycStore();

const emit = defineEmits(["modal-close", "next-step", "prev-step"]);

const kyc = kycStore.kyc as UserKyc;

const closeModal = () => {
  emit("modal-close");
};

const continueToNextStep = () => {
  emit("next-step", SellModalStep.overview);
};

const goPrevStep = () => {
  emit("prev-step");
};

const goToUpdateBankInfo = () => {
  emit("next-step", SellModalStep.bankInfoUpdate);
};
</script>
