import { createI18n } from "vue-i18n";
import axios from "./axios";

async function loadLocaleMessages(): Promise<any> {
  const remoteLocales = await axios.get("/admin/locales");

  return remoteLocales.data;

  // const messages: any = {};
  // // Use Vite's glob import feature to dynamically import locale JSON files
  // const localeFiles = import.meta.glob("@/locales/*.json", { eager: false });

  // const importPromises = Object.entries(localeFiles).map(
  //   async ([key, importer]) => {
  //     const matched = key.match(/\/locales\/([A-Za-z0-9-_]+)\.json$/i);
  //     if (matched && matched.length > 1) {
  //       const locale = matched[1];
  //       const module: any = await importer();
  //       messages[locale] = module.default;
  //     }
  //   },
  // );

  // await Promise.all(importPromises);

  // return messages;
}

export default createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: await loadLocaleMessages(),
  missing: () => "-",
  silentTranslationWarn: true,
  globalInjection: true,
  legacy: false,
});
