<script setup lang="ts">
import dayjs from "dayjs";
import { ref } from "vue";

import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import OnboardingHeader from "@/containers/onboarding/OnboardingHeader.vue";

import axios from "@/lib/axios";

const nextBatchEnd = ref<dayjs.Dayjs>();

const getActiveBatch = async () => {
  try {
    const request = await axios.get("/batches/active");
    nextBatchEnd.value = dayjs(request.data.closingDate);
  } catch (e) {
    console.error(e);
  }
};

getActiveBatch();
</script>

<template>
  <div class="flex h-screen w-full">
    <OnboardingSidebar current-step="closing" />

    <div
      class="flex h-full flex-1 flex-col items-center overflow-y-auto p-6 sm:p-8"
    >
      <OnboardingHeader />

      <div class="mt-4 flex w-full flex-1 justify-center">
        <div
          class="relative h-full w-full max-w-2xl py-16 text-marine-800 md:min-w-[672px]"
        >
          <div class="mt-16 font-display text-4xl font-bold text-marine-800">
            {{ $t("onboarding.steps.closing.content.title") }}
          </div>
          <div class="mt-4 font-display text-sm font-medium text-marine-500">
            {{ $t("onboarding.steps.closing.content.subtitle") }}
          </div>

          <div class="mt-12 rounded border bg-white p-6">
            <div class="mb-4 font-display text-lg font-bold text-marine-700">
              {{ $t("onboarding.steps.closing.content.whatNext") }}
            </div>

            <div class="text-sm text-marine-500">
              {{ $t("onboarding.steps.closing.content.description") }}
            </div>

            <div class="mt-5 items-center border-t pt-5 md:flex">
              <div class="font-semibold text-marine-500">
                {{ $t("onboarding.steps.closing.content.nextRoundClosing") }}
              </div>
              <div class="flex-1" />
              <div
                v-if="nextBatchEnd"
                class="mt-2 font-medium text-marine-400 md:mt-0"
              >
                {{ nextBatchEnd.format("DD MMM YYYY") }}
              </div>
              <div v-else class="h-6 w-20 rounded bg-marine-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
