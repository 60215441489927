<script setup lang="ts">
import { ref } from "vue";

import LogoSmall from "@/components/LogoSmall.vue";

const step = ref(0);
const emit = defineEmits(["completed"]);

const hide = ref(false);

const complete = () => {
  hide.value = true;
  setTimeout(() => {
    emit("completed");
  }, 1400);
};
</script>

<template>
  <div class="h-full w-full p-8 flex flex-col items-center justify-center">
    <div
      class="relative flex flex-col items-center text-marine-900 text-center px-4 font-display"
    >
      <TransitionGroup name="fade-slow">
        <template v-if="!hide">
          <div key="blue" class="glare-blue" />
          <div key="yellow" class="glare-yellow" />
          <LogoSmall />

          <div>
            <Transition
              name="slide-fade"
              appear
              mode="out-in"
              :duration="{ enter: 2400, leave: 700 }"
            >
              <div v-if="step === 0" class="flex flex-col items-center">
                <div class="font-bold text-5xl md:text-7xl mt-12 title">
                  {{ $t("auth.slides.first.title") }}
                </div>

                <div class="font-semibold text-xl md:text-2xl mt-12 subtitle">
                  {{ $t("auth.slides.first.subtitle") }}
                </div>
                <div
                  class="text-lg md:text-xl mt-12 max-w-lg mb-[60px] description"
                >
                  {{ $t("auth.slides.first.description") }}
                </div>

                <button
                  class="mt-12 rounded-full bg-indigo-600 text-white px-6 py-2 font-semibold border border-indigo-500 flex items-center hover:bg-indigo-500 transition-colors link"
                  @click="step = 1"
                >
                  {{ $t("auth.slides.first.button") }}
                  <i class="mdi mdi-chevron-right mdi-24px ml-1 mb-0.5" />
                </button>
              </div>
              <div v-else-if="step === 1" class="flex flex-col items-center">
                <div class="font-bold text-5xl md:text-7xl mt-12 title">
                  {{ $t("auth.slides.second.title") }}
                </div>

                <div
                  class="font-semibold text-xl md:text-2xl mt-12 max-w-lg subtitle"
                >
                  {{ $t("auth.slides.second.subtitle") }}
                </div>
                <div class="text-lg md:text-xl mt-12 max-w-lg description">
                  {{ $t("auth.slides.second.description") }}
                </div>

                <button
                  class="mt-12 rounded-full bg-indigo-600 text-white px-6 py-2 font-semibold border border-indigo-500 flex items-center hover:bg-indigo-500 transition-colors link"
                  @click="step = 2"
                >
                  {{ $t("auth.slides.second.button") }}
                  <i class="mdi mdi-chevron-right mdi-24px ml-1 mb-0.5" />
                </button>
              </div>
              <div v-else-if="step === 2" class="flex flex-col items-center">
                <div class="font-bold text-5xl md:text-7xl mt-12 title">
                  {{ $t("auth.slides.third.title") }}
                </div>

                <div
                  class="font-semibold text-xl md:text-2xl mt-12 max-w-lg subtitle"
                >
                  {{ $t("auth.slides.third.subtitle") }}
                </div>
                <div class="text-lg md:text-xl mt-12 max-w-lg description">
                  {{ $t("auth.slides.third.description") }}
                </div>

                <button
                  class="mt-12 rounded-full bg-indigo-600 text-white px-6 py-2 font-semibold border border-indigo-500 flex items-center hover:bg-indigo-500 transition-colors link"
                  @click="complete"
                >
                  {{ $t("auth.slides.third.button") }}
                  <i class="mdi mdi-chevron-right mdi-24px ml-1 mb-0.5" />
                </button>
              </div>
            </Transition>
          </div>
        </template>
      </TransitionGroup>
    </div>
  </div>
</template>

<style scoped>
.glare-blue {
  position: absolute;
  z-index: 10;
  top: -10%;
  left: -5%;
  width: 258px;
  height: 268px;
  border-radius: 268px;
  background: #8f92da;
  mix-blend-mode: hard-light;
  filter: blur(65px);
}

.glare-yellow {
  position: absolute;
  z-index: 10;
  top: 25%;
  right: -5%;
  width: 258px;
  height: 268px;
  border-radius: 268px;
  background: #ffcf97;
  mix-blend-mode: hard-light;
  filter: blur(65px);
}

.slide-fade-enter-active div,
.slide-fade-enter-active .link {
  transition: all 1.4s ease-out;
}

.slide-fade-enter-active .subtitle {
  transition-delay: 0.5s;
}

.slide-fade-enter-active .description {
  transition-delay: 0.7s;
}

.slide-fade-enter-active .link {
  transition-delay: 1s;
}

.slide-fade-leave-active div,
.slide-fade-leave-active .link {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-active .subtitle {
  transition-delay: 0.2s;
}

.slide-fade-leave-active .description {
  transition-delay: 0.4s;
}

.slide-fade-leave-active .link {
  transition-delay: 0.4s;
}

.slide-fade-enter-from div,
.slide-fade-enter-from .link {
  transform: translateY(-20px);
  opacity: 0;
}

.slide-fade-leave-to div,
.slide-fade-leave-to .link {
  transform: translateY(20px);
  opacity: 0;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1.4s ease;
}

.fade-slow-enter-from,
.fade-slow-leave-to {
  opacity: 0;
}

.fade-slow-enter-to,
.fade-slow-leave-from {
  opacity: 1;
}
</style>
