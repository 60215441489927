import {ref, onMounted, onBeforeMount, type Ref} from 'vue';
import {useDebounce} from "@/hooks/use-debounce";

export function useClickOutside(
    component: Ref<HTMLElement | null>,
    callback: (event: Event) => void,
    excludeComponent: Ref<HTMLElement | null> = ref(null)
) {
  const debouncedCallback = useDebounce(callback, 200);

  const handleClickOutside: EventListener = (event: Event) => {
    if (!component.value) return;
    if (!component.value.contains(event.target as Node) &&
        (!excludeComponent.value || !excludeComponent.value.contains(event.target as Node))) {
      debouncedCallback(event);
    }
  };

  onMounted(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  });

  onBeforeMount(() => {
    document.removeEventListener('mousedown', handleClickOutside);
    document.removeEventListener('touchstart', handleClickOutside);
  });
}
