<template>
  <div
    class="fixed bottom-0 z-10 mb-4 flex w-[calc(100%-32px)] flex-wrap justify-center rounded-xl border bg-white p-4 shadow-sm md:left-[416px] md:w-[calc(100%-424px)] md:flex-nowrap md:items-center md:justify-between md:gap-0"
  >
    <div>
      <div class="font-display font-semibold text-marine-700">
        {{ $t("onboarding.steps.invest.content.needHelp.title") }}
      </div>
      <div class="whitespace-pre-line text-xs text-marine-400">
        {{ $t("onboarding.steps.invest.content.needHelp.text") }},

        <a
          href="https://meetings-eu1.hubspot.com/meetings/siptene"
          target="_blank"
          class="bg-white text-center text-xs font-bold text-marine-700 hover:text-marine-900"
        >
          {{ $t("onboarding.steps.invest.content.bookACall") }}
        </a>
      </div>
    </div>

    <slot name="button"> </slot>
  </div>
</template>
