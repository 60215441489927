<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";

import LineChart from "@/components/charts/LineChart.vue";

import type { MarketPrice } from "@/types/products";

import axios from "@/lib/axios";

const prices = ref<MarketPrice[]>();

const fetchPrices = async () => {
  prices.value = undefined;
  try {
    const request = await axios.get("/products/eua/price/history", {
      params: { timespan: "ytd" },
    });
    prices.value = request.data;
  } catch (e) {
    console.error(e);
  }
};

const pricesValues = computed(() => {
  if (!prices.value) return;
  return prices.value.map((p) => ({ timestamp: p.timestamp, value: p.price }));
});

fetchPrices();
</script>

<template>
  <LineChart
    :values="pricesValues"
    :vertical-legend="false"
    :tooltip="false"
    :height="140"
    :cursor="false"
    :legend-formatter="(date: dayjs.Dayjs) => date.format('MMM')"
  />
</template>
