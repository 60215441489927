<template>
  <div class="absolute h-full w-full">
    <div class="flex justify-between px-4 pt-4">
      <TIcon icon="arrowLeft" class="cursor-pointer" @click.stop="goPrevStep" />
      <TIcon icon="cross" class="cursor-pointer" @click.stop="closeModal" />
    </div>
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div class="mx-auto flex h-full w-full flex-col pb-12 pt-6">
        <div class="px-6 lg:px-20">
          <h2 class="mt-2 font-display text-xl font-[600] text-marine-900">
            {{ $t("sell.modal.updateBankInfo.title") }}
          </h2>
          <div class="mb-4 mt-8">
            <label for="holder" class="flex items-center gap-2 text-marine-500">
              <p class="text-sm font-semibold">
                {{ $t("sell.modal.bankInfo.holder") }}
              </p>
              <TTooltip :text="$t('sell.modal.updateBankInfo.holderTooltip')">
                <TIcon icon="infoFull" color="marine-400" size="xs" />
              </TTooltip>
            </label>
            <input
              id="holder"
              type="text"
              :value="userFullName"
              readonly
              class="mt-1 w-full rounded-md border border-marine-100 bg-marine-50 px-3 py-2 text-marine-500"
            />
          </div>
          <div class="mb-4">
            <label for="holder" class="text-marine-500">
              <p class="text-sm font-semibold">
                {{ $t("sell.modal.bankInfo.iban") }}
              </p>
            </label>
            <input
              id="iban"
              v-model="formattedIban"
              type="text"
              :class="{ 'border-red-400': !isValidIban }"
              class="mt-1 w-full rounded-md border border-marine-50 px-3 py-2"
              @input="formatAndValidateIban"
            />
            <p v-if="!isValidIban" class="mt-2 text-sm text-red-500">
              {{
                $t("sell.modal.updateBankInfo.sell.modal.bankInfo.invalidIban")
              }}
            </p>
          </div>
          <div class="mb-4">
            <label for="holder" class="text-marine-500">
              <p class="text-sm font-semibold">
                {{ $t("sell.modal.bankInfo.bankName") }}
              </p>
            </label>
            <input
              id="iban"
              v-model="newBankName"
              type="text"
              :class="{ 'border-red-400': !isBankNameValid }"
              class="mt-1 w-full rounded-md border border-marine-50 px-3 py-2"
              @input="formatAndValidateIban"
            />
            <p v-if="!isBankNameValid" class="mt-2 text-sm text-red-500">
              {{ $t("sell.modal.updateBankInfo.bankNameRequired") }}
            </p>
          </div>
          <div class="flex items-center gap-2">
            <input
              id="confirmation"
              v-model="confirmed"
              type="checkbox"
              class="form-checkbox h-5 w-5"
            />
            <label for="confirmation" class="text-gray-700">{{
              $t("sell.modal.updateBankInfo.confirmatiomLabel")
            }}</label>
          </div>
        </div>
        <div class="flex flex-1 items-end px-6 pb-4">
          <div class="w-full rounded-lg border border-marine-100 bg-white p-4">
            <div class="flex w-full justify-end">
              <TButton
                class="transition-all"
                :disabled="!canModifyBankInfo"
                @click="addNewIban"
              >
                <p v-if="!isLoading">
                  {{ $t("sell.modal.updateBankInfo.addIban") }}
                </p>
                <Loader v-else class="text-white" />
              </TButton>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useKycStore } from "@/stores/kyc";
import { useAuthStore } from "@/stores/auth";
import { extractIBAN, friendlyFormatIBAN, isValidIBAN } from "ibantools";
import { SellModalStep } from "@/constants/sell-modal-constants";
import SellTransaction from "@/models/sell-transaction";
import Loader from "@/components/Loader.vue";
import { useNotificationsStore } from "@/stores/notifications";
import { useI18n } from "vue-i18n";

const transaction = defineModel<SellTransaction>({ required: true });

const { t: $t } = useI18n();

const emit = defineEmits(["modal-close", "prev-step", "next-step"]);

const kycStore = useKycStore();
const notificationStore = useNotificationsStore();

const kyc = kycStore.kyc;

const newIban = ref(kyc?.iban);
const newBankName = ref(kyc?.bankName ?? "");
const formattedIban = ref(
  newIban.value ? friendlyFormatIBAN(newIban.value) : "",
);
const confirmed = ref(false);
const isValidIban = ref(true);
const isLoading = ref(false);

const isBankNameValid = computed(() => {
  return newBankName.value && newBankName.value !== "";
});

const userFullName = computed(() => {
  return kyc?.bankHolder;
});

const canModifyBankInfo = computed(() => {
  return confirmed.value && isValidIban.value;
});

const formatAndValidateIban = () => {
  if (formattedIban.value) {
    const cleanedIban = extractIBAN(formattedIban.value);
    formattedIban.value = friendlyFormatIBAN(cleanedIban.iban);
    isValidIban.value = isValidIBAN(cleanedIban.iban);
  } else {
    isValidIban.value = true;
  }
};

const addNewIban = async () => {
  if (formattedIban.value && newBankName.value) {
    isLoading.value = true;

    try {
      await kycStore.updateBankInfo({
        iban: formattedIban.value,
        bankName: newBankName.value,
      });

      continueToNextStep();
    } catch (_) {
      notificationStore.addNotification(
        $t("sell.modal.updateBankInfo.updateFailedError"),
        "error",
      );
    } finally {
      isLoading.value = false;
    }
  }
};

watch(newIban, (newVal) => {
  formattedIban.value = friendlyFormatIBAN(newVal);
  isValidIban.value = isValidIBAN(extractIBAN(newVal!).iban);
});

const continueToNextStep = () => {
  if (transaction.value.bankInfoVerified) {
    emit("prev-step");
  } else {
    transaction.value.bankInfoVerified = true;
    emit("next-step", SellModalStep.overview);
  }
};

const goPrevStep = () => {
  emit("prev-step");
};

const closeModal = () => {
  emit("modal-close");
};
</script>
