<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { getCurrencyFormatter, nFormatter, parseAmount } from "@/utils/numbers";
import {
  getExampleNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  type CountryCode,
  AsYouType,
} from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import axios from "@/lib/axios";

import { extractIBAN, friendlyFormatIBAN } from "ibantools";

import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import OnboardingHeader from "@/containers/onboarding/OnboardingHeader.vue";
import Terms from "@/containers/onboarding/Terms.vue";
import Loader from "@/components/Loader.vue";

import { useAuthStore } from "@/stores/auth";
import { usePortfolioStore } from "@/stores/portfolio";
import { useBatchesStore } from "@/stores/batches";

import type { Upload } from "@/types/upload";

import countries from "@/assets/constants/countries.json";

const portfolioStore = usePortfolioStore();
const authStore = useAuthStore();
const batchesStore = useBatchesStore();
const { user } = storeToRefs(authStore);
const router = useRouter();

const { currentBatch } = storeToRefs(batchesStore);

enum LegalForm {
  INDIVIDUAL = "individual",
  COMPANY = "company",
}

const step = ref(1);

const firstName = ref<string | null>(user.value!.firstName);
const lastName = ref<string | null>(user.value!.lastName);
const country = ref<string>(user.value!.country ?? "France");
const phoneCountry = ref<CountryCode>();
const phone = ref<string>();

const legalForm = ref<LegalForm>();
const companyName = ref<string>();
const companyRegistrationNumber = ref<string>();
const companyCountry = ref<string>(user.value!.country ?? "France");
const companyRegistrationDocument = ref<Upload>();
const identityDocument = ref<Upload>();
const needIdentityDocumentSecond = ref(false);
const identityDocumentSecond = ref<Upload>();
const addressLineOne = ref<string>();
const needAddressLineTwo = ref(false);
const addressLineTwo = ref<string>();
const addressCity = ref<string>();
const addressPostalCode = ref<string>();
const iban = ref<string>();
const bankName = ref<string>();

const loading = ref<boolean>(false);
const contractLoading = ref<boolean>(false);
const contractReady = ref<boolean>(false);
const contractOpened = ref<boolean>(false);
const amount = ref<number>(1000);
const currentOrder = ref();
const signingLink = ref();

const currentPhoneNumber = parsePhoneNumberFromString(user.value!.phone!, {
  defaultCountry: countries.find((c) => c.name === user.value!.country)
    ?.code as CountryCode | undefined,
});
if (currentPhoneNumber) {
  phoneCountry.value = currentPhoneNumber.country;
  phone.value = currentPhoneNumber.formatNational();
}

const hasReducedFees = computed(() => amount.value >= 20_000);

const disabled = computed<boolean>(() => {
  if (step.value === 1) {
    return (
      !firstName.value ||
      !lastName.value ||
      !country.value ||
      !phone.value ||
      !phoneValid.value
    );
  } else if (step.value === 2) {
    if (legalForm.value === "individual") {
      return false;
    } else if (legalForm.value === "company") {
      return (
        !companyName.value ||
        !companyRegistrationNumber.value ||
        !companyCountry.value ||
        !companyRegistrationDocument.value
      );
    } else {
      return true;
    }
  } else if (step.value === 3) {
    return !identityDocument.value;
  } else if (step.value === 4) {
    return (
      !addressLineOne.value || !addressCity.value || !addressPostalCode.value
    );
  } else if (step.value === 5) {
    return !iban.value || !ibanValid.value || !bankName.value;
  }

  return false;
});

const formatPhone = () => {
  if (!phoneCountry.value || !phone.value) return;
  const asYouType = new AsYouType(phoneCountry.value);
  phone.value = asYouType.input(phone.value);
};

const phoneValid = computed(() => {
  if (!phone.value) return;
  else if (!phoneCountry.value) return;
  return isValidPhoneNumber(phone.value, phoneCountry.value);
});

const formatIBAN = () => {
  if (!iban.value) return;
  const formattedIBAN = friendlyFormatIBAN(iban.value);
  if (formattedIBAN) {
    iban.value = formattedIBAN;
  }
};

const ibanValid = computed(() => {
  if (!iban.value) return false;
  const ibanExtracted = extractIBAN(iban.value);
  return ibanExtracted.valid;
});

const currentSubStep = computed(() => {
  if (!user.value) return "terms";

  const steps = {
    kyc_done: "terms",
    signature_pending: "signature",
    deposit_pending: "deposit",
  };

  const key = user.value!.status as keyof typeof steps;

  return steps[key] || "terms";
});

const previous = () => {
  if (step.value === 1) {
    user.value!.status = "onboarded";
    router.push({ name: "onboarding-invest" });
  }

  step.value -= 1;
};

const loadAmount = async () => {
  if (!user.value?.amount) return;

  amount.value = user.value?.amount;

  const orders = await axios.get("/orders/");
  currentOrder.value = orders.data.at(-1);

  if (currentOrder.value?.signature_link) {
    signingLink.value = currentOrder.value.signature_link;
    contractReady.value = true;
    await openContract();
  }
};

const next = async () => {
  if (disabled.value) return;

  if (step.value === 1) {
    const phoneNumber = parsePhoneNumberFromString(
      phone.value!,
      phoneCountry.value,
    );
    if (!phoneNumber) return;

    const request = await axios.patch("/user", {
      firstName: firstName.value,
      lastName: lastName.value,
      country: country.value,
      phone: phoneNumber.number,
    });
    user.value = request.data;
  } else if (step.value === 5) {
    loading.value = true;
    await axios.post("/user/kyc", {
      legalForm: legalForm.value,
      company:
        legalForm.value === LegalForm.COMPANY
          ? {
              name: companyName.value,
              registrationNumber: companyRegistrationNumber.value,
              country: companyCountry.value,
              registrationDocument: {
                content: companyRegistrationDocument.value!.base64,
                name: companyRegistrationDocument.value!.name,
              },
            }
          : undefined,
      identityDocument: {
        content: identityDocument.value!.base64,
        name: identityDocument.value!.name,
      },
      identityDocumentSecond: identityDocumentSecond.value
        ? {
            content: identityDocumentSecond.value.base64,
            name: identityDocumentSecond.value.name,
          }
        : undefined,
      addressLineOne: addressLineOne.value,
      addressLineTwo: addressLineTwo.value,
      addressCity: addressCity.value,
      addressPostalCode: addressPostalCode.value,
      iban: iban.value,
      bankName: bankName.value,
    });
    user.value!.status = "kyc_done";
    loading.value = false;
  }

  step.value += 1;
};

const checkOrderSignature = async () => {
  const orders = await axios.get("/orders/");
  if (orders.data[0].signature_done) {
    user.value!.status = "deposit_pending";
    router.push({ name: "onboarding-deposit" });
  } else {
    setTimeout(checkOrderSignature, 1000);
  }
};

const openContract = async () => {
  contractOpened.value = true;
  document.querySelector("main")!.classList.add("yousign-iframe-open");

  await nextTick();

  // @ts-ignore
  new Yousign({
    signatureLink: signingLink.value,
    iframeContainerId: "signature-container",
    isSandbox: process.env.NODE_ENV !== "production",
  });

  setTimeout(checkOrderSignature, 1000);
};

Promise.all([
  loadAmount(),
  batchesStore.getActiveBatch(),
  portfolioStore.fetchLastPrice(),
]);
</script>

<template>
  <div class="flex h-full w-full overflow-hidden p-4">
    <OnboardingSidebar
      :current-step="user?.status === 'kyc_pending' ? 'kyc' : 'invest'"
      :current-sub-step="currentSubStep"
    />

    <div
      class="no-scrollbar flex h-screen flex-1 flex-col items-center overflow-y-auto p-6 sm:p-8"
    >
      <OnboardingHeader />

      <div class="mb-32 flex w-full flex-1 justify-center md:mb-16">
        <div class="h-full w-full py-12 text-marine-800 md:min-w-[672px]">
          <template v-if="user?.status === 'kyc_pending'">
            <div class="font-display text-4xl font-bold text-marine-800">
              {{ $t("onboarding.steps.kyc.content.title") }}
            </div>
            <div class="mt-4 font-display text-sm font-medium text-marine-500">
              {{ $t("onboarding.steps.kyc.content.subtitle") }}
            </div>

            <div class="my-10 rounded-lg border bg-white p-4 sm:p-6">
              <template v-if="!loading">
                <div class="text-sm font-semibold text-marine-400">
                  {{ $t("onboarding.step") }} {{ step }} / 5
                </div>
                <div class="mt-2 text-xl font-semibold text-marine-800">
                  <template v-if="step === 1">
                    {{ $t("onboarding.steps.kyc.content.confirmInformation") }}
                  </template>
                  <template v-else-if="step === 2">
                    {{ $t("onboarding.steps.kyc.content.whichLegalForm") }}
                  </template>
                  <template v-else-if="step === 3">
                    {{ $t("onboarding.steps.kyc.content.provideDocument") }}
                  </template>
                  <template v-else-if="step === 4">
                    {{ $t("onboarding.steps.kyc.content.addressQuestion") }}
                  </template>
                  <template v-else-if="step === 5">
                    {{ $t("onboarding.steps.kyc.content.IBANQuestion") }}
                  </template>
                </div>

                <Transition name="slide-left" mode="out-in">
                  <div v-if="step === 1">
                    <div class="mt-10 flex flex-col gap-3 md:flex-row">
                      <div class="flex-1">
                        <div class="ml-2 text-sm font-semibold text-marine-500">
                          {{ $t("onboarding.form.firstName") }}
                        </div>
                        <TTextField
                          v-model="firstName"
                          :placeholder="$t('onboarding.form.firstName')"
                          name="firstname"
                          autofocus
                          class="mt-2"
                        />
                      </div>

                      <div class="flex-1">
                        <div class="ml-2 text-sm font-semibold text-marine-500">
                          {{ $t("onboarding.form.lastName") }}
                        </div>
                        <TTextField
                          v-model="lastName"
                          :placeholder="$t('onboarding.form.lastName')"
                          name="lastname"
                          class="mt-2"
                        />
                      </div>
                    </div>

                    <div
                      class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.countryQuestion") }}
                    </div>
                    <TSelect
                      v-model="country"
                      :placeholder="$t('onboarding.form.country')"
                      name="country"
                      autofocus
                      :items="countries"
                      item-value="name"
                      class="mt-2 w-full"
                    >
                      <template #item="{ item, selected }">
                        <div
                          :class="[
                            selected ? 'font-bold' : 'font-normal',
                            'flex items-center truncate',
                          ]"
                        >
                          <div class="mr-2">
                            {{ item.emoji }}
                          </div>
                          {{ item.name }}
                        </div>
                      </template>

                      <template #selected="{ item }">
                        <div class="'truncate items-center', flex">
                          <div class="mr-2">
                            {{ item.emoji }}
                          </div>
                          {{ item.name }}
                        </div>
                      </template>
                    </TSelect>

                    <div
                      class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.phoneQuestion") }}
                    </div>
                    <div
                      class="mt-2 flex w-full flex-col items-center gap-2 md:flex-row"
                    >
                      <TSelect
                        v-model="phoneCountry"
                        name="phone-country"
                        :items="
                          getCountries().map((c) => ({
                            text: `${c} (+${getCountryCallingCode(c)})`,
                            value: c,
                          }))
                        "
                        class="w-full md:w-auto"
                        @update:model-value="formatPhone"
                      />
                      <TTextField
                        v-model="phone"
                        :placeholder="
                          getExampleNumber(
                            phoneCountry!,
                            examples,
                          )?.formatNational()
                        "
                        name="phone-number"
                        type="tel"
                        autofocus
                        class="w-full flex-1 md:w-auto"
                        @update:model-value="formatPhone"
                      />
                    </div>
                  </div>
                  <div v-else-if="step === 2">
                    <div
                      class="mt-8 grid w-full grid-cols-1 gap-2 md:grid-cols-2"
                    >
                      <label
                        v-for="value in [
                          { text: 'Individual', value: LegalForm.INDIVIDUAL },
                          { text: 'Company', value: LegalForm.COMPANY },
                        ]"
                        :key="value.value"
                        class="flex h-12 cursor-pointer items-center justify-center rounded-lg border px-4 font-medium transition"
                        :class="
                          legalForm === value.value
                            ? 'border-indigo-400 bg-indigo-100 text-marine-800'
                            : 'hover:bg-indigo-25 bg-white text-marine-700 hover:border-gray-300'
                        "
                        @click="legalForm = value.value"
                      >
                        {{ value.text }}
                      </label>
                    </div>

                    <div v-if="legalForm === 'company'" class="mt-2">
                      <div
                        class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                      >
                        {{ $t("onboarding.form.companyName") }}
                      </div>
                      <TTextField
                        v-model="companyName"
                        placeholder="ACME Holding"
                        name="companyName"
                        autofocus
                        class="mt-2"
                      />

                      <div
                        class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                      >
                        {{ $t("onboarding.form.companyRegistrationNumber") }}
                      </div>
                      <TTextField
                        v-model="companyRegistrationNumber"
                        placeholder=""
                        name="companyRegistrationNumber"
                        class="mt-2"
                      />

                      <div
                        class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                      >
                        {{ $t("onboarding.form.companyCountry") }}
                      </div>
                      <TSelect
                        v-model="companyCountry"
                        :placeholder="$t('onboarding.form.country')"
                        name="country"
                        :items="countries"
                        item-value="name"
                        class="mt-2 w-full"
                      >
                        <template #item="{ item, selected }">
                          <div
                            :class="[
                              selected ? 'font-bold' : 'font-normal',
                              'flex items-center truncate',
                            ]"
                          >
                            <div class="mr-2">
                              {{ item.emoji }}
                            </div>
                            {{ item.name }}
                          </div>
                        </template>

                        <template #selected="{ item }">
                          <div class="'truncate items-center', flex">
                            <div class="mr-2">
                              {{ item.emoji }}
                            </div>
                            {{ item.name }}
                          </div>
                        </template>
                      </TSelect>

                      <div
                        class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                      >
                        {{ $t("onboarding.form.companyRegistrationDocument") }}
                      </div>
                      <TDropZone
                        class="mt-2"
                        @upload="companyRegistrationDocument = $event"
                      />
                    </div>
                  </div>
                  <div v-else-if="step === 3">
                    <div
                      class="ml-2 mt-10 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.identityDocument") }}
                    </div>
                    <TDropZone
                      class="mt-2"
                      @upload="identityDocument = $event"
                    />

                    <template v-if="needIdentityDocumentSecond">
                      <div
                        class="ml-2 mt-10 text-sm font-semibold text-marine-500"
                      >
                        {{
                          $t("onboarding.form.companyRegistrationDocumentBack")
                        }}
                      </div>
                      <TDropZone
                        class="mt-2"
                        @upload="identityDocumentSecond = $event"
                      />
                    </template>
                    <template v-else>
                      <div
                        class="mt-10 cursor-pointer text-sm font-semibold text-marine-600 hover:text-marine-800"
                        @click="needIdentityDocumentSecond = true"
                      >
                        <i class="mdi mdi-plus" />
                        {{ $t("onboarding.form.addDocumentPicture") }}
                      </div>
                    </template>
                  </div>
                  <div v-else-if="step === 4">
                    <div
                      class="ml-2 mt-10 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.street") }}
                    </div>
                    <TTextField
                      v-model="addressLineOne"
                      placeholder="25 Rue des petites écuries"
                      name="address-line1"
                      autocomplete="address-line1"
                      autofocus
                      class="mt-2"
                    />

                    <template v-if="needAddressLineTwo">
                      <div
                        class="ml-2 mt-5 text-sm font-semibold text-marine-500"
                      >
                        {{ $t("onboarding.form.addressLineTwo") }}
                      </div>
                      <TTextField
                        v-model="addressLineTwo"
                        placeholder="Appartment 5B"
                        name="address-line2"
                        autocomplete="address-line2"
                        class="mt-2"
                      />
                    </template>
                    <template v-else>
                      <div
                        class="mb-10 ml-2 mt-5 cursor-pointer text-sm font-semibold text-marine-600 hover:text-marine-800"
                        @click="needAddressLineTwo = true"
                      >
                        <i class="mdi mdi-plus" />
                        {{ $t("onboarding.form.addAddressLine") }}
                      </div>
                    </template>

                    <div class="mt-5 flex flex-col gap-3 md:flex-row">
                      <div class="flex-1">
                        <div class="ml-2 text-sm font-semibold text-marine-500">
                          {{ $t("onboarding.form.postalCode") }}
                        </div>
                        <TTextField
                          v-model="addressPostalCode"
                          placeholder="75010"
                          name="postal-code"
                          autocomplete="postal-code"
                          class="mt-2"
                        />
                      </div>

                      <div class="flex-1">
                        <div class="ml-2 text-sm font-semibold text-marine-500">
                          {{ $t("onboarding.form.city") }}
                        </div>
                        <TTextField
                          v-model="addressCity"
                          placeholder="Paris"
                          name="address-level2"
                          autocomplete="address-level2"
                          class="mt-2"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="step === 5">
                    <div class="mb-4 text-sm text-marine-400">
                      {{ $t("onboarding.form.IBANInformation") }}
                    </div>

                    <div
                      class="ml-2 mt-10 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.IBAN") }}
                    </div>
                    <TTextField
                      v-model="iban"
                      placeholder="FR76 0000 0000 0000 0000"
                      name="iban"
                      autocomplete="iban"
                      autofocus
                      class="mt-2"
                      @update:model-value="formatIBAN"
                    />
                    <div
                      class="ml-2 mt-10 text-sm font-semibold text-marine-500"
                    >
                      {{ $t("onboarding.form.bankName") }}
                    </div>
                    <TTextField
                      v-model="bankName"
                      :placeholder="$t('onboarding.form.bankNamePlaceholder')"
                      name="bankName"
                      class="mt-2"
                    />
                  </div>
                </Transition>

                <div class="mt-10 flex">
                  <TBtn class="px-6" outlined @click="previous">
                    {{ $t("global.previous") }}
                  </TBtn>
                  <div class="flex-1" />
                  <TBtn :disabled="disabled" class="px-6" @click="next">
                    <template v-if="step < 5">
                      {{ $t("global.next") }}
                    </template>
                    <template v-else> {{ $t("global.submit") }} </template>
                  </TBtn>
                </div>
              </template>
              <div v-else class="flex items-center gap-6">
                <Loader class="h-20 w-20 text-marine-500" />
                <div class="text-lg font-semibold text-marine-600">
                  {{ $t("onboarding.steps.kyc.content.submitting") }}
                </div>
              </div>
            </div>
          </template>
          <Terms v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
#yousign-iframe {
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

main.yousign-iframe-open {
  overflow: hidden;
}
</style>
