<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem, Portal } from "@headlessui/vue";

import { usePopper } from "@/hooks/use-popper";

type MenuItemType = {
  value: string | number;
  text: string | number;
  icon?: string;
};

const props = withDefaults(
  defineProps<{
    items: MenuItemType[];
    disabled?: boolean;
    icon?: string;
    primary?: boolean;
  }>(),
  { primary: false, disabled: false, icon: "mdi-dots-horizontal" },
);
const emit = defineEmits(["click:item"]);

const [trigger, container] = usePopper({
  placement: "top-start",
  modifiers: [{ name: "offset", options: { offset: [0, 4] } }],
});
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <Menu v-slot="{ open }">
    <MenuButton ref="trigger" @click.stop="">
      <slot :open="open" :primary="primary">
        <button
          v-bind="$attrs"
          class="inline-flex items-center justify-center w-6 h-6 transition hover:text-marine-900 min-w-6 shrink-0"
          :class="{
            'text-marine-900': open,
            'border border-gray-300 rounded hover:bg-marine-25 text-marine-600':
              primary,
            'text-marine-400': !primary,
          }"
        >
          <i :class="`mdi ${props.icon}`" />
        </button>
      </slot>
    </MenuButton>

    <Portal v-if="open">
      <MenuItems
        ref="container"
        class="w-56 divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none"
      >
        <div class="px-1 py-1">
          <MenuItem
            v-for="item in props.items"
            v-slot="{ active }"
            :key="item.value"
          >
            <button
              :class="[
                active ? 'bg-marine-25 text-marine-900' : 'text-marine-700',
                'flex w-full items-center rounded-md px-2 py-1',
              ]"
              @click="emit('click:item', item.value)"
            >
              <i
                v-if="item.icon"
                class="mdi mr-3 mt-0.5"
                :class="`mdi-${item.icon}`"
              />
              <span class="text-sm">
                {{ item.text }}
              </span>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </Portal>
  </Menu>
</template>
