<template>
  <div>
    <div class="mb-14 font-display">
      <div class="flex items-center gap-3">
        <div
          class="mb-1.5 flex h-9 w-9 items-center justify-center rounded-lg bg-white text-lg"
        >
          🗂
        </div>
        <div class="text-3xl font-bold text-marine-900 md:text-4xl">
          {{ $t("portfolio.title") }}
        </div>
      </div>
      <div class="mt-2 text-sm font-medium text-marine-600 md:text-base">
        {{ $t("portfolio.subline") }}
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <TSnackbar
        v-if="rebuyProcessInProgress"
        v-bind="rebuySnackbarProps"
        @confirm="openBuyMoreModal"
        @cancel="deleteCurrentOrder"
      />

      <TSnackbar
        v-if="ordersStore.currentSellOrder"
        v-bind="sellOngoingSnackBarProps"
      />
    </div>

    <div class="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
      <div class="rounded-lg border bg-white px-4 py-6 shadow-sm sm:px-6">
        <div
          class="flex items-center font-display font-semibold text-marine-700"
        >
          {{ $t("portfolio.value") }}
        </div>
        <template
          v-if="
            portfolio.balance !== undefined &&
            portfolio.totalInvested !== undefined
          "
        >
          <div class="mt-4 text-4xl font-bold">
            {{ getCurrencyFormatter("EUR").format(portfolio.balance) }}
          </div>
          <div class="mt-3 text-sm font-medium text-marine-400">
            {{ getCurrencyFormatter("EUR").format(portfolio.totalInvested) }}
            {{ $t("portfolio.invested") }}
          </div>
        </template>
        <div v-else class="mt-4 h-[72px] w-full rounded bg-gray-100" />
      </div>

      <div
        class="relative w-full rounded-lg border bg-white px-4 py-6 shadow-sm sm:px-6"
      >
        <div class="flex items-start justify-between">
          <div
            class="flex items-center font-display font-semibold text-marine-700"
          >
            {{ $t("portfolio.totalHoldings") }}
          </div>
        </div>
        <template v-if="portfolio.totalHoldings !== undefined">
          <div class="mt-4 text-4xl font-bold">
            {{ getCurrencyFormatter("EUA").format(portfolio.totalHoldings) }}
          </div>
          <div class="mt-3 text-sm font-medium text-marine-400">
            = {{ getNumberFormatter().format(portfolio.totalHoldings) }}
            {{ $t("portfolio.CO2tons") }}
          </div>
        </template>
        <div v-else class="mt-4 h-[72px] w-full rounded bg-gray-100" />
        <div v-if="!isSellButtonHidden" class="absolute right-4 top-4">
          <TButton
            v-if="canSellBonds && !ordersStore.currentSellOrder"
            size="sm"
            variant="primary-light"
            style-type="outline"
            :disabled="ordersStore.currentSellOrder"
            @click="openModal"
            >{{ $t("portfolio.sell") }}</TButton
          >
          <TTooltip
            v-else
            tooltip-classes="bg-white shadow-lg w-max-lg"
            placement="top"
            variant="primary-light"
          >
            <div>
              <TButton
                icon="lock"
                icon-position="right"
                size="sm"
                variant="primary-light"
                disabled
                >{{ $t("portfolio.sell") }}</TButton
              >
            </div>
            <template #content>
              <div class="p-2 text-marine-900">
                <div class="text-md mb-2 flex items-center gap-2 font-semibold">
                  <TIcon icon="lock" />
                  <p class="text-lg font-semibold text-[#16171D]">
                    {{
                      ordersStore.currentSellOrder
                        ? $t("portfolio.purchaseTooltip.title")
                        : $t("portfolio.lockUp.title")
                    }}
                  </p>
                </div>
                <p class="mt-4 text-[#7E8084]">
                  {{
                    ordersStore.currentSellOrder
                      ? $t("portfolio.purchaseTooltip.text")
                      : $t("portfolio.lockUp.text")
                  }}
                </p>
                <p
                  v-if="!ordersStore.currentSellOrder"
                  class="mt-2 text-[#7E8084]"
                >
                  {{ $t("portfolio.lockUp.subText") }}
                </p>
                <ul class="mt-2 list-disc px-5 text-[#7E8084]">
                  <li
                    v-for="(period, index) in lockInTooltipPeriods"
                    :key="index"
                  >
                    {{ period }}
                  </li>
                </ul>
              </div>
            </template>
          </TTooltip>
        </div>
      </div>

      <div
        class="relative w-full rounded-lg border bg-white px-4 py-6 shadow-sm sm:px-6"
      >
        <div
          class="flex items-center font-display font-semibold text-marine-700"
        >
          {{ $t("portfolio.EUAPrice") }}
        </div>
        <template v-if="lastPrice !== undefined && entryPrice !== undefined">
          <div class="mt-4 text-4xl font-bold">
            {{ getCurrencyFormatter("EUR").format(lastPrice.price) }}
          </div>
          <div class="mt-3 text-sm font-medium text-marine-400">
            {{ $t("portfolio.entryPrice") }}
            {{ getCurrencyFormatter("EUR").format(entryPrice) }}
          </div>
        </template>
        <div v-else class="mt-4 h-[72px] w-full rounded bg-gray-100" />
        <div class="absolute right-4 top-4">
          <TButton size="sm" variant="primary" @click="openBuyMoreModal">{{
            $t("portfolio.buyEUAs")
          }}</TButton>
        </div>
      </div>
    </div>

    <PortfolioHistoryChart class="mt-4" />

    <div class="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      <div class="w-full rounded-lg border bg-white p-4 shadow-sm sm:p-6">
        <div
          class="flex items-center font-display font-semibold text-marine-700"
        >
          {{ $t("portfolio.summary") }}
        </div>

        <div class="mt-6 text-sm font-medium text-marine-400">
          {{ $t("portfolio.investment") }}
        </div>
        <div
          v-if="portfolio.totalInvested !== undefined"
          class="mt-1 font-bold"
        >
          {{ getCurrencyFormatter("EUR").format(portfolio.totalInvested) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />

        <div class="mt-6 text-sm font-medium text-marine-400">Fees</div>
        <div v-if="totalFees !== undefined" class="mt-1 font-bold">
          {{ getCurrencyFormatter("EUR").format(totalFees) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />

        <div class="mt-6 text-sm font-medium text-marine-400">
          {{ $t("portfolio.currentNetValue") }}
        </div>
        <div v-if="portfolio.balance !== undefined" class="mt-1 font-bold">
          {{ getCurrencyFormatter("EUR").format(portfolio.balance) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />
      </div>

      <div class="h-72 w-full rounded-lg border bg-white p-4 shadow-sm sm:p-6">
        <div
          class="flex items-center font-display font-semibold text-marine-700"
        >
          {{ $t("portfolio.earnings") }}
        </div>

        <div class="mt-6 text-sm font-medium text-marine-400">
          {{ $t("portfolio.netROI") }}
        </div>
        <div v-if="portfolio.netROI !== undefined" class="mt-1 font-bold">
          {{ getPercentageFormatter().format(portfolio.netROI) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />

        <div class="mt-6 text-sm font-medium text-marine-400">
          {{ $t("portfolio.netEarnings") }}
        </div>
        <div v-if="portfolio.netEarnings !== undefined" class="mt-1 font-bold">
          {{ getCurrencyFormatter("EUR").format(portfolio.netEarnings) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />

        <div class="mt-6 text-sm font-medium text-marine-400">
          {{ $t("portfolio.annualizedNetROI") }}
        </div>
        <div
          v-if="portfolio.annualizedROI !== undefined"
          class="mt-1 font-bold"
        >
          {{ getPercentageFormatter().format(portfolio.annualizedROI) }}
        </div>
        <div v-else class="mt-1 h-6 w-full rounded bg-gray-100" />
      </div>
      <BuyMore
        ref="refBuyMore"
        class="col-span-2"
        :title="$t('portfolio.buyMore')"
        :button-text="$t('portfolio.joinOurNextRound')"
      />
    </div>

    <div class="mt-4 w-full rounded-lg border bg-white p-4 shadow-sm sm:p-6">
      <div class="flex items-center font-display font-semibold text-marine-700">
        {{ $t("portfolio.transactions.title") }}
      </div>

      <div class="mt-4 w-full overflow-x-auto">
        <table
          v-if="transactions !== undefined"
          class="mt-4 w-full text-left text-sm text-gray-500"
        >
          <thead class="border-b text-xs uppercase text-marine-700">
            <tr>
              <th scope="col" class="py-3 pr-6">
                {{ $t("portfolio.transactions.id") }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ $t("portfolio.transactions.date") }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ $t("portfolio.transactions.type") }}
              </th>
              <th scope="col" class="px-6 py-3">
                {{ $t("portfolio.transactions.description") }}
              </th>
              <th scope="col" class="px-6 py-3 text-right">
                {{ $t("portfolio.transactions.operation") }}
              </th>
              <th scope="col" class="py-3 pl-6 pr-3 text-right">
                {{ $t("portfolio.transactions.status") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="transaction in transactions"
              :key="transaction.id"
              class="whitespace-nowrap border-b bg-white text-marine-900"
            >
              <th scope="row" class="py-4 pr-6 font-medium">
                #{{ transaction.id }}
              </th>
              <td class="px-6 py-4">
                {{ transaction.date }}
              </td>
              <td class="px-6 py-4 text-xs font-bold uppercase text-marine-500">
                {{ transaction.type }}
              </td>
              <td class="px-6 py-4">
                {{ transaction.description }}
              </td>
              <td class="px-6 py-4 text-right font-medium text-marine-400">
                <span
                  v-if="transaction.type === TransactionType.DEPOSIT"
                  class="text-green-500"
                >
                  +{{
                    getCurrencyFormatter("EUR").format(transaction.from_amount)
                  }}
                </span>
                <span
                  v-else-if="transaction.type === TransactionType.FEE"
                  class="text-red-500"
                >
                  {{
                    getCurrencyFormatter("EUR").format(
                      transaction.from_amount * -1,
                    )
                  }}
                </span>
                <template v-else>
                  <span class="text-red-500">
                    <template
                      v-if="transaction.from_account === TransactionAccount.EUA"
                    >
                      -{{
                        getCurrencyFormatter("EUA").format(
                          transaction.from_amount,
                        )
                      }}
                    </template>
                    <template v-else>
                      -{{
                        getCurrencyFormatter("EUR").format(
                          transaction.from_amount,
                        )
                      }}
                    </template>
                  </span>
                  {{ $t("portfolio.from") }}
                  <span class="font-bold uppercase text-marine-500">
                    {{ transaction.from_account }}
                  </span>

                  <i class="mdi mdi-arrow-right mx-3" />

                  <span class="text-green-500">
                    <template
                      v-if="transaction.to_account === TransactionAccount.EUA"
                    >
                      +{{
                        getCurrencyFormatter("EUA").format(
                          transaction.to_amount,
                        )
                      }}
                    </template>
                    <template v-else>
                      +{{
                        getCurrencyFormatter("EUR").format(
                          transaction.to_amount,
                        )
                      }}
                    </template>
                  </span>
                  {{ $t("portfolio.in") }}
                  <span class="font-bold uppercase text-marine-500">
                    {{ transaction.to_account }}
                  </span>
                </template>
              </td>
              <td class="py-4 pl-6 text-right">
                <span
                  class="rounded-full px-2.5 py-2 font-display text-xs font-bold capitalize tracking-wide"
                  :class="{
                    'bg-green-100 text-green-500':
                      transaction.status === TransactionStatus.PROCESSED,
                    'bg-orange-100 text-orange-500':
                      transaction.status === TransactionStatus.PROCESSING,
                    'bg-red-100 text-red-500':
                      transaction.status === TransactionStatus.CANCELLED,
                    'bg-gray-100 text-gray-400':
                      transaction.status === TransactionStatus.UPCOMING,
                  }"
                >
                  {{ transaction.status }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-else class="mt-4 h-20 w-full rounded bg-gray-100" />
      </div>
    </div>
    <SellModal v-model="isSellModalOpened" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import { usePortfolioStore } from "@/stores/portfolio";
import { useBatchesStore } from "@/stores/batches";
import { useOrdersStore } from "@/stores/orders";
import {
  getCurrencyFormatter,
  getNumberFormatter,
  getPercentageFormatter,
} from "@/utils/numbers";
import PortfolioHistoryChart from "@/containers/portfolio/PortfolioHistoryChart.vue";
import BuyMore from "@/containers/portfolio/BuyMore.vue";
import { BatchStatus } from "@/types/batches";

const { t: $t, locale } = useI18n();

import {
  TransactionType,
  TransactionAccount,
  TransactionStatus,
} from "@/types/transaction";
import SellModal from "@/containers/modals/sell/SellModal.vue";
import { type Portfolio } from "@/types/portfolio";
import { getShortDate } from "@/helpers/date-helpers";

const portfolioStore = usePortfolioStore();
const batchesStore = useBatchesStore();
const ordersStore = useOrdersStore();

const portfolio: Portfolio = portfolioStore.portfolio!;

const { currentBatch, nextBatchEnd } = storeToRefs(batchesStore);
const { latestOrder } = storeToRefs(ordersStore);
const { transactions, totalInvested, totalEUA, totalBalance, lastPrice } =
  storeToRefs(portfolioStore);

const refBuyMore = ref();

const isSellModalOpened = ref(false);

const openModal = () => {
  isSellModalOpened.value = true;
};

const lockInTooltipPeriods = computed(() => {
  const portfolio = portfolioStore.portfolio as Portfolio;
  const lockedOrders = portfolio.lockedOrders;

  return lockedOrders.map((lockedOrder) => {
    const unlockDate = new Date(lockedOrder.unlockDate);
    const unlockDateString = getShortDate(locale.value, unlockDate);

    return `${lockedOrder.quantity.toFixed(2)} ${lockedOrder.product} ${$t("portfolio.lockUp.on")} ${unlockDateString}`;
  });
});

const canSellBonds = computed(() => {
  return portfolioStore.portfolio!.availableBonds > 0;
});

const isSellButtonHidden = computed(() => {
  return (
    portfolioStore.portfolio!.availableBonds === 0 &&
    portfolioStore.portfolio!.lockedOrders.length === 0
  );
});

const totalFees = computed(() => {
  if (!transactions.value) return;
  return (
    transactions.value
      .filter((t) => t.type === TransactionType.FEE)
      .reduce((s, t) => s + t.to_amount, 0) * -1
  );
});

const entryPrice = computed(() => {
  if (totalInvested.value === undefined) return;
  else if (totalFees.value === undefined) return;
  else if (totalEUA.value === undefined) return;
  return (totalInvested.value + totalFees.value) / totalEUA.value;
});

const rebuyProcessInProgress = computed(() => {
  if (!latestOrder.value || !currentBatch.value) return false;

  return latestOrder.value.batch === currentBatch.value.id;
});

const hasSignedCurrentBatchContract = computed(() => {
  if (!latestOrder.value || !currentBatch.value) return false;

  return (
    latestOrder.value.batch === currentBatch.value.id &&
    latestOrder.value.signature_done &&
    !latestOrder.value.deposit_done
  );
});

const durationBeforeBatchEnd = computed(() => {
  if (!nextBatchEnd.value) return;

  // @DEV : 18 hours gap between closing hour and batch end because that's plain date in DB, the batches closes at 6PM.
  // When we'll change date to datetime, we will not need this anymore.
  const CLOSING_HOUR_GAP = 18;

  const diff = +nextBatchEnd.value - Date.now();
  const d = Math.floor(diff / (1000 * 60 * 60 * 24));
  const h =
    Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) +
    CLOSING_HOUR_GAP;
  const m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { d, h, m } as Record<string, unknown>;
});

const rebuySnackbarProps = computed(() => {
  if (!rebuyProcessInProgress.value) return;

  const rebuyProcessEnd =
    rebuyProcessInProgress.value && hasSignedCurrentBatchContract.value;

  const depositDoneAndBatchActive =
    latestOrder.value?.deposit_done &&
    currentBatch.value?.status === BatchStatus.ACTIVE;

  if (rebuyProcessEnd) {
    return {
      type: "info",
      title: $t("portfolio.waitingTransfer"),
      text: $t("portfolio.investmentDone"),
      confirmText: $t("portfolio.viewBankInfo"),
    };
  }

  if (depositDoneAndBatchActive) {
    return {
      type: "success",
      title: $t("portfolio.depositReceived"),
      text: $t("portfolio.afterDeposit"),
    };
  }

  return {
    type: "warning",
    title: $t("portfolio.batchClose", durationBeforeBatchEnd.value!),
    text: $t("portfolio.continueProcess"),
    cancelText: $t("global.cancel"),
    confirmText: $t("portfolio.continueInvestment"),
  };
});

const sellOngoingSnackBarProps = computed(() => {
  return {
    type: "info",
    title: $t("portfolio.waitingSell"),
    text: $t("portfolio.waitingSelling"),
  };
});

const openBuyMoreModal = () => {
  refBuyMore.value.openModal();
};

const deleteCurrentOrder = () => {
  ordersStore.deleteOrder(latestOrder.value!.id);
  latestOrder.value = null;
};

Promise.all([
  batchesStore.getActiveBatch(),
  ordersStore.getLatestOrder(),
  ordersStore.getCurrentSellOrder(),
]);
</script>
