import type { AxiosError } from "axios";
import type { AxiosResponse } from "axios";
import axios from "axios";
import Cookies from "js-cookie";

declare module "axios" {
  interface AxiosRequestConfig {
    cancelPreviousRequests?: boolean;
  }
}

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
});

const pendingRequests: { [key: string]: AbortController } = {};
const removePendingRequest = (url: string | undefined, abort = false): void => {
  if (url && pendingRequests[url]) {
    if (abort) {
      pendingRequests[url].abort();
    }
    delete pendingRequests[url];
  }
};

axiosInstance.interceptors.request.use((config) => {
  // CSRF
  config.headers["X-CSRF-Token"] = Cookies.get("csrf_token");

  // Cancel previous request
  if (config?.cancelPreviousRequests && config?.url && !config.signal) {
    removePendingRequest(config.url, true);

    const abortController = new AbortController();
    config.signal = abortController.signal;
    pendingRequests[config.url] = abortController;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    removePendingRequest(response.request.responseURL);
    return response;
  },
  (error: AxiosError) => {
    removePendingRequest(error.config?.url);

    if (error.response) {
      if (error.response.status === 401) {
        Cookies.remove("loggedIn");
        window.location.href = "/";
        return;
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
