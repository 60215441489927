<script setup lang="ts">
import { RouterLink, useRoute } from "vue-router";
import { ref } from "vue";

import LogoSmall from "@/components/LogoSmall.vue";
import Profile from "@/containers/header/Profile.vue";


const route = useRoute();

const links = [
  { route: "home", text: "Home" },
  { route: "portfolio", text: "Portfolio" },
];

const openMobileDrawer = ref(false);
</script>

<template>
  <nav
    class="w-full max-w-7xl py-6 border-b mb-12 relative text-marine-900 border-b-marine-100"
  >
    <div class="flex px-2 sm:px-4">
      <button
        class="sm:hidden h-10 w-10 flex items-center justify-center"
        @click="openMobileDrawer = !openMobileDrawer"
      >
        <i class="mdi mdi-menu mdi-24px" />
      </button>
      <div class="flex-1 sm:hidden" />

      <RouterLink to="/" class="flex items-center sm:mr-12">
        <LogoSmall />
      </RouterLink>

      <div class="gap-6 hidden sm:flex font-display">
        <RouterLink
          v-for="link in links"
          :key="link.route"
          :to="{ name: link.route }"
          class="transition-colors block px-3 pt-3 rounded font-semibold text-lg"
          :class="
            route.name === link.route
              ? 'text-marine-900'
              : 'text-marine-600 hover:text-marine-900'
          "
        >
          {{ link.text }}
        </RouterLink>
      </div>

      <div class="flex-1" />

      <Profile />
    </div>

    <Transition name="slide-top">
      <div v-if="openMobileDrawer" class="sm:hidden absolute w-full px-2 z-10">
        <div
          class="gap-2 px-2 mt-2 py-4 flex flex-col bg-white w-full rounded font-display"
        >
          <RouterLink
            v-for="link in links"
            :key="link.route"
            :to="{ name: link.route }"
            class="transition-colors block px-3 py-2 rounded"
            :class="
              route.name === link.route
                ? 'text-marine-800 font-semibold bg-marine-200'
                : 'text-marine-600 font-medium'
            "
            @click="openMobileDrawer = false"
          >
            {{ link.text }}
          </RouterLink>
        </div>
      </div>
    </Transition>
  </nav>
</template>
