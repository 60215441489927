<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useBatchesStore } from "@/stores/batches";
import { useOrdersStore } from "@/stores/orders";
import Invest from "@/containers/onboarding/Invest.vue";
import Terms from "@/containers/onboarding/Terms.vue";
import Deposit from "@/containers/onboarding/Deposit.vue";
import AssetCard from "@/containers/assets/AssetCard.vue";

const props = defineProps<{
  title?: string;
  buttonText?: string;
}>();

const batchesStore = useBatchesStore();
const ordersStore = useOrdersStore();
const { nextBatchEnd, currentBatch } = storeToRefs(batchesStore);
const { latestOrder } = storeToRefs(ordersStore);

const isModalOpened = ref<boolean>(false);

const openModal = () => {
  isModalOpened.value = true;
};

const closeModal = () => {
  isModalOpened.value = false;

  // if yousign iframe was opened and modal was closed before it, force closing it
  document.querySelector("main")!.classList.remove("yousign-iframe-open");
};

const canRebuy = computed(() => {
  return latestOrder.value?.batch !== currentBatch.value?.id;
});

const rebuyProcessInProgress = computed(() => {
  if (!latestOrder.value || !currentBatch.value) return false;

  return (
    latestOrder.value.batch === currentBatch.value.id &&
    !latestOrder.value.deposit_done
  );
});

const hasSignedCurrentBatchContract = computed(() => {
  if (!latestOrder.value || !currentBatch.value) return false;

  return (
    latestOrder.value.batch === currentBatch.value.id &&
    latestOrder.value.signature_done
  );
});

const currentStepComponent = computed(() => {
  if (!rebuyProcessInProgress.value) return Invest;

  if (rebuyProcessInProgress.value && !hasSignedCurrentBatchContract.value)
    return Terms;

  if (rebuyProcessInProgress.value && hasSignedCurrentBatchContract.value)
    return Deposit;

  return Invest;
});

Promise.all([batchesStore.getActiveBatch(), ordersStore.getLatestOrder()]);

defineExpose({
  openModal,
});
</script>

<template>
  <div
    class="flex h-72 flex-row flex-wrap items-center overflow-hidden rounded-lg border bg-marine-900 p-6"
  >
    <div class="flex w-full flex-col justify-center gap-10 md:w-auto">
      <div class="font-display text-2xl font-bold text-white">
        {{ props.title }}
      </div>
      <div class="text-marine-500">
        <div class="text-xl font-medium">
          {{ $t("portfolio.joinOurNextRound") }}
        </div>
        <div class="font-display text-2xl font-bold">
          {{ nextBatchEnd.format("DD MMM YYYY") }}
        </div>
      </div>
      <button
        class="block rounded-lg bg-marine-25 px-6 py-3 font-display text-lg font-bold tracking-[0.16px] md:w-full"
        :class="{
          'pointer-events-none': !canRebuy && !rebuyProcessInProgress,
          'border text-marine-200': !canRebuy && !rebuyProcessInProgress,
          'text-marine-900': canRebuy,
        }"
        @click.stop="openModal"
      >
        {{ rebuyProcessInProgress ? $t("global.continue") : props.buttonText }}
      </button>
    </div>
    <div class="relative hidden h-full grow md:block">
      <AssetCard asset="eua" class="absolute -top-4 scale-90" />
    </div>
    <TModal :is-open="isModalOpened" @modal-close="closeModal">
      <div
        class="absolute right-10 top-6 z-30 cursor-pointer text-sm font-medium text-marine-500 underline opacity-60"
        @click="closeModal"
      >
        {{ $t("onboarding.continueLater") }}
      </div>
      <component :is="currentStepComponent" re-buy />
    </TModal>
  </div>
</template>
