<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useOrdersStore } from "@/stores/orders";
import { getCurrencyFormatter } from "@/utils/numbers";

const ordersStore = useOrdersStore();
const { latestOrder } = storeToRefs(ordersStore);

ordersStore.getLatestOrder();
</script>
<template>
  <div class="h-[85svh] overflow-auto p-8 md:h-full">
    <div class="font-display text-4xl font-bold text-marine-800">
      {{ $t("onboarding.steps.deposit.content.title.first") }}<br />
      {{ $t("onboarding.steps.deposit.content.title.second") }}
    </div>
    <div class="mt-4 font-display text-sm font-medium text-marine-500">
      {{ $t("onboarding.steps.deposit.content.subtitle") }}
    </div>

    <div class="mt-12 rounded border bg-white p-6">
      <div class="text-sm text-marine-500">
        {{ $t("onboarding.steps.deposit.content.description") }}
      </div>

      <div class="mt-6 text-sm text-marine-300">
        {{ $t("onboarding.steps.deposit.content.amount") }}
      </div>
      <div class="text-xl font-semibold text-marine-800">
        {{ getCurrencyFormatter("EUR").format(latestOrder?.amount) }}
      </div>

      <div class="mt-6 text-sm text-marine-300">
        {{ $t("onboarding.steps.deposit.content.bankName") }}
      </div>
      <div class="text-xl font-semibold text-marine-800">
        <a href="https://memo.bank/mentions-legales/" target="_blank">
          Memo Bank SA
        </a>
      </div>

      <div class="mt-6 text-sm text-marine-300">
        {{ $t("onboarding.steps.deposit.content.accountName") }}
      </div>
      <div class="text-xl font-semibold text-marine-800">
        Homaio Carbon Holdings
      </div>

      <div class="mt-6 text-sm text-marine-300">BIC / SWIFT</div>
      <div class="text-xl font-semibold text-marine-800">MEMOFRP2XXX</div>

      <div class="mt-6 text-sm text-marine-300">IBAN</div>
      <div class="text-xl font-semibold text-marine-800">
        FR76 1733 8000 0143 8241 2745 847
      </div>
    </div>
  </div>
</template>
