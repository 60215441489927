<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const initials = computed(() => {
  return `${user.value!.firstName![0]}${
    user.value!.lastName![0]
  }`.toUpperCase();
});
</script>

<template>
  <div
    class="h-10 w-10 rounded-lg bg-marine-900 text-white font-medium flex items-center justify-center"
  >
    {{ initials }}
  </div>
</template>
