<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import {
  getExampleNumber,
  getCountries,
  getCountryCallingCode,
  parsePhoneNumberFromString,
  isValidPhoneNumber,
  type CountryCode,
  AsYouType,
} from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";
import { useRouter } from "vue-router";

import axios from "@/lib/axios";

import Logo from "@/components/Logo.vue";
import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import Loader from "@/components/Loader.vue";

import { useAuthStore } from "@/stores/auth";

import countries from "@/assets/constants/countries.json";

const router = useRouter();

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const step = ref(0);
const error = ref(false);
const firstName = ref<string | null>(user.value!.firstName);
const lastName = ref<string | null>(user.value!.lastName);
const country = ref<string>(user.value!.country ?? "France");
const phoneCountry = ref<CountryCode>();
const phone = ref<string>();
const amount = ref<number>();
const loading = ref(false);

const next = async () => {
  error.value = false;

  if (step.value === 0) {
    if (!firstName.value || !lastName.value) return;
    try {
      const request = await axios.patch("/user", {
        firstName: firstName.value,
        lastName: lastName.value,
      });
      user.value = request.data;
      step.value += 1;
    } catch (e) {
      error.value = true;
    }
  } else if (step.value === 1) {
    if (!country.value) return;
    try {
      const request = await axios.patch("/user", { country: country.value });
      user.value = request.data;
      if (phoneCountry.value === undefined) {
        phoneCountry.value = countries.find((c) => c.name === country.value)!
          .code as CountryCode;
      }
      step.value += 1;
    } catch (e) {
      error.value = true;
    }
  } else if (step.value === 2) {
    loading.value = true;

    if (!phone.value || !phoneCountry.value || !phoneValid.value) return;
    const phoneNumber = parsePhoneNumberFromString(
      phone.value,
      phoneCountry.value,
    );
    if (!phoneNumber) return;

    try {
      const request = await axios.patch("/user", { phone: phoneNumber.number });
      user.value = request.data;
      // step.value += 1;
      setTimeout(() => {
        router.push("/");
      }, 3000);
    } catch (e) {
      loading.value = false;
      error.value = true;
    }
  }
  // else if (step.value === 3) {
  //   if (!amount.value) return;
  //   loading.value = true;

  //   try {
  //     const request = await axios.patch("/user", { amount: amount.value });
  //     user.value = request.data;
  //     setTimeout(() => {
  //       router.push("/");
  //     }, 3000);
  //   } catch (e) {
  //     loading.value = false;
  //     error.value = true;
  //   }
  // }
};

const disabled = computed(() => {
  if (step.value === 0) {
    return !firstName.value || !lastName.value;
  } else if (step.value === 1) {
    return !country.value;
  } else if (step.value === 2) {
    return !phone.value || !phoneValid.value;
  }

  return false;
});

if (user.value!.phone) {
  const currentPhoneNumber = parsePhoneNumberFromString(user.value!.phone, {
    defaultCountry: countries.find((c) => c.name === user.value!.country)
      ?.code as CountryCode | undefined,
  });
  if (currentPhoneNumber) {
    phoneCountry.value = currentPhoneNumber.country;
    phone.value = currentPhoneNumber.formatNational();
  }
}

const phoneValid = computed(() => {
  if (!phone.value) return;
  else if (!phoneCountry.value) return;
  return isValidPhoneNumber(phone.value, phoneCountry.value);
});

const formatPhone = () => {
  if (!phoneCountry.value || !phone.value) return;
  const asYouType = new AsYouType(phoneCountry.value);
  phone.value = asYouType.input(phone.value);
};
</script>

<template>
  <div class="flex h-full w-full overflow-hidden p-4">
    <OnboardingSidebar v-if="!loading" current-step="profile" />

    <div
      class="relative flex h-screen flex-1 flex-col items-center overflow-y-auto px-6 py-1 sm:px-8"
    >
      <div class="flex w-full items-center justify-center">
        <Logo />
      </div>

      <div
        v-if="!loading"
        class="mt-16 flex w-full flex-1 justify-center md:mt-20"
      >
        <div
          class="relative h-full w-full max-w-2xl py-16 text-marine-800 md:min-w-[672px]"
        >
          <div class="font-display text-4xl font-bold text-marine-800">
            {{ $t("onboarding.steps.accountCreation.content.title") }}
          </div>
          <div class="mt-4 font-display text-sm font-medium text-marine-500">
            {{ $t("onboarding.steps.accountCreation.content.subtitle") }}
          </div>

          <div class="my-10 text-lg text-marine-600">
            <span class="font-semibold">
              {{
                $t("onboarding.steps.accountCreation.content.description.bold")
              }}</span
            >
            {{
              $t("onboarding.steps.accountCreation.content.description.plain")
            }}
          </div>

          <div v-if="step === 0">
            <div class="font-display text-2xl font-bold">
              {{ $t("onboarding.form.nameQuestion") }}
            </div>
            <div class="mt-4 flex flex-col gap-3 md:flex-row">
              <TTextField
                v-model="firstName"
                :placeholder="$t('onboarding.form.firstName')"
                name="firstname"
                autofocus
                class="flex-1"
              />
              <TTextField
                v-model="lastName"
                :placeholder="$t('onboarding.form.lastName')"
                name="lastname"
                class="flex-1"
              />
            </div>
          </div>
          <div
            v-else-if="step > 0"
            class="flex cursor-pointer items-center justify-between border-b pb-3 text-marine-400 transition-colors hover:text-marine-600"
            @click="step = 0"
          >
            <div class="font-display text-lg font-bold">
              {{ $t("onboarding.form.nameQuestion") }}
            </div>
            <div class="font-medium">
              {{ user?.firstName }} {{ user?.lastName }}
            </div>
          </div>

          <div v-if="step === 1" class="mt-8">
            <div class="font-display text-2xl font-bold">
              {{ $t("onboarding.form.countryQuestion") }}
            </div>

            <TSelect
              v-model="country"
              :placeholder="$t('onboarding.form.country')"
              name="country"
              autofocus
              :items="countries"
              item-value="name"
              class="mt-4 w-full"
            >
              <template #item="{ item, selected }">
                <div
                  :class="[
                    selected ? 'font-bold' : 'font-normal',
                    'flex items-center truncate',
                  ]"
                >
                  <div class="mr-2">
                    {{ item.emoji }}
                  </div>
                  {{ item.name }}
                </div>
              </template>

              <template #selected="{ item }">
                <div class="'truncate items-center', flex">
                  <div class="mr-2">
                    {{ item.emoji }}
                  </div>
                  {{ item.name }}
                </div>
              </template>
            </TSelect>
          </div>
          <div
            v-else-if="step > 1"
            class="mt-3 flex cursor-pointer items-center justify-between border-b pb-3 text-marine-400 transition-colors hover:text-marine-600"
            @click="step = 1"
          >
            <div class="font-display text-lg font-bold">
              {{ $t("onboarding.form.countryQuestion") }}
            </div>
            <div class="font-medium">{{ user?.country }}</div>
          </div>

          <div v-if="step === 2" class="mt-8">
            <div class="font-display text-2xl font-bold">
              {{ $t("onboarding.form.phoneQuestion") }}
            </div>

            <div
              class="mt-4 flex w-full flex-col items-center gap-2 md:flex-row"
            >
              <TSelect
                v-model="phoneCountry"
                name="phone-country"
                :items="
                  getCountries().map((c) => ({
                    text: `${c} (+${getCountryCallingCode(c)})`,
                    value: c,
                  }))
                "
                class="w-full md:w-auto"
                @update:model-value="formatPhone"
              />
              <TTextField
                v-model="phone"
                :placeholder="
                  getExampleNumber(phoneCountry!, examples)?.formatNational()
                "
                name="phone-number"
                type="tel"
                autofocus
                class="w-full flex-1 md:w-auto"
                @update:model-value="formatPhone"
              />
            </div>
          </div>
          <div
            v-else-if="step > 2"
            class="mt-3 flex cursor-pointer items-center justify-between border-b pb-3 text-marine-400 transition-colors hover:text-marine-600"
            @click="step = 2"
          >
            <div class="font-display text-lg font-bold">
              {{ $t("onboarding.form.phoneQuestion") }}
            </div>
            <div class="font-medium">{{ user?.phone }}</div>
          </div>

          <!-- <div v-if="step === 3" class="mt-8">
            <div class="text-2xl font-bold my-6">
              How much would you like to invest <br />
              in European Carbon Allowances (EUAs) with Homaio ?

              <TTooltip
                placement="top"
                text="EUAs are the trading unit of Europe's Emissions Trading Scheme, which covers 40% of European CO2 emissions or roughly 1.5 Gt.CO2. They form the largest, most mature market with over 15 years of successful track record at delivering emissions reduction while increasing in value."
              >
                <i
                  class="mdi mdi-information text-marine-400 ml-2 hover:text-marine-500"
                />
              </TTooltip>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-3 mt-4 w-full gap-2">
              <label
                v-for="value in [
                  { text: '1k€ - 20k€', value: 1 },
                  { text: '20k€ - 100k€', value: 20 },
                  { text: '+100k€', value: 100 },
                ]"
                :key="value.value"
                class="px-4 h-12 border rounded-lg flex items-center justify-center transition cursor-pointer font-medium"
                :class="
                  amount === value.value
                    ? 'border-indigo-400 bg-indigo-100 text-marine-800'
                    : 'bg-white hover:bg-indigo-25 hover:border-gray-300 text-marine-700'
                "
                @click="amount = value.value"
              >
                {{ value.text }}
              </label>
            </div>
          </div> -->

          <div class="mt-4 flex">
            <div class="flex-1" />
            <TBtn :disabled="disabled" class="px-6" @click="next">
              {{ $t("global.next") }}
            </TBtn>
          </div>
        </div>
      </div>
      <div v-else class="flex h-full max-w-xl items-center justify-center">
        <Loader class="h-20 w-20 text-marine-500" />
        <div class="ml-6">
          <div class="text-5xl font-bold text-marine-800">
            {{ $t("global.thankYou") }}
          </div>
          <div class="mt-3 text-marine-500">
            We are currently processing your demand. See you on the other side !
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
