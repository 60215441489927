<template>
  <TModal
    :is-open="isOpen"
    modal-classes="w-full h-full rounded-none md:max-w-[1260px] md:max-h-[730px] md:rounded-3xl"
    @modal-close="closeModal"
  >
    <div class="h-full overflow-hidden bg-[#FAFBFD] md:min-h-[85svh]">
      <transition name="slow-fade" appear>
        <div
          v-show="isLoading"
          class="absolute flex h-full w-full items-center justify-center bg-black bg-opacity-20 lg:rounded-lg"
          style="z-index: 10"
        >
          <Loader class="h-10 w-10" />
        </div>
      </transition>
      <div :key="currentStep" class="absolute inset-0 h-full w-full">
        <component
          :is="currentStepComponent"
          v-model="transaction"
          @modal-close="closeModal"
          @next-step="goNextStep"
          @prev-step="goPrevStep"
          @show-error="showError"
        />
      </div>
    </div>
    <div
      v-show="errorVisible"
      class="absolute bottom-0 mb-4 ml-10 flex w-full max-w-[80vw] gap-2 overflow-hidden rounded-lg bg-danger-500 px-2 py-4 md:max-h-[730px] md:max-w-[600px]"
    >
      <TIcon icon="infoEmpty" color="#ffffff" />
      <p class="flex-1 text-white">{{ errorMessage }}</p>
      <TIcon
        class="cursor-pointer"
        icon="cross"
        color="#ffffff"
        @click="errorVisible = false"
      />
      <div
        ref="errorBar"
        class="absolute bottom-0 h-[3px] w-[100%] translate-x-[-10px] bg-white"
      ></div>
    </div>
  </TModal>
</template>

<script setup lang="ts">
import TModal from "@/components/designSystem/TModal.vue";
import SellModalTransactionStep from "@/containers/modals/sell/SellModalTransactionStep.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import SellTransaction from "@/models/sell-transaction";
import SellModalBankInfoStep from "@/containers/modals/sell/SellModalBankInfoStep.vue";
import SellModalOverviewStep from "@/containers/modals/sell/SellModalOverviewStep.vue";
import { SellModalStep } from "@/constants/sell-modal-constants";
import SellModalUpdateBankInfoStep from "@/containers/modals/sell/SellModalUpdateBankInfoStep.vue";
import Loader from "@/components/Loader.vue";
import { usePortfolioStore } from "@/stores/portfolio";
import { useOrdersStore } from "@/stores/orders";
import TIcon from "@/components/designSystem/TIcon.vue";
import { useAnimate, watchDeep } from "@vueuse/core";

const portfolioStore = usePortfolioStore();
const ordersStore = useOrdersStore();

const isOpen = defineModel<boolean>();
const transaction = ref<SellTransaction>(
  SellTransaction.fromPortfolio(portfolioStore.portfolio!),
);
const isLoading = ref(false);

const errorVisible = ref<boolean>(false);
const errorMessage = ref<string>("");

const steps = ref<SellModalStep[]>([SellModalStep.transaction]);

const errorBar = ref();

const { play, finish, playState } = useAnimate(
  errorBar,
  { width: "0%" },
  {
    duration: 3000,
    immediate: false,
  },
);

const currentStep = computed(() => {
  return steps.value[steps.value.length - 1];
});

const currentStepComponent = computed(() => {
  switch (currentStep.value) {
    case SellModalStep.transaction:
      return SellModalTransactionStep;
    case SellModalStep.bankInfo:
      return SellModalBankInfoStep;
    case SellModalStep.overview:
      return SellModalOverviewStep;
    case SellModalStep.bankInfoUpdate:
      return SellModalUpdateBankInfoStep;
    default:
      return null;
  }
});

const showError = (message: string) => {
  errorMessage.value = message;
  errorVisible.value = true;
  play();
};

watch(playState, (state) => {
  if (state === "finished") {
    errorVisible.value = false;
  }
});

watch(isOpen, (value) => {
  if (value) {
    transaction.value = SellTransaction.fromPortfolio(
      portfolioStore.portfolio!,
    );
  }
});

onBeforeMount(async () => {
  const estimation = await ordersStore.getEstimatedSellingData(
    transaction.value.bondToSell,
  );

  transaction.value.updateWithEstimation(estimation!);
});

const goNextStep = (step: SellModalStep) => {
  steps.value.push(step);
};

const goPrevStep = () => {
  steps.value.pop();
};

function closeModal() {
  transaction.value = SellTransaction.fromDefault();
  steps.value = [SellModalStep.transaction];
  isOpen.value = false;
}
</script>
