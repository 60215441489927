import type { App } from "vue";

import TBtn from "./TBtn.vue";
import TCounter from "./TCounter.vue";
import TDatePicker from "./TDatePicker.vue";
import TDeleteConfirmation from "./TDeleteConfirmation.vue";
import TDropZone from "./TDropZone.vue";
import TMenu from "./TMenu.vue";
import TModal from "./TModal.vue";
import TSelect from "./TSelect.vue";
import TSnackbar from "./TSnackbar.vue";
import TTextField from "./TTextField.vue";
import TTooltip from "./TTooltip.vue";
import TIcon from "./TIcon.vue";
import TButton from "./TButton.vue";
import TCallout from "@/components/designSystem/TCallout.vue";
import TCollapsableCallout from "@/components/designSystem/TCollapsableCallout.vue";

export const registerDesignSystem = (app: App<Element>) => {
  app.component("TSelect", TSelect);
  app.component("TMenu", TMenu);
  app.component("TTextField", TTextField);
  app.component("TDatePicker", TDatePicker);
  app.component("TBtn", TBtn);
  app.component("TDeleteConfirmation", TDeleteConfirmation);
  app.component("TTooltip", TTooltip);
  app.component("TCounter", TCounter);
  app.component("TDropZone", TDropZone);
  app.component("TModal", TModal);
  app.component("TSnackbar", TSnackbar);
  app.component("TIcon", TIcon);
  app.component("TButton", TButton);
  app.component("TCallout", TCallout);
  app.component("TCollapsableCallout", TCollapsableCallout);
};
