import { defineStore } from "pinia";
import { ref } from "vue";

import axios from "@/lib/axios";
import { type UserKyc } from "@/types/user-kyc";
import { AxiosError, type AxiosResponse } from "axios";

export const useKycStore = defineStore("kyc", () => {
  const kyc = ref<UserKyc | undefined>();

  const loadKyc = async () => {
    if (kyc.value) return kyc.value;

    try {
      const request = await axios.get("/user/kyc");
      kyc.value = request.data;
    } catch (e) {
      kyc.value = undefined;
    }

    return kyc.value;
  };

  const updateBankInfo = async (bankInfo: {
    iban: string;
    bankName: string;
  }) => {
    let request: AxiosResponse;

    try {
      request = await axios.patch("/user/kyc", bankInfo);
    } catch (e) {
      const error = e as AxiosError;
      throw error.response?.data;
    }

    kyc.value = request.data;

    return kyc.value;
  };

  return {
    kyc,
    loadKyc,
    updateBankInfo,
  };
});
