<template>
  <div class="absolute h-full w-full">
    <div class="flex justify-between px-4 pt-4">
      <TIcon icon="arrowLeft" class="cursor-pointer" @click.stop="goPrevStep" />
      <TIcon icon="cross" class="cursor-pointer" @click.stop="closeModal" />
    </div>
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div class="flex h-full w-full justify-center pb-6">
        <div
          class="flex h-full w-full max-w-screen-sm flex-col overflow-scroll p-6"
        >
          <h2 class="mb-4 font-display text-xl font-[600] text-marine-900">
            {{ $t("sell.modal.overview.title") }}
          </h2>
          <div class="relative mt-4 flex flex-col gap-1">
            <div class="w-full rounded-lg border border-marine-50 bg-white p-4">
              <p class="mb-4 text-marine-600">
                {{ $t("sell.modal.overview.sellingSubtitle") }}
              </p>
              <div class="mb-4 flex flex-col gap-3 lg:flex-row lg:items-center">
                <p class="text-2xl font-semibold text-marine-900">
                  {{ transaction.bondToSell }}
                  {{ $t("global.bonds", transaction.bondToSell) }}
                </p>
                <div class="flex items-center gap-2">
                  <p class="text-marine-400">
                    =
                    {{ transaction.euaToSell.toFixed(2) }}
                    EUAs
                  </p>
                  <img
                    class="h-4 w-4 rounded-full object-cover"
                    :src="euaImage"
                    alt=""
                  />
                  <p class="text-marine-400">
                    = {{ transaction.euaToSell.toFixed(2) }}
                    {{ $t("sell.modal.overview.tonsCo2") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="relative m-0">
              <div
                class="absolute left-[50%] top-0 top-[-20px] flex translate-x-[-50%] items-center justify-center rounded-lg bg-marine-900 p-3"
              >
                <TIcon icon="arrowDown" color="neutral-50" />
              </div>
            </div>
            <div class="w-full rounded-lg border border-marine-50 bg-white p-4">
              <p class="mb-4 mt-2 text-marine-600 lg:mt-0">
                {{ $t("sell.modal.overview.receiveSubtitle") }}
              </p>
              <div class="flex items-center gap-3">
                <p class="mb-4 text-2xl font-semibold text-marine-900">
                  {{ transaction.estimatedValue }} €
                </p>
              </div>
              <div class="mb-4 flex items-center gap-2 text-marine-300">
                <p class="italic">
                  {{
                    $t("sell.modal.transactionPreview.custodyFees.title", {
                      fee: transaction.fee,
                    })
                  }}
                  <span class="inline-block translate-y-[18%]">
                    <TTooltip
                      placement="bottom"
                      :text="
                        $t('sell.modal.transactionPreview.custodyFees.tooltip')
                      "
                      tooltip-classes="bg-secondary"
                    >
                      <TIcon icon="infoFull" size="xs" color="marine-300" />
                    </TTooltip>
                  </span>
                </p>
              </div>
              <div>
                <div class="flex justify-between">
                  <p class="text-marine-600">
                    {{ $t("sell.modal.overview.to") }}
                  </p>
                  <p
                    class="cursor-pointer text-secondary underline underline-offset-4"
                    @click="goUpdateBankInfoStep"
                  >
                    {{ $t("sell.modal.overview.modify") }}
                  </p>
                </div>
                <div class="mt-2 flex flex-col gap-1 font-semibold">
                  <p>{{ kyc.bankHolder }}</p>
                  <p>{{ kyc.bankName }}</p>
                  <p>{{ kyc.iban }}</p>
                </div>
              </div>
            </div>
          </div>
          <TCallout icon="warningTriangleOutline">
            <p>
              {{ $t("sell.modal.transactionPreview.estimatedValueCallout") }}
            </p>
          </TCallout>
          <div class="mt-10">
            <h2 class="font-semibold">
              {{ $t("sell.modal.transactionPreview.sellProcessDetails") }}
            </h2>
            <div class="mt-4 px-2">
              <VerticalStepper :steps="steps" />
            </div>
          </div>
          <div class="mt-6">
            <TButton
              class="w-full"
              variant="primary"
              @click="openComfirmationModal"
              >{{ $t("sell.modal.overview.confirmSale") }}</TButton
            >
          </div>
        </div>
      </div>
    </transition>
    <TModal :is-open="comfirmationModalOpen" modal-classes="max-w-lg">
      <div class="flex flex-col gap-3 p-10">
        <p class="text-center text-xl font-semibold text-primary-800">
          {{ $t("sell.modal.confirmationDialog.title") }}
        </p>
        <p class="text-center text-primary-600">
          {{ $t("sell.modal.confirmationDialog.content") }}
        </p>
        <div class="mt-6 flex flex-col gap-2">
          <TButton class="w-full" @click="confirmTransaction">
            <p v-if="!isLoading">
              {{ $t("sell.modal.confirmationDialog.yes") }}
            </p>
            <Loader v-else class="text-white" />
          </TButton>
          <TButton
            class="w-full"
            variant="danger"
            @click="closeComfirmationModal"
          >
            <p>
              {{ $t("sell.modal.confirmationDialog.no") }}
            </p>
          </TButton>
        </div>
      </div>
    </TModal>
  </div>
</template>

<script setup lang="ts">
import SellTransaction from "@/models/sell-transaction";
import VerticalStepper from "@/components/VerticalStepper.vue";
import TButton from "@/components/designSystem/TButton.vue";
import { computed, ref } from "vue";
import { getNextClosingDate } from "@/helpers/date-helpers";
import { useI18n } from "vue-i18n";
import euaImage from "@/assets/imgs/devises/eua.png";
import { useKycStore } from "@/stores/kyc";
import { type UserKyc } from "@/types/user-kyc";
import { SellModalStep } from "@/constants/sell-modal-constants";
import { useNotificationsStore } from "@/stores/notifications";
import { usePortfolioStore } from "@/stores/portfolio";
import { useOrdersStore } from "@/stores/orders";
import Loader from "@/components/Loader.vue";

const { locale, t: $t } = useI18n();

const transaction = defineModel<SellTransaction>({ required: true });
const kycStore = useKycStore();
const notificationStore = useNotificationsStore();
const portfolioStore = usePortfolioStore();
const ordersStore = useOrdersStore();

const kyc: UserKyc = kycStore.kyc!;

const comfirmationModalOpen = ref(false);
const isLoading = ref(false);

const transactionDates = computed(() => {
  return getNextClosingDate(locale.value, $t);
});

const emit = defineEmits(["modal-close", "prev-step", "next-step"]);

const steps = ref([
  {
    key: "order",
    label: $t("sell.modal.transactionPreview.steps.orderConfirmed"),
  },
  {
    key: "closing",
    label: $t("sell.modal.transactionPreview.steps.settlement"),
    suffix: transactionDates.value.closing,
  },
  {
    key: "market",
    label: $t("sell.modal.transactionPreview.steps.orderProcessed"),
    suffix: transactionDates.value.processing,
  },
  {
    key: "validation",
    label: $t("sell.modal.transactionPreview.steps.pendingValidation"),
  },
  {
    key: "wire",
    label: $t("sell.modal.transactionPreview.steps.transfer"),
    suffix: $t("sell.modal.transactionPreview.steps.transferSuffix"),
  },
]);

const showTransactionError = () => {
  notificationStore.addNotification(
    $t("sell.modal.overview.errorMessage"),
    "error",
  );
};

const confirmTransaction = async () => {
  isLoading.value = true;

  try {
    const success = await ordersStore.sellEua(transaction.value.bondToSell);
    if (success) {
      await portfolioStore.reloadPortfolio();
      await ordersStore.getCurrentSellOrder();
      notificationStore.addNotification(
        $t("sell.modal.overview.successMessage"),
        "success",
      );
      closeModal();
    } else {
      showTransactionError();
    }
  } catch (_) {
    showTransactionError();
  } finally {
    isLoading.value = false;
  }
};

const openComfirmationModal = () => {
  comfirmationModalOpen.value = true;
};

const closeComfirmationModal = () => {
  comfirmationModalOpen.value = false;
};

const goUpdateBankInfoStep = () => {
  emit("next-step", SellModalStep.bankInfoUpdate);
};

const goPrevStep = () => {
  transaction.value.bankInfoVerified = false;
  emit("prev-step");
};

const closeModal = () => {
  emit("modal-close");
};
</script>
