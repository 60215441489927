<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";

import axios from "@/lib/axios";
import type { MetricKey } from "@/types/products";

import {
  getCurrencyFormatter,
  nFormatter,
  getNumberFormatter,
  getPercentageFormatter,
  parseAmount,
} from "@/utils/numbers";

import background from "@/assets/background.png";
import euaImage from "@/assets/eua.png";
import ccaImage from "@/assets/cca.png";
import ukaImage from "@/assets/uka.png";

import EUAHistoryChart from "@/containers/assets/EUAHistoryChart.vue";
import Banner from "@/containers/onboarding/Banner.vue";
import Check from "@/components/Check.vue";

import { usePortfolioStore } from "@/stores/portfolio";
import { usePricesStore } from "@/stores/prices";
import { useBatchesStore } from "@/stores/batches";
import { useAuthStore } from "@/stores/auth";
import { useOrdersStore } from "@/stores/orders";

const router = useRouter();

const portfolioStore = usePortfolioStore();
const pricesStore = usePricesStore();
const batchesStore = useBatchesStore();
const authStore = useAuthStore();
const ordersStore = useOrdersStore();

const availableComparisons = [
  {
    icon: "⚡",
    name: "averageEnergyPlant",
    ratio: 25.51,
  },
  {
    icon: "🇪🇺",
    name: "yearlyCO2emission",
    ratio: 6.2,
  },
  {
    icon: "🚗",
    name: "yearlyCommuteByCar",
    ratio: 2,
  },
];

const availableMetrics = [
  {
    key: "volume1D",
    formatted: (v: number) => getNumberFormatter(0).format(v) + " EUAs",
  },
  {
    key: "low1Y",
    formatted: (v: number) => getCurrencyFormatter("EUR").format(v),
  },
  {
    key: "volatility90D",
    formatted: (v: number) => getPercentageFormatter().format(v),
  },
  {
    key: "high1Y",
    formatted: (v: number) => getCurrencyFormatter("EUR").format(v),
  },
  {
    key: "marketValue",
    formatted: (v: number) => getCurrencyFormatter("EUR").format(v),
  },
  {
    key: "ath",
    formatted: (v: number) => getCurrencyFormatter("EUR").format(v),
  },
];

const MAX_ICONS = 100;

const step = ref<number>(0);
const learnMore = ref<boolean>(false);
const loading = ref<boolean>(false);
const amount = ref<number>(1000);
const slider = ref();
const currentComparison = ref(availableComparisons[0]);

const { lastPrice } = storeToRefs(portfolioStore);
const { summary } = storeToRefs(pricesStore);
const { nextBatchEnd, currentBatch } = storeToRefs(batchesStore);
const { user } = storeToRefs(authStore);
const { latestOrder } = storeToRefs(ordersStore);

const hasExistingOrder =
  latestOrder.value?.id && latestOrder.value?.batch === currentBatch.value?.id;

const props = withDefaults(
  defineProps<{
    reBuy?: boolean;
  }>(),
  {
    reBuy: false,
  },
);

// if re-buy process, skip asset selection
if (props.reBuy) {
  step.value = 1;
}

if (hasExistingOrder) {
  amount.value = latestOrder.value.amount;
  step.value = 1;
}

const calculateAmount = (x: number) => {
  if (x <= 33) {
    amount.value = Math.round(((x / 33) * 19000 + 1000) / 1000) * 1000;
  } else if (x <= 66) {
    amount.value = Math.round((((x - 33) / 33) * 80000 + 20000) / 1000) * 1000;
  } else if (x <= 1000) {
    amount.value =
      Math.round((((x - 66) / 34) * 900000 + 100000) / 1000) * 1000;
  } else {
    amount.value = 1000000;
  }
};

const increase = () => {
  amount.value = Math.ceil((amount.value + 1000) / 100) * 100;
};

const decrease = () => {
  if (amount.value <= 1000) return;

  amount.value = Math.ceil((amount.value - 1000) / 100) * 100;
};

const preventMinus = (e: KeyboardEvent) => {
  if (e.key === "-") {
    e.preventDefault();
  }
};

const preventPasteNegative = (e: ClipboardEvent) => {
  const clipboardData = e.clipboardData;
  const pastedData = clipboardData!.getData("Text");
  if (pastedData.includes("-")) {
    e.preventDefault();
  }
};

const invest = async () => {
  if (!amount.value) return;

  try {
    loading.value = true;
    const userRequest = await axios.patch("/user", { amount: amount.value });
    user.value = userRequest.data;

    const orderRequest =
      latestOrder.value?.id &&
      latestOrder.value?.batch === currentBatch.value?.id
        ? await axios.patch(`/orders/${latestOrder.value.id}`, {
            amount: amount.value,
          })
        : await axios.post("/orders/buy", {
            amount: amount.value,
            product: "eua",
          });

    if ([200, 201].includes(orderRequest.status)) {
      await ordersStore.getLatestOrder();
      user.value!.status = "kyc_pending";

      if (!props.reBuy) {
        router.push({ name: "onboarding-kyc" });
      }
    }
  } catch (err) {
    console.error(err);
  } finally {
    loading.value = false;
  }
};

const position = computed(() => {
  if (amount.value <= 20000) {
    return ((amount.value - 1000) / 19000) * 33;
  } else if (amount.value <= 100000) {
    return 33 + ((amount.value - 20000) / 80000) * 33;
  } else if (amount.value <= 1000000) {
    return 66 + ((amount.value - 100000) / 900000) * 34;
  } else {
    return 101;
  }
});

const comparisonResult = computed(() => {
  if (!amount.value || amount.value < 0)
    return { total: 0, icons: "", rest: "" };

  const result =
    amount.value / lastPrice.value?.price / currentComparison.value.ratio;

  return {
    total: result,
    icons: `${currentComparison.value.icon} `.repeat(
      result < MAX_ICONS ? result : MAX_ICONS,
    ),
    rest: result < MAX_ICONS ? "" : `+ ${Math.ceil(result - MAX_ICONS)}`,
  };
});

const canInvest = computed(() => amount.value >= 1000);

const assets = computed(() => [
  {
    key: "eua",
    image: euaImage,
    nextBatchEnd: nextBatchEnd.value,
  },
  {
    key: "uka",
    image: ukaImage,
  },
  {
    key: "cca",
    image: ccaImage,
  },
]);

watch([slider, amount], () => {
  if (!slider.value) return;

  slider.value.style.background = `linear-gradient(to right, #C1DBFF ${position.value}%, #E6E8EE ${position.value}%)`;
});

Promise.all([
  batchesStore.getActiveBatch(),
  portfolioStore.fetchLastPrice(),
  pricesStore.fetchSummary(),
]);
</script>
<template>
  <div
    class="flex w-full"
    :class="{
      'relative h-[85svh] overflow-auto': props.reBuy,
    }"
  >
    <div class="flex w-full flex-1 justify-center">
      <div
        v-if="step === 0"
        class="relative h-full w-full py-12 text-marine-800 md:min-w-[672px]"
      >
        <div class="max-w-2xl">
          <div class="font-display text-3xl font-bold text-marine-900">
            {{ $t("onboarding.steps.invest.content.title.full") }}
          </div>
          <div class="mt-4 text-sm font-semibold text-marine-400">
            {{ $t("onboarding.steps.invest.content.subtitle") }}
          </div>
        </div>

        <div
          class="no-scrollbar mt-12 flex flex-row flex-wrap overflow-x-auto md:flex-nowrap md:gap-6"
        >
          <!-- Seems the custom shadow with tailwind is buggy, doing this with style works -->
          <div
            v-for="asset in assets"
            :key="asset.key"
            class="mb-10 overflow-hidden rounded-2xl 2xl:min-w-[360px]"
            :class="{
              'border-2 border-marine-25 opacity-70': !asset.nextBatchEnd,
              'border-2 border-snuff-50 bg-white md:ml-3': asset.nextBatchEnd,
              'md:min-w-[330px]': !props.reBuy,
            }"
            :style="[
              asset.nextBatchEnd
                ? {
                    boxShadow: '1px 5px 16px 0px rgba(0, 0, 0, 0.12)',
                  }
                : {},
            ]"
          >
            <div
              class="relative h-[150px] w-full bg-cover bg-center bg-no-repeat px-7 py-8"
              :style="{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${asset.image})`,
              }"
            >
              <div class="text-2xl font-medium text-white">
                {{
                  $t(`onboarding.steps.invest.content.assets.${asset.key}.name`)
                }}
              </div>

              <div
                class="text-lg font-medium uppercase text-white text-opacity-50"
              >
                {{ asset.key }}
              </div>
              <div
                class="absolute right-2 top-2 rounded-full px-3 py-1 font-display text-xs font-semibold text-white 3xl:right-3 3xl:top-3"
                :class="{
                  'bg-[#3265E7]': asset.nextBatchEnd,
                  'border border-b-[0.5px] border-l-[1px] border-r-[0.5px] border-t-[1px] border-[rgba(255,255,255,0.6)] border-b-[rgba(255,255,255,0.4)] border-r-[rgba(255,255,255,0.4)] bg-white bg-opacity-20':
                    !asset.nextBatchEnd,
                }"
              >
                {{
                  asset.nextBatchEnd
                    ? $t("onboarding.steps.invest.content.live")
                    : $t("onboarding.steps.invest.content.comingSoon")
                }}
              </div>
            </div>
            <div class="flew-row flex min-h-[160px] justify-between px-7 py-8">
              <div class="basis-2/5">
                <div class="text-xs font-medium text-marine-800">
                  {{ $t("onboarding.steps.invest.content.performance.label") }}
                </div>
                <div class="mt-3">
                  <div class="text-2xl font-semibold text-marine-900">
                    {{
                      $t(
                        `onboarding.steps.invest.content.assets.${asset.key}.performance`,
                      )
                    }}
                    <span class="text-lg">%</span>
                  </div>
                  <div class="text-xs text-marine-400">
                    {{
                      $t("onboarding.steps.invest.content.performance.suffix")
                    }}
                  </div>
                </div>
              </div>
              <div class="border-r border-marine-50" />
              <div class="basis-2/5">
                <div class="text-xs font-medium text-marine-800">
                  {{ $t("onboarding.steps.invest.content.impact.label") }}
                </div>
                <div class="mt-3">
                  <div class="text-2xl font-semibold text-marine-900">
                    {{
                      $t(
                        `onboarding.steps.invest.content.assets.${asset.key}.impact`,
                      )
                    }}
                    <span class="text-lg">%</span>
                  </div>
                  <div class="text-xs text-marine-400">
                    {{ $t("onboarding.steps.invest.content.impact.suffix") }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="flex h-[100px] flex-row items-center justify-center gap-6 bg-snuff-25 px-7 py-4 2xl:gap-24"
            >
              <template v-if="asset.nextBatchEnd">
                <div class="text-left text-marine-400">
                  <div class="text-xs">
                    {{
                      $t("onboarding.steps.invest.content.nextRoundClosing")
                    }}:
                  </div>
                  <div class="font-display text-xl font-bold">
                    {{ asset.nextBatchEnd.format("DD MMM YYYY") }}
                  </div>
                </div>
                <button
                  class="block rounded-lg bg-marine-900 px-3 font-display font-bold tracking-[0.16px] text-white md:h-12 md:whitespace-nowrap"
                  @click="step = 1"
                >
                  {{ $t("onboarding.steps.invest.content.startInvesting") }}
                </button>
              </template>
              <template v-else>
                <div
                  class="flex items-center text-center font-display text-xl font-bold text-marine-300"
                >
                  {{ $t("onboarding.steps.invest.content.comingSoon") }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <Transition
        enter-active-class="transition-opacity duration-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-75 absolute"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="step === 1"
          class="relative h-full w-full py-3 text-marine-800 md:min-w-[672px]"
          :class="{
            'mt-10': !props.reBuy,
            'mt-10 p-6': props.reBuy,
          }"
        >
          <div class="font-display">
            <span class="text-4xl font-bold text-marine-800">
              {{ $t("onboarding.steps.invest.content.becomeCarbonInvestor") }}
            </span>
            <br />
            <span class="text-lg font-medium md:text-2xl">
              {{ $t("onboarding.steps.invest.content.EUA") }}
            </span>
          </div>

          <div
            class="mt-4 w-full bg-cover p-6 shadow"
            :style="{ backgroundImage: `url(${background})` }"
          >
            <div class="">
              {{ $t("onboarding.steps.invest.content.howMuch") }}
            </div>

            <div
              class="align-center mt-8 flex flex-wrap items-center justify-start gap-6 md:flex-row md:flex-nowrap md:gap-0"
            >
              <div
                class="relative flex h-[50px] w-full flex-row items-center justify-between rounded-lg bg-white p-4 md:basis-2/5 md:p-8"
              >
                <div
                  class="h-[16px] w-[16px] cursor-pointer select-none rounded-full border border-marine-800 text-center leading-3"
                  @click="decrease"
                >
                  -
                </div>
                <input
                  class="w-[100%] rounded-md text-center text-2xl font-bold md:text-3xl"
                  :value="
                    amount === undefined
                      ? undefined
                      : getCurrencyFormatter('EUR', 0).format(amount)
                  "
                  :placeholder="getCurrencyFormatter('EUR', 0).format(0)"
                  pattern="[0-9]"
                  inputmode="numeric"
                  @keypress="preventMinus"
                  @paste="preventPasteNegative"
                  @input="
                    (event) =>
                      (amount =
                        amount < 100
                          ? parseAmount(
                              (event.target as HTMLInputElement).value,
                            )
                          : Math.ceil(
                              parseAmount(
                                (event.target as HTMLInputElement).value,
                              ) / 100,
                            ) * 100)
                  "
                />
                <div
                  class="h-[16px] w-[16px] cursor-pointer select-none rounded-full border border-marine-800 text-center leading-3"
                  @click="increase"
                >
                  +
                </div>
                <div
                  v-if="!canInvest"
                  class="absolute -bottom-5 -left-0 inline-block w-[300px] text-left text-xs font-medium text-red-400 md:-bottom-6 2xl:w-full 2xl:text-center 2xl:text-base"
                >
                  {{ $t("onboarding.steps.invest.content.minimumAmount") }}
                </div>
              </div>

              <div class="w-full px-6 text-center md:basis-1/4 md:text-left">
                <div class="font-medium 2xl:text-xl">
                  =
                  {{ nFormatter(amount ? amount / lastPrice.price : 0, 2) }}
                  EUAs
                </div>
                <div>
                  {{ $t("onboarding.steps.invest.content.atCurrentPrice") }}
                </div>
              </div>
            </div>

            <div class="mt-8 hidden rounded-lg bg-white p-6 md:block">
              <div class="text-2xl">
                {{ $t("onboarding.steps.invest.content.opportunity") }}
              </div>
              <div class="mt-4 flex flex-row">
                <div class="basis-1/6">
                  <div class="text-marine-400">
                    {{
                      $t("onboarding.steps.invest.content.initialInvestment")
                    }}
                  </div>
                  <div
                    class="mt-3 rounded-md bg-marine-25 p-1 text-center text-xl font-medium"
                  >
                    {{ getCurrencyFormatter("EUR", 0).format(amount) }}
                  </div>
                </div>
                <div class="ml-5 grow">
                  <div class="relative flex w-[95%]">
                    <div class="basis-[30.5%]">1K€</div>
                    <div class="basis-[32%]">20K€</div>
                    <div class="grow">100K€</div>
                    <div>+1m€</div>
                  </div>
                  <input
                    ref="slider"
                    type="range"
                    min="0"
                    max="100"
                    :value="position"
                    step="1"
                    class="h-1 w-[95%] appearance-none bg-marine-50"
                    @input="
                      (event) =>
                        calculateAmount(
                          +(event.target as HTMLInputElement).value,
                        )
                    "
                  />
                  <div class="flex w-[95%]">
                    <ul class="block basis-[33.3%] border-r-2 px-2 text-xs">
                      <li class="flex items-center text-marine-400">
                        <Check class="h-3 w-3" />
                        <span class="ml-1">
                          {{
                            $t(
                              "onboarding.steps.invest.content.accessToDashboard",
                            )
                          }}
                        </span>
                      </li>
                      <li class="flex items-center text-marine-400">
                        <Check class="h-3 w-3" /><span class="ml-1">
                          {{ $t("onboarding.steps.invest.content.basicFees") }}
                        </span>
                      </li>
                    </ul>
                    <ul class="basis-[32.6%] border-r-2 px-2 text-xs">
                      <li class="flex items-center text-marine-400">
                        <Check class="h-3 w-3" :disabled="amount < 20000" />
                        <span class="ml-1">
                          {{
                            $t(
                              "onboarding.steps.invest.content.accessToDashboard",
                            )
                          }}</span
                        >
                      </li>
                      <li class="flex items-center text-marine-400">
                        <Check class="h-3 w-3" :disabled="amount < 20000" />
                        <span class="ml-1">
                          {{
                            $t("onboarding.steps.invest.content.reducedFees")
                          }}</span
                        >
                      </li>
                    </ul>
                    <div class="grow px-6">
                      <a
                        href="https://meetings-eu1.hubspot.com/valentin-lautier/homaio-sales-call"
                        target="_blank"
                        class="mt-3 block w-full rounded-full p-2 text-center font-display text-xs font-semibold"
                        :class="{
                          'bg-marine-800 text-white': amount >= 100000,
                          'pointer-events-none border  border-marine-200 bg-white text-marine-200':
                            amount < 100000,
                        }"
                        :disabled="amount < 100000"
                      >
                        {{ $t("onboarding.steps.invest.content.contactUs") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="relative mt-12 flex w-full flex-row">
                <div class="basis-2/5 flex-col">
                  <div class="text-marine-400">
                    {{ $t("onboarding.steps.invest.content.EUAequivalent") }}
                  </div>
                  <div class="text-xl font-medium">
                    {{ nFormatter(amount ? amount / lastPrice.price : 0, 2) }}
                    {{ $t("onboarding.steps.invest.content.CO2tons") }}
                  </div>
                  <ul class="mt-4">
                    <li
                      v-for="comparison of availableComparisons"
                      :key="comparison.name"
                      class="mt-1"
                    >
                      <button
                        class="block rounded-full p-2 text-left font-display text-xs font-medium 2xl:text-lg"
                        :class="{
                          'bg-marine-800 text-white':
                            currentComparison.icon === comparison.icon,
                          'bg-slate-50 text-marine-400':
                            currentComparison.icon !== comparison.icon,
                        }"
                        @click="currentComparison = comparison"
                      >
                        {{
                          $t(
                            `onboarding.steps.invest.content.${comparison.name}`,
                          )
                        }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div
                  class="h-[180px] basis-3/5 break-all rounded-md bg-gray-50 p-2 2xl:h-[220px]"
                >
                  <div class="mb-2">
                    <span class="mr-1 font-display text-marine-400">
                      {{
                        $t(
                          `onboarding.steps.invest.content.${currentComparison.name}`,
                        )
                      }}
                      -
                    </span>
                    <span class="font-bold">{{
                      nFormatter(comparisonResult.total, 2)
                    }}</span>
                  </div>

                  <transition-group
                    :key="currentComparison.icon"
                    name="list"
                    tag="span"
                  >
                    <span
                      v-for="(icon, index) in comparisonResult.icons
                        .split(' ')
                        .filter((x) => x)"
                      :key="`${icon}-${index}`"
                      :data-index="`${icon}-${index}`"
                      class="ml-1 inline-block text-sm 2xl:mt-2 2xl:text-xl"
                      >{{ icon }}</span
                    >
                  </transition-group>

                  <span
                    v-if="comparisonResult.rest"
                    class="ml-1 transform-gpu rounded-full bg-blue-600 p-1 text-xs text-white"
                    >{{ comparisonResult.rest }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <EUAHistoryChart class="mt-4" />

          <div
            class="mb-32 mt-4 flex flex-row flex-wrap gap-4 md:mb-20 md:flex-nowrap"
          >
            <div
              class="rounded border bg-white p-4 shadow-sm md:h-[320px] md:basis-1/2 2xl:h-[275px]"
            >
              <div class="font-display text-lg font-semibold text-marine-700">
                {{ $t("onboarding.steps.invest.content.metrics.title") }}
              </div>
              <div
                v-if="summary?.metrics"
                class="flex flex-wrap justify-between"
              >
                <div
                  v-for="metric in availableMetrics"
                  :key="metric.key"
                  class="flex-1 basis-1/2 md:mt-6 md:gap-2 2xl:mt-4"
                >
                  <div class="font-display text-marine-400">
                    {{
                      $t(
                        `onboarding.steps.invest.content.metrics.${metric.key}`,
                      )
                    }}
                  </div>
                  <div class="text-bold mt-1 text-marine-900">
                    {{
                      summary.metrics[metric.key as MetricKey]
                        ? metric.formatted(
                            summary.metrics[metric.key as MetricKey] as number,
                          )
                        : "-"
                    }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="rounded border bg-white p-4 shadow-sm transition-all duration-100 ease-in-out md:h-[320px] md:basis-1/2 2xl:h-[275px]"
              :class="{
                'md:h-[650px] 2xl:h-[530px]': learnMore,
                'mb-32': props.reBuy,
              }"
            >
              <div
                class="flex items-center font-display text-lg font-semibold text-marine-700"
              >
                {{ $t("onboarding.steps.invest.content.aboutEUAs.title") }}
              </div>
              <div
                class="mt-6 whitespace-pre-line font-display text-sm text-marine-400"
              >
                {{ $t("onboarding.steps.invest.content.aboutEUAs.text.short") }}
                <button
                  v-if="!learnMore"
                  class="mt-4 block cursor-pointer"
                  @click="learnMore = true"
                >
                  {{ $t("global.learnMore") }}
                </button>
                <span v-if="learnMore" class="mt-2">
                  {{
                    $t("onboarding.steps.invest.content.aboutEUAs.text.long")
                  }}
                </span>

                <button
                  v-if="learnMore"
                  class="mt-4 block cursor-pointer"
                  @click="learnMore = false"
                >
                  {{ $t("global.readLess") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
    <Banner
      v-if="step > 0"
      :class="{
        '!bottom-14 left-[0.5rem] w-[calc(100%-1rem)] md:!bottom-12 md:!left-[4.5rem] md:!w-[calc(100%-9rem)] 2xl:bottom-24':
          props.reBuy,
        '!left-3 md:!bottom-0 md:!left-[24.5rem] md:!w-[calc(100%-26.5rem)]':
          !props.reBuy,
      }"
    >
      <template #button>
        <div class="mt-2 md:mt-0 lg:basis-1/4">
          <button
            class="block w-full rounded-full p-2 px-4 text-sm font-semibold md:p-4 lg:p-2"
            :class="{
              'pointer-events-none border border-marine-200 bg-white text-marine-400':
                !canInvest || loading,
              'border-none bg-marine-800 text-white': canInvest,
            }"
            :disabled="!canInvest || loading"
            @click="invest"
          >
            {{ $t("onboarding.steps.invest.content.invest") }}
          </button>
        </div>
      </template>
    </Banner>
  </div>
</template>

<style scoped>
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #041952;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from {
  opacity: 0;
  transform: scale(0.1);
}
.list-enter-to {
  opacity: 1;
  transform: scale(1);
}
.list-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>
