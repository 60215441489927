import { defineStore } from "pinia";
import { computed, ref } from "vue";

import axios from "@/lib/axios";

import type { MarketPrice } from "@/types/products";
import {
  TransactionAccount,
  TransactionType,
  type Transaction,
} from "@/types/transaction";

import { type Portfolio } from "@/types/portfolio";

export const usePortfolioStore = defineStore("portfolio", () => {
  const transactions = ref<Transaction[]>();
  const portfolio = ref<Portfolio>();
  const lastPrice = ref<MarketPrice>({
    price: 0,
    timestamp: Date.now().toString(),
  });

  const loadPortfolio = async () => {
    if (portfolio.value) return portfolio.value;

    try {
      const request = await axios.get("/portfolio");
      portfolio.value = request.data;
    } catch (e) {
      portfolio.value = undefined;
    }

    return portfolio.value;
  };

  const reloadPortfolio = async () => {
    try {
      const request = await axios.get("/portfolio");
      portfolio.value = request.data;
    } catch (e) {
      portfolio.value = undefined;
    }

    return portfolio.value;
  };

  const fetchTransactions = async () => {
    try {
      const request = await axios.get("/transactions");
      transactions.value = request.data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchLastPrice = async () => {
    try {
      const request = await axios.get("/products/eua/price");
      lastPrice.value = request.data;
    } catch (e) {
      console.error(e);
    }
  };

  const totalInvested = computed(() => {
    if (!transactions.value) return;
    return transactions.value
      .filter(
        (t) =>
          t.type === TransactionType.TRANSFER &&
          t.to_account === TransactionAccount.BATCH,
      )
      .reduce((s, t) => s + t.to_amount, 0);
  });

  const totalEUA = computed(() => {
    if (!transactions.value) return;
    const EUAin = transactions.value
      .filter((t) => t.to_account === TransactionAccount.EUA)
      .reduce((s, t) => s + t.to_amount, 0);

    const EUAout = transactions.value
      .filter((t) => t.from_account === TransactionAccount.EUA)
      .reduce((s, t) => s + t.from_amount, 0);

    return EUAin - EUAout;
  });

  const totalBalance = computed(() => {
    if (totalEUA.value === undefined) return;
    else if (lastPrice.value === undefined) return;
    return totalEUA.value * lastPrice.value.price;
  });

  return {
    transactions,
    lastPrice,
    fetchTransactions,
    fetchLastPrice,
    totalInvested,
    totalEUA,
    totalBalance,
    loadPortfolio,
    portfolio,
    reloadPortfolio,
  };
});
