<template>
  <span>{{ formatedAmount }}</span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface Data {
  startAmount: number;
  endAmount: number;
  timestamp: number;
  startTimestamp: number | null;
  currentStartAmount: number;
  currentAmount: number;
  currentDuration: number;
  remaining: number;
  animationFrame: number;
}

export default defineComponent({
  name: "TCounter",
  interval: null,
  props: {
    value: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 1,
      validator(duration: number): boolean {
        return duration >= 1;
      },
    },
    formatter: {
      type: Function,
      default: (v: number): string => v.toString(),
    },
  },
  data(): Data {
    return {
      startAmount: 0,
      endAmount: 0,
      timestamp: 0,
      startTimestamp: 0,
      currentAmount: 0,
      currentStartAmount: 0,
      currentDuration: 0,
      remaining: 0,
      animationFrame: 0,
    };
  },
  computed: {
    isCountingUp(): boolean {
      return this.endAmount > this.startAmount;
    },
    formatedAmount(): string {
      return this.formatter(this.currentAmount);
    },
  },
  watch: {
    value(value: number, oldValue: number): void {
      this.startAmount = oldValue;
      this.endAmount = value;
      this.reset();
    },
  },
  mounted(): void {
    this.startAmount = 0;
    this.endAmount = this.value;

    this.currentAmount = this.value;
    this.currentStartAmount = this.value;
    this.currentDuration = this.duration * 1000;
    this.remaining = this.duration * 1000;

    this.start();
  },
  unmounted(): void {
    this.cancelAnimation();
  },
  methods: {
    start(): void {
      this.cancelAnimation();
      this.currentStartAmount = this.startAmount;
      this.startTimestamp = null;
      this.currentDuration = this.duration * 1000;
      this.animationFrame = window.requestAnimationFrame(this.counting);
    },
    reset(): void {
      this.startTimestamp = null;
      this.cancelAnimation();
      this.currentAmount = this.startAmount;

      this.start();
    },
    counting(timestamp: number): void {
      this.timestamp = timestamp;
      if (!this.startTimestamp) this.startTimestamp = timestamp;
      let progress: number = timestamp - this.startTimestamp;
      this.remaining = this.currentDuration - progress;

      if (!this.isCountingUp) {
        this.currentAmount =
          this.currentStartAmount -
          (this.currentStartAmount - this.endAmount) *
            (progress / this.currentDuration);
        this.currentAmount =
          this.currentAmount < this.endAmount
            ? this.endAmount
            : this.currentAmount;
      } else {
        this.currentAmount =
          this.currentStartAmount +
          (this.endAmount - this.currentStartAmount) *
            (progress / this.currentDuration);
        this.currentAmount =
          this.currentAmount > this.endAmount
            ? this.endAmount
            : this.currentAmount;
      }

      if (progress < this.currentDuration)
        this.animationFrame = window.requestAnimationFrame(this.counting);
    },
    cancelAnimation(): void {
      if (this.animationFrame) window.cancelAnimationFrame(this.animationFrame);
    },
  },
});
</script>
