import Cookies from "js-cookie";
import { defineStore } from "pinia";
import { ref } from "vue";

import axios from "@/lib/axios";
import type { User } from "@/types/user";
import posthog from "posthog-js";

export const useAuthStore = defineStore("auth", () => {
  const user = ref<User | undefined>();

  const loadUser = async () => {
    // User already loaded
    if (user.value) return user.value;

    // Are we logged in
    if (Cookies.get("loggedIn")) {
      try {
        const request = await axios.get("/user");
        user.value = request.data;

        posthog.identify(user.value!.id.toString(), user.value);
      } catch (e) {
        user.value = undefined;
        Cookies.remove("loggedIn");
      }
    }

    return user.value;
  };

  const logout = async () => {
    try {
      await axios.post("/auth/logout");
    } catch (e) {
      Cookies.remove("loggedIn");
    }
  };

  return {
    user,
    loadUser,
    logout,
  };
});
