<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useNotificationsStore } from "@/stores/notifications";

const notificationsStore = useNotificationsStore();
const { notifications } = storeToRefs(notificationsStore);
</script>

<template>
  <div
    class="fixed top-0 z-20 flex w-full flex-col items-end px-4 py-4 sm:px-8"
    :class="{ hidden: notifications.length === 0 }"
  >
    <TransitionGroup name="slide-left">
      <div
        v-for="notification in notifications"
        :key="notification.id"
        class="mb-4 w-[420px] max-w-full rounded border-t-4 bg-white px-4 py-4 shadow-xl"
        :class="{
          'border-t-green-400': notification.status === 'success',
          'border-t-red-400': notification.status === 'error',
        }"
      >
        <div class="flex items-center">
          <div class="font-semibold text-marine-700">
            {{ notification.text }}
          </div>
          <div class="flex-1" />
          <button
            class="inline-flex h-6 w-6 min-w-6 shrink-0 items-center justify-center text-marine-500 transition hover:text-marine-900"
            @click.prevent="
              notificationsStore.dismissNotification(notification.id)
            "
            @mouseup.prevent=""
          >
            <i class="mdi mdi-close text-lg" />
          </button>
        </div>

        <div v-if="notification.subtext" class="mt-1 text-sm text-marine-600">
          {{ notification.subtext }}
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>
