<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

import Logo from "@/components/Logo.vue";
import Check from "@/components/Check.vue";

const { t: $t } = useI18n();

type Step = {
  name: string;
  active?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  duration?: number;
  subSteps?: Step[];
};

const props = defineProps<{
  currentStep: string;
  currentSubStep?: string;
}>();

const steps = computed<Step[]>(() => {
  const investSteps: Step[] = [
    {
      name: "project",
      active: props.currentStep === "project",
      disabled: ["kyc", "invest"].includes(props.currentStep),
      clickable: props.currentStep === "kyc",
      duration: 1,
    },
    {
      name: "kyc",
      active: props.currentStep === "kyc",
      disabled: ["invest"].includes(props.currentStep),
      duration: 5,
    },
    {
      name: "invest",
      active: props.currentStep === "invest",
      disabled: props.currentStep === "unlocked",
      duration: 5,
      subSteps: [
        {
          name: "terms",
          active: props.currentSubStep === "terms",
          disabled: ["deposit", "closing"].includes(props.currentStep),
        },
        {
          name: "signature",
          active: props.currentSubStep === "signature",
          disabled: ["closing"].includes(props.currentStep),
        },
        {
          name: "deposit",
          active: props.currentSubStep === "deposit",
        },
      ],
    },
  ];

  return [
    {
      name: "accountCreation",
      disabled: !["sign-up", "profile"].includes(props.currentStep),
      active: ["profile", "sign-up"].includes(props.currentStep),
      duration: 1,
    },
    ...investSteps,
    {
      name: "unlocked",
    },
  ];
});
</script>

<template>
  <div
    class="onboarding-sidebar h-svh relative hidden max-w-[360px] flex-1 overflow-hidden rounded-2xl px-12 py-16 shadow lg:block"
  >
    <Logo class="relative ml-4" />

    <div class="relative mt-16 flex flex-col gap-4">
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="flex w-full flex-col gap-2"
      >
        <div
          class="flex w-full flex-row items-center gap-2 rounded-xl px-3 py-2"
          :class="{
            'cursor-pointer': step.clickable,
            'opacity-80': step.disabled,
            'border border-b-[0.5px] border-l-[1px] border-r-[0.5px] border-t-[1px] border-[rgba(255,255,255,0.6)] border-b-[rgba(255,255,255,0.4)] border-r-[rgba(255,255,255,0.4)] bg-white bg-opacity-25 ':
              step.active,
          }"
        >
          <div
            class="top-0.5 z-10 flex h-5 w-5 items-center justify-center rounded-xl bg-marine-800"
            :class="{
              'group-hover/clickable:border-marine-700': step.clickable,
            }"
          >
            <Check
              v-if="step.disabled"
              class="h-5 w-5"
              background-color="transparent"
            />
            <div v-else class="text-xs font-bold text-white">
              {{ index + 1 }}
            </div>
          </div>

          <div
            class="text-lg font-bold text-marine-700"
            :class="{
              'group-hover/clickable:text-marine-700': step.clickable,
            }"
          >
            {{ $t(`onboarding.steps.${step.name}.title`) }}
          </div>

          <div
            v-if="step.duration"
            class="text-xs font-semibold uppercase text-marine-500"
          >
            {{ step.duration }} min
          </div>
        </div>
        <div
          v-if="step.subSteps?.length && step.active"
          class="ml-3 flex flex-col gap-3 font-semibold"
        >
          <div
            v-for="subStep in step.subSteps"
            :key="subStep.name"
            class="flex flex-row items-center gap-2 align-middle font-semibold"
            :class="{
              ' text-marine-800': subStep.active,
              'text-marine-500 opacity-40': !subStep.active,
            }"
          >
            <div class="h-5 w-5">
              <svg
                v-if="subStep.active"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="19"
                viewBox="0 0 12 13"
                fill="none"
              >
                <path
                  d="M11.5303 7.03033C11.8232 6.73744 11.8232 6.26256 11.5303 5.96967L6.75736 1.1967C6.46446 0.903805 5.98959 0.903805 5.6967 1.1967C5.4038 1.48959 5.4038 1.96447 5.6967 2.25736L9.93934 6.5L5.6967 10.7426C5.4038 11.0355 5.4038 11.5104 5.6967 11.8033C5.98959 12.0962 6.46446 12.0962 6.75736 11.8033L11.5303 7.03033ZM6.55671e-08 7.25L11 7.25L11 5.75L-6.55671e-08 5.75L6.55671e-08 7.25Z"
                  fill="#1E3064"
                />
              </svg>
            </div>

            <div>
              {{ $t(`onboarding.steps.invest.${subStep.name}`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.onboarding-sidebar {
  background: linear-gradient(390deg, #D8D9F1, #FAEAF4, #FFE6D2);
}

@media (max-width: 1800px) {
  .onboarding-sidebar::before {
    top: -70%;
    left: -20%;
  }
}

@media (min-width: 2560px) {
  .onboarding-sidebar::before {
    top: -30%;
    left: 0;
    transform: rotate(-123deg) scale(1.5);
  }
}

@media (min-width: 3840px) {
  .onboarding-sidebar::before {
    top: -10%;
    left: 10%;
    transform: rotate(-123deg) scale(2);
  }
}
</style>
