<template>
  <svg
    width="517"
    height="125"
    viewBox="0 0 517 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="114.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M110.517 49V46.872H113.429V40.616C113.061 41.16 112.261 41.576 111.541 41.576H111.365V39.24H111.445C112.981 39.24 113.509 38.152 113.509 37.32H115.653V46.872H118.485V49H110.517Z"
      fill="#364775"
    />
    <circle cx="258.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M259.173 49V46.632H254.437V44.6L258.901 37.32H261.333V44.6H262.565V46.632H261.333V49H259.173ZM256.741 44.6H259.173V40.648L256.741 44.6Z"
      fill="#364775"
    />
    <circle cx="162.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M158.517 49V46.904C158.517 44.904 160.309 43.704 161.893 42.728C163.141 41.944 164.293 41.304 164.293 40.424C164.293 39.688 163.669 39.08 162.501 39.08C161.365 39.08 160.629 39.752 160.181 40.728L158.261 39.768C159.029 38.168 160.453 37.16 162.485 37.16C165.045 37.16 166.549 38.6 166.549 40.44C166.549 42.296 164.885 43.32 163.349 44.248C162.021 45.064 160.757 45.784 160.741 46.872H166.485V49H158.517Z"
      fill="#364775"
    />
    <circle cx="306.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M306.485 49.16C304.277 49.16 302.869 48.184 302.197 46.888L304.357 45.64C304.677 46.52 305.445 47.112 306.517 47.112C307.541 47.112 308.485 46.264 308.485 45.064C308.485 43.896 307.717 42.952 306.453 42.952C305.445 42.952 304.837 43.432 304.389 44.104L302.405 43.08L303.237 37.32H310.229V39.4H304.981L304.677 41.464C305.157 41.176 305.845 40.92 306.789 40.92C308.917 40.92 310.725 42.28 310.725 45.08C310.725 47.4 308.949 49.16 306.485 49.16Z"
      fill="#364775"
    />
    <path
      d="M88.5 97L93.5 99.8868L93.5 94.1132L88.5 97ZM428.5 97L423.5 94.1132L423.5 99.8867L428.5 97ZM93 97.5L424 97.5L424 96.5L93 96.5L93 97.5Z"
      fill="#B4BACC"
    />
    <path d="M138.5 97V69" stroke="#B4BACC" />
    <path d="M234.5 97V69" stroke="#B4BACC" />
    <path d="M330.5 97V69" stroke="#B4BACC" />
    <circle cx="210.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M210.581 49.16C208.549 49.16 207.269 48.504 206.149 47L208.085 45.608C208.709 46.68 209.541 47.128 210.597 47.128C211.685 47.128 212.501 46.536 212.501 45.528C212.501 44.664 211.957 44.072 210.757 44.072H209.221V42.024H210.725C211.973 42.024 212.437 41.272 212.437 40.456C212.437 39.784 211.813 39.096 210.629 39.096C209.349 39.096 208.773 39.544 208.277 40.536L206.341 39.576C207.189 37.944 208.645 37.16 210.613 37.16C212.917 37.16 214.597 38.472 214.597 40.36C214.597 41.512 214.101 42.424 213.237 43C214.165 43.512 214.725 44.36 214.725 45.592C214.725 47.592 213.189 49.16 210.581 49.16Z"
      fill="#364775"
    />
    <circle cx="354.5" cy="44" r="16" fill="#364775" />
    <path
      d="M354.517 50.16C352.053 50.16 350.277 48.368 350.277 46.112C350.277 45.008 350.629 43.984 351.173 43.168L354.165 38.32H356.789L354.325 42.144C354.533 42.08 354.741 42.048 354.965 42.048C357.045 42.048 358.725 43.712 358.725 46.096C358.725 48.48 356.917 50.16 354.517 50.16ZM354.469 48.144C355.589 48.144 356.533 47.2 356.533 46.048C356.533 44.928 355.589 43.968 354.469 43.968C353.349 43.968 352.453 44.928 352.453 46.064C352.453 47.2 353.349 48.144 354.469 48.144Z"
      fill="white"
    />
    <path d="M354.5 24L349.304 18L359.696 18L354.5 24Z" fill="#364775" />
    <path
      d="M339.522 12V1.78H345.584V3.642H341.468V5.938H345.584V7.8H341.468V10.138H345.584V12H339.522ZM350.272 12.14C348.144 12.14 346.59 10.894 346.59 8.458V1.78H348.536V8.584C348.536 9.564 349.278 10.334 350.258 10.334C351.238 10.334 352.022 9.564 352.022 8.584V1.78H353.968V8.458C353.968 10.894 352.4 12.14 350.272 12.14ZM354.101 12L357.727 1.78H359.687L363.313 12H361.283L360.667 10.222H356.747L356.131 12H354.101ZM358.707 4.552L357.391 8.36H360.023L358.707 4.552ZM366.793 12.14C365.099 12.14 364.077 11.482 363.573 10.25L365.407 9.536C365.659 10.18 366.191 10.488 366.877 10.488C367.507 10.488 367.843 10.194 367.843 9.858C367.843 9.48 367.157 9.368 366.345 9.214C365.085 8.99 363.825 8.556 363.825 7.058C363.825 5.882 364.959 4.832 366.695 4.86C368.263 4.86 369.159 5.504 369.663 6.484L367.969 7.198C367.787 6.736 367.311 6.428 366.681 6.428C366.051 6.428 365.771 6.694 365.771 7.002C365.771 7.31 366.177 7.422 367.227 7.632C368.403 7.87 369.761 8.29 369.761 9.802C369.761 10.824 368.809 12.168 366.793 12.14Z"
      fill="#364775"
    />
    <circle cx="402.5" cy="43" r="16" fill="#E6E8EE" />
    <path
      d="M399.525 49L404.149 39.448H398.517V37.32H406.485V39.256L401.893 49H399.525Z"
      fill="#364775"
    />

    <text x="102" y="86" fill="#B4BACC" font-size="12.5">
      {{ $t("onboarding.srri.scale.safe") }}
    </text>
    <text x="165" y="86" fill="#B4BACC" font-size="12.5">
      {{ $t("onboarding.srri.scale.careful") }}
    </text>
    <text x="256" y="86" fill="#B4BACC" font-size="12.5">
      {{ $t("onboarding.srri.scale.balanced") }}
    </text>
    <text x="353" y="86" fill="#B4BACC" font-size="12.5">
      {{ $t("onboarding.srri.scale.dynamic") }}
    </text>
    <text x="90" y="117" fill="#9BA3BA" font-size="12">
      {{ $t("onboarding.srri.risk.low") }}
    </text>
    <text x="380" y="117" fill="#9BA3BA" font-size="12">
      {{ $t("onboarding.srri.risk.high") }}
    </text>
  </svg>
</template>
