<script setup lang="ts">
import { ref } from "vue";
import { useClickOutside } from "@/hooks/use-click-outside";
import { cn } from "@/helpers/tailwind-helpers";

const props = withDefaults(
    defineProps<{
      isOpen: boolean,
      modalClasses?: string,
    }>(),
    {
      isOpen: false,
      modalClasses: '',
    },
);

const modal = ref();
const emit = defineEmits(["modal-close"]);

const defaultModalClasses = "z-1 relative w-[90svw] rounded-lg bg-white md:mx-16 md:w-full overflow-hidden"

useClickOutside(modal, () => props.isOpen && emit("modal-close"));
</script>

<template>
  <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
  >
    <div
        v-if="props.isOpen"
        class="z-2 fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50"
    >
      <transition
          enter-active-class="transition-transform duration-800 ease-out"
          enter-from-class="transform scale-50 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition-transform duration-800 ease-in"
          leave-from-class="transform scale-50 opacity-100"
          leave-to-class="transform scale-90 opacity-0"
      >
        <div
            v-if="props.isOpen"
            ref="modal"
            class=""
            :class="cn(defaultModalClasses, modalClasses)"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
