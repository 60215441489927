<script setup lang="ts">
import dayjs from "dayjs";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";

import LineChart from "@/components/charts/LineChart.vue";
import { Timespan } from "@/types/charts";

import { getCurrencyFormatter, getPercentageFormatter } from "@/utils/numbers";

import { usePricesStore } from "@/stores/prices";

const pricesStore = usePricesStore();

const { pricesValues, timespan } = storeToRefs(pricesStore);

pricesStore.fetchPrices();

const lineChart = ref<InstanceType<typeof LineChart> | null>(null);

const mousePositionValue = computed(() => {
  return lineChart.value?.mousePositionValue;
});

const diffFromStart = computed(() => {
  if (!pricesValues.value) return 0;

  return (
    (mousePositionValue?.value
      ? mousePositionValue.value!.value
      : pricesValues.value.at(-1)!.value) - pricesValues.value.at(0)!.value
  );
});

const diffFromStartPercentage = computed(() => {
  if (!pricesValues.value) return 0;
  return diffFromStart.value / pricesValues.value.at(0)!.value;
});
</script>

<template>
  <div class="w-full rounded border bg-white p-4 shadow-sm sm:p-6">
    <div
      class="flex items-center font-display text-lg font-semibold text-marine-700"
    >
      {{ $t("portfolio.historical") }}
    </div>
    <div class="mt-6 flex flex-col gap-3 md:flex-row md:items-center">
      <div v-if="pricesValues !== undefined">
        <span class="text-3xl font-bold text-marine-800">
          <template v-if="!mousePositionValue">
            {{ getCurrencyFormatter("EUR").format(pricesValues.at(-1)!.value) }}
          </template>
          <template v-else>
            {{ getCurrencyFormatter("EUR").format(mousePositionValue?.value) }}
          </template>
        </span>
        <span
          v-if="diffFromStart !== 0"
          class="ml-2 text-xs font-bold text-green-400"
          :class="diffFromStart > 0 ? 'text-green-400' : 'text-red-400'"
        >
          <template v-if="diffFromStart > 0">
            +{{ getCurrencyFormatter("EUR").format(diffFromStart) }} (+{{
              getPercentageFormatter().format(diffFromStartPercentage)
            }})
          </template>
          <template v-else>
            {{ getCurrencyFormatter("EUR").format(diffFromStart) }} ({{
              getPercentageFormatter().format(diffFromStartPercentage)
            }})
          </template>
        </span>
      </div>
      <div v-else class="h-10 w-56 rounded bg-gray-100"></div>

      <div class="flex-1" />

      <div
        class="flex items-center overflow-x-hidden rounded-lg border border-gray-300 font-display font-semibold shadow-sm"
      >
        <button
          v-for="(availableTimespan, index) in pricesStore.availableTimespans"
          :key="availableTimespan.value"
          type="button"
          class="inline-flex h-6 flex-grow items-center justify-center whitespace-nowrap border-gray-300 px-5 text-xs leading-normal transition"
          :class="[
            timespan === availableTimespan.value
              ? 'bg-marine-800 text-white'
              : 'bg-transparent text-marine-700 hover:bg-marine-25 hover:text-marine-900',
            index === 0 ? '' : 'border-l',
          ]"
          @click="
            timespan = availableTimespan.value;
            pricesStore.fetchPrices();
          "
        >
          <span class="hidden lg:inline">
            {{ availableTimespan.text }}
          </span>
          <span class="lg:hidden">
            {{ availableTimespan.smallText }}
          </span>
        </button>
      </div>
    </div>

    <LineChart
      ref="lineChart"
      class="mt-4"
      :values="pricesValues"
      :legend-formatter="
        (date: dayjs.Dayjs) =>
          Timespan.MONTH == timespan ? date.format('MMM D') : date.format('MMM')
      "
    >
      <template #tooltip="{ value }">
        <div class="whitespace-nowrap text-sm font-medium text-marine-400">
          {{ value.date.format("DD MMM YYYY") }}
          <template v-if="Timespan.MONTH === timespan">
            {{ value.date.format("HH:mm") }}
          </template>
        </div>
      </template>
    </LineChart>
  </div>
</template>
