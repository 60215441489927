import axios from "@/lib/axios";
import i18n from "@/lib/i18n";
import { Timespan } from "@/types/charts";
import type { MarketPrice, ProductSummary } from "@/types/products";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

const { t } = i18n.global as any;

export const usePricesStore = defineStore("prices", () => {
  const timespan = ref<Timespan>(Timespan.FIVE_YEARS);
  const prices = ref<MarketPrice[]>();
  const summary = ref<ProductSummary>();

  const fetchPrices = async () => {
    try {
      const request = await axios.get("/products/eua/price/history", {
        params: { timespan: timespan.value },
        cancelPreviousRequests: true,
      });
      prices.value = request.data;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchSummary = async () => {
    try {
      const request = await axios.get("/products/eua/summary", {
        cancelPreviousRequests: true,
      });
      summary.value = request.data;
    } catch (e) {
      console.error(e);
    }
  };

  const pricesValues = computed(() => {
    if (!prices.value) return;
    return prices.value.map((p) => ({
      timestamp: p.timestamp,
      value: p.price,
    }));
  });

  const availableTimespans = [
    {
      value: Timespan.MONTH,
      text: t("portfolio.timespans.month.text", 1),
      smallText: t("portfolio.timespans.month.smallText", 1),
    },
    {
      value: Timespan.YTD,
      text: t("portfolio.timespans.ytd.text"),
      smallText: t("portfolio.timespans.ytd.smallText"),
    },
    {
      value: Timespan.YEAR,
      text: t("portfolio.timespans.year.text", 1),
      smallText: t("portfolio.timespans.year.smallText", 1),
    },
    {
      value: Timespan.TWO_YEARS,
      text: t("portfolio.timespans.year.text", 2),
      smallText: t("portfolio.timespans.year.smallText", 2),
    },
    {
      value: Timespan.FIVE_YEARS,
      text: t("portfolio.timespans.year.text", 5),
      smallText: t("portfolio.timespans.year.smallText", 5),
    },
  ];

  return {
    pricesValues,
    fetchPrices,
    fetchSummary,
    summary,
    timespan,
    availableTimespans,
  };
});
