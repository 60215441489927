import Holidays from "date-holidays";
import { type DateTimeOptions } from "vue-i18n";
export function isHoliday(date: Date): boolean {
  const holidayService = new Holidays("FR");

  return holidayService.isHoliday(date) !== false;
}

export function isLocale24HoursFormat(locale: string): boolean {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    hour: "numeric",
  });

  const formattedTime = dateTimeFormat.formatToParts(
    new Date(Date.UTC(2020, 0, 1, 15)),
  );

  return !formattedTime.some((part) => part.type === "dayPeriod");
}

export function getShortDate(locale: string, date: Date) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  } as DateTimeOptions;

  return new Intl.DateTimeFormat(locale, options).format(date);
}

export function isToday(date: Date) {
  const today = new Date();

  return date.toDateString() === today.toDateString();
}

export function getNextClosingDate(
  locale: string = "en-US",
  translate: Function,
): {
  processing: string;
  closing: string;
} {
  const maxTransactionHoursForToday = 14;
  const closingHours = 19;
  const processingHours = 20;
  const canBeProcessedToday =
    isHoursBeforeInFrance(maxTransactionHoursForToday) &&
    !isHoliday(new Date());
  const today = new Date();

  if (canBeProcessedToday) {
    return {
      closing: formatSellingData(today, closingHours, locale, translate),
      processing: formatSellingData(today, processingHours, locale, translate),
    };
  } else {
    return {
      closing: formatSellingData(
        findNextWorkingDay(today),
        closingHours,
        locale,
        translate,
      ),
      processing: formatSellingData(
        findNextWorkingDay(today),
        processingHours,
        locale,
        translate,
      ),
    };
  }
}

function findNextWorkingDay(date: Date): Date {
  const nextDate = new Date(date);

  do {
    nextDate.setDate(nextDate.getDate() + 1);
  } while (isWeekend(nextDate) || isHoliday(nextDate));

  return nextDate;
}

function isWeekend(date: Date): boolean {
  const day = date.getDay();
  const sundayIndex = 0;
  const saturdayIndex = 6;

  return day === sundayIndex || day === saturdayIndex;
}

function isHoursBeforeInFrance(hours: number) {
  const now = new Date();
  const options: DateTimeOptions = {
    timeZone: "Europe/Paris",
    hour: "2-digit",
    hour12: false,
  };
  const currentHourInFrance = new Intl.DateTimeFormat("en-US", options).format(
    now,
  );
  return parseInt(currentHourInFrance, 10) < hours;
}

function get12hoursFormatSuffix(hours: number) {
  return hours > 12 ? "pm" : "am";
}

function convert24hoursTo12hours(hours: number) {
  return hours > 12 ? hours - 12 : hours;
}

function formatSellingData(
  date: Date,
  hours: number,
  locale: string = "en-US",
  translate: Function,
) {
  const dayOptions: DateTimeOptions = {
    weekday: "long",
    timeZone: "Europe/Paris",
  };

  const dayFormatter = new Intl.DateTimeFormat(locale, dayOptions);

  const dayName = dayFormatter.format(date);
  const use24h = isLocale24HoursFormat(locale);
  const suffix = use24h ? "" : get12hoursFormatSuffix(hours);
  const formatedHours = use24h ? hours : convert24hoursTo12hours(hours);

  const dayPart = isToday(date) ? translate("global.today") : dayName;
  return `${dayPart}, ${formatedHours}:00 ${suffix}`;
}
