<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="116"
    height="24"
    viewBox="0 0 116 24"
    fill="none"
  >
    <path
      d="M9.2393 7.16443C11.523 7.16443 13.4659 7.97035 15.0718 9.58027C16.695 11.1921 17.4979 13.1596 17.4979 15.4517V23.9903H13.5564V15.4517C13.5564 14.2342 13.1462 13.2137 12.2894 12.3904C11.4517 11.5303 10.4351 11.1013 9.2393 11.1013C8.04354 11.1013 7.02878 11.5303 6.17192 12.3904C5.33431 13.2137 4.90491 14.2342 4.90491 15.4517V23.9903H0.857422V0H4.799V8.45352C6.15459 7.59348 7.63533 7.16443 9.2393 7.16443Z"
      fill="currentColor"
    />
    <path
      d="M22.1159 21.5202C20.4753 19.8736 19.6377 17.8868 19.6377 15.5772C19.6377 13.2677 20.4753 11.2809 22.1159 9.65166C23.7564 7.98763 25.7186 7.16431 28.0196 7.16431C30.3206 7.16431 32.3001 7.98763 33.9406 9.65166C35.5812 11.2809 36.4015 13.2503 36.4015 15.5772C36.4015 17.9042 35.5812 19.8736 33.9406 21.5202C32.3001 23.1669 30.3379 23.9902 28.0196 23.9902C25.7012 23.9902 23.7564 23.1669 22.1159 21.5202ZM24.9156 12.4618C24.078 13.3218 23.6486 14.3597 23.6486 15.5772C23.6486 16.7948 24.0761 17.8153 24.9156 18.6927C25.7898 19.5528 26.8238 19.9818 28.0196 19.9818C29.2153 19.9818 30.2667 19.5528 31.1235 18.6927C31.9804 17.8153 32.4079 16.7774 32.4079 15.5772C32.4079 14.3771 31.9804 13.3218 31.1235 12.4618C30.2667 11.6017 29.2327 11.1727 28.0196 11.1727C26.8065 11.1727 25.7898 11.6017 24.9156 12.4618Z"
      fill="currentColor"
    />
    <path
      d="M55.4473 7.16431C57.3728 7.16431 58.9961 7.84461 60.3343 9.20521C61.6899 10.5658 62.3677 12.1951 62.3677 14.1277V23.9902H58.4627V14.1277C58.4627 13.287 58.1777 12.57 57.5712 11.9805C57.0012 11.3891 56.2868 11.1031 55.4492 11.1031C54.6116 11.1031 53.9146 11.3891 53.3273 11.9805C52.7381 12.5719 52.4357 13.287 52.4357 14.1277V23.9902H48.4768V14.1277C48.4768 13.287 48.1919 12.57 47.6026 11.9805C47.0134 11.3891 46.301 11.1031 45.4634 11.1031C44.6258 11.1031 43.948 11.3891 43.3414 11.9805C42.7715 12.5719 42.4672 13.287 42.4672 14.1277V23.9902H38.543V14.1277C38.543 12.1951 39.2208 10.5658 40.5763 9.20521C41.9319 7.84461 43.5725 7.16431 45.4634 7.16431C47.3542 7.16431 49.1007 7.862 50.4563 9.29412C51.8292 7.862 53.489 7.16431 55.4492 7.16431H55.4473Z"
      fill="currentColor"
    />
    <path
      d="M77.3444 22.7381C76.0254 23.5614 74.5447 23.9904 72.8868 23.9904C70.5857 23.9904 68.6236 23.1671 66.9831 21.5205C65.3425 19.8738 64.5049 17.887 64.5049 15.5775C64.5049 13.2679 65.3425 11.2811 66.9831 9.6519C68.6236 7.98787 70.5857 7.16455 72.8868 7.16455C75.1878 7.16455 77.1846 7.98787 78.8251 9.6519C80.4657 11.2811 81.286 13.2505 81.286 15.5775V23.9904H77.3444V22.7381ZM69.7828 12.4639C68.9452 13.324 68.5158 14.3618 68.5158 15.5794C68.5158 16.797 68.9433 17.8175 69.7828 18.6949C70.657 19.5549 71.691 19.984 72.8868 19.984C74.0825 19.984 75.1512 19.5549 76.0081 18.6949C76.8649 17.8175 77.2924 16.7796 77.2924 15.5794C77.2924 14.3792 76.8649 13.324 76.0081 12.4639C75.1512 11.6039 74.0999 11.1749 72.8868 11.1749C71.6737 11.1749 70.657 11.6039 69.7828 12.4639Z"
      fill="currentColor"
    />
    <path
      d="M88.3583 10.1754C87.7075 10.8538 86.8795 11.2056 85.9283 11.2056C84.9771 11.2056 84.1491 10.8538 83.4482 10.1754C82.7723 9.47194 82.4219 8.64089 82.4219 7.68615C82.4219 6.73141 82.7723 5.87523 83.4482 5.19686C84.1491 4.49337 84.9771 4.16675 85.9283 4.16675C86.8795 4.16675 87.7075 4.49337 88.3583 5.19686C89.0592 5.87523 89.3846 6.70628 89.3846 7.68615C89.3846 8.66602 89.0592 9.47194 88.3583 10.1754Z"
      fill="currentColor"
    />
    <path
      d="M114.116 22.9699C113.465 23.6482 112.637 24 111.686 24C110.735 24 109.907 23.6482 109.206 22.9699C108.53 22.2664 108.18 21.4353 108.18 20.4806C108.18 19.5258 108.53 18.6697 109.206 17.9913C109.907 17.2878 110.735 16.9612 111.686 16.9612C112.637 16.9612 113.465 17.2878 114.116 17.9913C114.817 18.6697 115.142 19.5007 115.142 20.4806C115.142 21.4605 114.817 22.2664 114.116 22.9699Z"
      fill="currentColor"
    />
    <path
      d="M87.884 13.519H83.9424V23.9903H87.884V13.519Z"
      fill="currentColor"
    />
    <path
      d="M92.5622 21.5202C90.9216 19.8736 90.084 17.8868 90.084 15.5772C90.084 13.2677 90.9216 11.2809 92.5622 9.65166C94.2027 7.98763 96.1648 7.16431 98.4659 7.16431C100.767 7.16431 102.746 7.98763 104.387 9.65166C106.027 11.2809 106.848 13.2503 106.848 15.5772C106.848 17.9042 106.027 19.8736 104.387 21.5202C102.746 23.1669 100.784 23.9902 98.4659 23.9902C96.1475 23.9902 94.2027 23.1669 92.5622 21.5202ZM95.3619 12.4618C94.5243 13.3218 94.0949 14.3597 94.0949 15.5772C94.0949 16.7948 94.5224 17.8153 95.3619 18.6927C96.2361 19.5528 97.2701 19.9818 98.4659 19.9818C99.6616 19.9818 100.713 19.5528 101.57 18.6927C102.427 17.8153 102.854 16.7774 102.854 15.5772C102.854 14.3771 102.427 13.3218 101.57 12.4618C100.713 11.6017 99.679 11.1727 98.4659 11.1727C97.2528 11.1727 96.2361 11.6017 95.3619 12.4618Z"
      fill="currentColor"
    />
  </svg>
</template>
