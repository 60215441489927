import axios from "@/lib/axios";
import dayjs from "dayjs";
import { defineStore } from "pinia";

import { ref } from "vue";

export const useBatchesStore = defineStore("batches", () => {
  const nextBatchEnd = ref<dayjs.Dayjs>(dayjs());
  const currentBatch = ref();

  const getActiveBatch = async () => {
    try {
      const request = await axios.get("/batches/active");
      currentBatch.value = request.data;
      nextBatchEnd.value = dayjs(request.data.closingDate);
    } catch (e) {
      console.error(e);
    }
  };

  return {
    getActiveBatch,
    nextBatchEnd,
    currentBatch,
  };
});
