<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    disabledColor?: string;
    backgroundColor?: string;
    textColor?: string;
  }>(),
  {
    disabled: false,
    disabledColor: "#F2F3F6",
    backgroundColor: "#4ADE80",
    textColor: "white",
  },
);
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10Z"
      :fill="props.disabled ? props.disabledColor : props.backgroundColor"
    />
    <path
      v-if="!props.disabled"
      d="M2.875 5.00002L4.29 6.41502L7.125 3.58502"
      :fill="backgroundColor"
    />
    <path
      v-if="!props.disabled"
      d="M2.875 5.00002L4.29 6.41502L7.125 3.58502"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
