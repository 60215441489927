<script setup lang="ts">
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import axios from "@/lib/axios";

import Logo from "@/components/Logo.vue";

const route = useRoute();

const error = ref(false);

const verify = async () => {
  if (!jwtData.value) return;

  try {
    await axios.post("/auth/verify", {
      token: route.query.token as string,
    });
  } catch (e) {
    error.value = true;
  }
};

const jwtData = computed(() => {
  try {
    const token = route.query.token as string;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return undefined;
  }
});

verify();
</script>

<template>
  <div class="h-full w-full p-8 flex flex-col items-center">
    <a href="https://homaio.com">
      <Logo />
    </a>

    <div class="flex-1 flex justify-center items-center w-full">
      <div class="w-full max-w-lg text-marine-800">
        <div class="text-5xl font-bold font-display">Email confirmation ✅</div>
        <div v-if="jwtData" class="font-medium mt-3 font-display">
          Your email is now confirmed.
        </div>

        <div
          v-if="!jwtData"
          class="rounded w-full px-6 py-4 font-medium bg-red-400 text-white mt-8 shadow"
        >
          It seems that the link you used is expired or has already been used.
        </div>
        <div
          v-else-if="error"
          class="rounded w-full px-6 py-4 font-medium bg-red-400 text-white mt-4 shadow"
        >
          It seems that you email is already verified.
        </div>

        <div v-if="jwtData" class="mt-8">
          <RouterLink to="/" class="text-indigo-400 font-bold">
            Go to platform
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>
