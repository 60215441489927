<template>
  <div class="w-100 flex flex-col rounded-3xl bg-white shadow-lg lg:h-full">
    <div class="flex-shrink-0">
      <h2 class="mx-6 mt-4 font-semibold">
        {{ $t("sell.modal.transactionPreview.title") }}
      </h2>
      <hr class="my-4 border-t border-marine-25" />
    </div>
    <div class="no-scrollbar flex-grow overflow-y-auto px-6 pb-4">
      <div class="mt-10 text-sm text-marine-300">
        <div class="flex items-center gap-2">
          <p class="italic">
            {{ $t("sell.modal.transactionPreview.exitFees.title") }}
            <span class="inline-block translate-y-[18%]">
              <TTooltip
                :text="$t('sell.modal.transactionPreview.exitFees.tooltip')"
                placement="bottom"
              >
                <TIcon icon="infoFull" size="xs" />
              </TTooltip>
            </span>
          </p>
        </div>
        <div class="flex items-center gap-2">
          <p class="italic">
            {{
              $t("sell.modal.transactionPreview.custodyFees.title", {
                fee: transaction.fee,
              })
            }}
            <span class="inline-block translate-y-[18%]">
              <TTooltip
                placement="bottom"
                :text="$t('sell.modal.transactionPreview.custodyFees.tooltip')"
              >
                <TIcon icon="infoFull" size="xs" />
              </TTooltip>
            </span>
          </p>
        </div>
      </div>
      <div class="mt-10">
        <h2 class="font-semibold">
          {{ $t("sell.modal.transactionPreview.estimatedValue") }}
        </h2>
        <p class="mt-4 text-4xl font-semibold">
          {{ transaction.estimatedValue }} €
        </p>
      </div>
      <TCallout icon="warningTriangleOutline">
        <p>
          {{ $t("sell.modal.transactionPreview.estimatedValueCallout") }}
        </p>
      </TCallout>
      <div class="mt-10">
        <h2 class="font-semibold">
          {{ $t("sell.modal.transactionPreview.sellProcessDetails") }}
        </h2>
        <div class="mt-4 px-2">
          <VerticalStepper :steps="steps" />
        </div>
      </div>
    </div>
    <div class="w-full flex-shrink-0 rounded-3xl bg-white px-6 pb-2">
      <TButton
        class="w-full"
        :disabled="isButtonDisabled"
        @click="continueToNextStep"
        >{{ buttonText }}</TButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import VerticalStepper from "@/components/VerticalStepper.vue";
import { computed, ref } from "vue";
import TButton from "@/components/designSystem/TButton.vue";
import TCallout from "@/components/designSystem/TCallout.vue";
import SellTransaction from "@/models/sell-transaction";
import { getNextClosingDate } from "@/helpers/date-helpers";
import { useI18n } from "vue-i18n";
import { SellModalStep } from "@/constants/sell-modal-constants";

const { locale, t: $t } = useI18n();

const transactionDates = computed(() => {
  return getNextClosingDate(locale.value, $t);
});

const transaction = defineModel<SellTransaction>({ required: true });

const emit = defineEmits(["next-step"]);

const continueToNextStep = () => {
  transaction.value.transactionLocked = true;
  const step = transaction.value.transactionLocked
    ? SellModalStep.overview
    : SellModalStep.transaction;
  emit("next-step", step);
};

const hasNoBondToSell = computed(() => {
  return !transaction.value.bondToSell || transaction.value.bondToSell === 0;
});

const isButtonDisabled = computed(() => {
  return (
    hasNoBondToSell.value ||
    transaction.value.transactionLocked ||
    transaction.value.hasError
  );
});

const buttonText = computed(() => {
  return transaction.value.transactionLocked
    ? $t("sell.modal.transactionPreview.pleaseVerifyIban")
    : $t("sell.modal.transactionPreview.continue");
});

const steps = ref([
  {
    key: "order",
    label: $t("sell.modal.transactionPreview.steps.orderConfirmed"),
  },
  {
    key: "closing",
    label: $t("sell.modal.transactionPreview.steps.settlement"),
    suffix: transactionDates.value.closing,
  },
  {
    key: "market",
    label: $t("sell.modal.transactionPreview.steps.orderProcessed"),
    suffix: transactionDates.value.processing,
  },
  {
    key: "validation",
    label: $t("sell.modal.transactionPreview.steps.pendingValidation"),
  },
  {
    key: "wire",
    label: $t("sell.modal.transactionPreview.steps.transfer"),
    suffix: $t("sell.modal.transactionPreview.steps.transferSuffix"),
  },
]);
</script>
