<script setup lang="ts">
import { ref } from "vue";
import { isAxiosError } from "axios";
import { useRouter } from "vue-router";

import axios from "@/lib/axios";

import Logo from "@/components/Logo.vue";
import GoogleLogo from "@/components/misc/GoogleLogo.vue";

import { useAuthStore } from "@/stores/auth";

const router = useRouter();

const authStore = useAuthStore();

const email = ref<string | undefined>();
const password = ref<string | undefined>();
const forgotPasswordView = ref(false);
const forgotPasswordViewResult = ref<boolean | undefined>();
const loginError = ref(false);

const login = async () => {
  if (!email.value || !password.value) {
    loginError.value = true;
    return;
  }

  try {
    await axios.post("/auth/login", {
      email: email.value,
      password: password.value,
    });

    const user = await authStore.loadUser();
    if (user) {
      router.push("/");
    } else {
      loginError.value = true;
    }
  } catch (e) {
    if (isAxiosError(e)) {
      loginError.value = true;
    }
  }
};

const loginWithGoogle = () => {
  window.location.href = `${axios.defaults.baseURL}auth/google/login`;
};

const reset = async () => {
  if (!email.value) return;

  try {
    await axios.post("/auth/reset", {
      email: email.value,
    });
    forgotPasswordViewResult.value = true;
  } catch (e) {
    if (isAxiosError(e)) {
      forgotPasswordViewResult.value = false;
    }
  }
};
</script>

<template>
  <div class="h-full w-full p-8 flex flex-col items-center">
    <a href="https://homaio.com">
      <Logo />
    </a>

    <div class="flex-1 flex justify-center items-center w-full">
      <div class="w-full max-w-lg text-marine-800">
        <Transition mode="out-in" name="fade">
          <div v-if="!forgotPasswordView">
            <div class="text-5xl font-bold font-display">
              {{ $t("auth.welcomeBack") }}
            </div>
            <div class="font-medium mt-3 font-display">
              {{ $t("auth.dontHaveAccount") }}
              <RouterLink
                :to="{ name: 'signup' }"
                class="text-indigo-400 font-bold"
              >
                {{ $t("auth.signUp") }}
              </RouterLink>
            </div>

            <TBtn class="w-full mt-10" large outlined @click="loginWithGoogle">
              <div class="flex justify-center">
                <GoogleLogo />
              </div>
            </TBtn>

            <div class="flex items-center my-5 w-full">
              <div class="h-[1px] bg-marine-200 flex-1" />
              <div class="px-4 text-marine-400">
                {{ $t("auth.continueWith") }}
              </div>
              <div class="h-[1px] bg-marine-200 flex-1" />
            </div>

            <div>
              <TTextField
                v-model="email"
                :placeholder="$t('auth.email')"
                name="email"
                autofocus
                @update:model-value="loginError = false"
              />
              <TTextField
                v-model="password"
                :placeholder="$t('auth.password')"
                type="password"
                class="mt-4"
                name="password"
                @update:model-value="loginError = false"
                @keydown.enter="login"
              />
              <div v-if="loginError" class="text-red-400 my-2 font-medium">
                {{ $t("auth.emailOrPasswordIncorrect") }}
              </div>

              <div class="mt-4">
                <span
                  class="font-medium cursor-pointer"
                  @click="forgotPasswordView = true"
                >
                  {{ $t("auth.forgotPassword") }}
                </span>
              </div>

              <TBtn
                class="w-full mt-12"
                large
                :disabled="loginError"
                @click="login"
              >
                {{ $t("global.continue") }}
              </TBtn>
            </div>
          </div>
          <div v-else>
            <div class="text-5xl font-bold">
              {{ $t("auth.forgotPassword") }}
            </div>
            <div class="font-medium mt-3">
              {{ $t("auth.forgotPasswordText") }}
            </div>

            <TTextField
              v-model="email"
              class="mt-20"
              :placeholder="$t('auth.email')"
              name="email"
              autofocus
            />
            <div
              v-if="forgotPasswordViewResult === false"
              class="text-red-400 my-2 font-medium"
            >
              {{ $t("auth.resetError") }}
            </div>

            <Transition mode="out-in" name="fade">
              <div
                v-if="forgotPasswordViewResult"
                class="rounded bg-indigo-500 text-white px-6 py-4 font-medium mt-10 shadow mt-8"
              >
                {{ $t("auth.forgotPasswordConfirmation") }}
              </div>
              <TBtn
                v-else-if="!forgotPasswordViewResult"
                class="w-full mt-8"
                large
                :disabled="!email"
                @click="reset"
              >
                {{ $t("auth.resetPassword") }}
              </TBtn>
            </Transition>

            <div class="mt-4 text-center">
              <span
                class="font-bold cursor-pointer text-indigo-400"
                @click="
                  forgotPasswordView = false;
                  forgotPasswordViewResult = undefined;
                "
              >
                {{ $t("global.back") }}
              </span>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>
