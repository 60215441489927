<script setup lang="ts">
import { computed, ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { isAxiosError } from "axios";
import { storeToRefs } from "pinia";

import axios from "@/lib/axios";

import { useNotificationsStore } from "@/stores/notifications";
import { useAuthStore } from "@/stores/auth";

const notificationsStore = useNotificationsStore();
const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const show = ref(false);
const email = ref<string>();

defineProps<{ referalsSent: number; referalsActivated: number }>();
const emit = defineEmits(["referal"]);

const reset = () => {
  show.value = false;
  email.value = undefined;
};

const share = async () => {
  if (!email.value || email.value.length === 0) return;
  try {
    const request = await axios.post("/user/invite", {
      email: email.value,
    });
    notificationsStore.addNotification(
      "Invite successfully sent.",
      "success",
      "Your invite has been sent to your friend.",
    );
    emit("referal");
  } catch (e) {
    if (isAxiosError(e) && e.response && e.response.status === 409) {
      notificationsStore.addNotification(
        "Invite already sent.",
        "error",
        "An invite has already been sent to your friend.",
      );
    }
  }

  reset();
};

// Copy link

const referalLink = computed(
  () => `${window.location.origin}/signup?referal=${user.value!.referalCode}`,
);

const linkCopied = ref(false);
const copyLink = () => {
  navigator.clipboard.writeText(referalLink.value);
  linkCopied.value = true;
  setTimeout(() => {
    linkCopied.value = false;
  }, 2000);
};
</script>

<template>
  <div class="bg-indigo-100 rounded border border-indigo-400 py-6 px-4">
    <div class="flex items-start flex-col md:flex-row">
      <div class="flex-1">
        <div class="text-marine-800 font-bold text-2xl">
          Interested in priority access?
        </div>
        <div class="text-marine-600 mt-1 text-sm">
          Refer 2 people to directly access the next round!
        </div>
      </div>

      <TBtn class="px-5 mt-4 md:mt-0" @click="show = true">
        Refer a friend
      </TBtn>
    </div>

    <div
      v-if="referalsSent"
      class="text-marine-500 mt-4 pt-4 text-sm border-t border-t-marine-300"
    >
      {{ referalsSent }} invite sent <span class="mx-4">⦁</span>
      {{ referalsActivated }} signup from invite
    </div>
  </div>

  <TransitionRoot appear :show="show" as="template">
    <Dialog as="div" class="relative z-40" @close="reset">
      <TransitionChild
        as="template"
        enter="duration-100 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 backdrop-blur-sm bg-white/20" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-start sm:items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-100 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-2xl transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-lg transition-all border"
            >
              <div class="px-4 sm:px-8 pt-8 flex items-start">
                <div class="min-w-0">
                  <div class="font-bold text-2xl text-marine-800">
                    <span class="mr-1">✉️ </span> Refer a friend
                  </div>
                  <div class="mt-3 text-marine-600">
                    For each email and signup, you will earn places in the
                    waitlist!<span class="font-semibold">
                      If two of them signup, we will directly make you access
                      the next round.
                    </span>
                  </div>
                </div>

                <div class="flex-1" />
                <button
                  class="inline-flex items-center justify-center w-6 h-6 transition text-marine-500 hover:text-marine-900 min-w-6 shrink-0"
                  @click="reset"
                >
                  <i class="mdi mdi-close text-lg" />
                </button>
              </div>

              <div class="px-4 sm:px-8 py-8">
                <TTextField
                  v-model="email"
                  placeholder="Email to send invite"
                  name="email"
                  autofocus
                />
              </div>

              <div class="px-4 sm:px-8 pb-8 flex flex-col md:flex-row gap-2">
                <TBtn
                  outlined
                  :class="linkCopied ? 'bg-green-100 hover:bg-green-100' : ''"
                  @click="copyLink"
                >
                  <template v-if="!linkCopied">
                    <i class="mdi mdi-link mr-1" />
                    Copy link
                  </template>
                  <i v-else class="mdi mdi-check text-marine-800" />
                </TBtn>
                <a
                  :href="`whatsapp://send?text=${referalLink}`"
                  target="_blank"
                >
                  <TBtn outlined class="w-full">
                    <i class="mdi mdi-whatsapp mr-1" />
                    Share on Whatsapp
                  </TBtn>
                </a>

                <div class="flex-1" />
                <TBtn :disabled="!email || email.length === 0" @click="share">
                  Share
                </TBtn>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
