<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <g clip-path="url(#clip0_237_4026)">
      <!-- color is orange for now, will be manageable by props later -->
      <path
        d="M11 6.41675C11.5063 6.41675 11.9167 6.82715 11.9167 7.33342V11.0001C11.9167 11.5063 11.5063 11.9167 11 11.9167C10.4938 11.9167 10.0834 11.5063 10.0834 11.0001V7.33342C10.0834 6.82715 10.4938 6.41675 11 6.41675Z"
        fill="#F97316"
      />
      <path
        d="M11 13.7501C10.4938 13.7501 10.0834 14.1605 10.0834 14.6667C10.0834 15.173 10.4938 15.5834 11 15.5834H11.0092C11.5154 15.5834 11.9259 15.173 11.9259 14.6667C11.9259 14.1605 11.5154 13.7501 11.0092 13.7501H11Z"
        fill="#F97316"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.55684 1.18523C6.72875 1.01333 6.96191 0.916748 7.20502 0.916748H14.795C15.0381 0.916748 15.2713 1.01333 15.4432 1.18523L20.8149 6.5569C20.9868 6.72881 21.0834 6.96197 21.0834 7.20508V14.7951C21.0834 15.0382 20.9868 15.2714 20.8149 15.4433L15.4432 20.8149C15.2713 20.9868 15.0381 21.0834 14.795 21.0834H7.20502C6.96191 21.0834 6.72875 20.9868 6.55684 20.8149L1.18517 15.4433C1.01326 15.2714 0.916687 15.0382 0.916687 14.7951V7.20508C0.916687 6.96197 1.01326 6.72881 1.18517 6.5569L6.55684 1.18523ZM7.58472 2.75008L2.75002 7.58478V14.4154L7.58472 19.2501H14.4153L19.25 14.4154V7.58478L14.4153 2.75008H7.58472Z"
        fill="#F97316"
      />
    </g>
    <defs>
      <clipPath id="clip0_237_4026">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
