<script setup lang="ts">
import { storeToRefs } from "pinia";

import PortfolioHistoryMiniChart from "@/containers/portfolio/PortfolioHistoryMiniChart.vue";
import EUAHistoryMiniChart from "@/containers/assets/EUAHistoryMiniChart.vue";
import BuyMore from "@/containers/portfolio/BuyMore.vue";

import { getCurrencyFormatter, getNumberFormatter } from "@/utils/numbers";

import { useAuthStore } from "@/stores/auth";
import { usePortfolioStore } from "@/stores/portfolio";
import type { RouterLink } from "vue-router";

const auth = useAuthStore();

const portfolioStore = usePortfolioStore();
const { totalInvested, totalEUA, totalBalance, lastPrice } =
  storeToRefs(portfolioStore);
</script>

<template>
  <div>
    <div class="mb-14 font-display">
      <div class="flex items-center gap-3">
        <div
          class="mb-1.5 flex h-9 w-9 items-center justify-center rounded-lg bg-white text-lg"
        >
          🏠
        </div>
        <div class="text-3xl font-bold text-marine-900 md:text-4xl">
          {{ $t("home.welcome") }} {{ auth.user?.firstName }}!
        </div>
      </div>
      <div class="mt-2 text-sm font-medium text-marine-600 md:text-base">
        {{ $t("home.subline") }}
      </div>
    </div>

    <div class="rounded-lg border bg-white py-6 shadow-sm">
      <div class="px-6">
        <div class="flex items-center">
          <div class="mb-1.5 mr-2 text-xl">🗂</div>
          <div class="font-display text-3xl font-bold text-marine-800">
            {{ $t("home.portfolio") }}
          </div>
          <div class="flex-1" />
          <RouterLink
            :to="{ name: 'portfolio' }"
            class="flex h-10 w-10 items-center justify-center rounded bg-marine-800 text-white transition-colors hover:bg-marine-900"
          >
            <i class="mdi mdi-arrow-right mdi-24px" />
          </RouterLink>
        </div>
        <div class="mt-1 font-display text-sm font-medium text-marine-400">
          {{ $t("home.portfolioText") }}
        </div>
      </div>

      <div class="mt-6 grid grid-cols-1 gap-4 md:grid-cols-3">
        <div class="border-t px-4 py-4 sm:px-6 md:border-t-0">
          <div
            class="flex items-center font-display font-semibold text-marine-700"
          >
            {{ $t("home.portfolioValue") }}
          </div>
          <template
            v-if="totalBalance !== undefined && totalInvested !== undefined"
          >
            <div class="mt-4 text-4xl font-bold">
              {{ getCurrencyFormatter("EUR").format(totalBalance) }}
            </div>
            <div class="mt-3 text-sm font-medium text-marine-400">
              {{ getCurrencyFormatter("EUR").format(totalInvested) }}
              {{ $t("portfolio.invested") }}
            </div>
          </template>
          <div v-else class="mt-4 h-[72px] w-full rounded bg-gray-100" />
        </div>

        <div
          class="w-full border-t px-4 py-4 sm:px-6 md:border-l md:border-t-0"
        >
          <div
            class="flex items-center font-display font-semibold text-marine-700"
          >
            {{ $t("portfolio.totalHoldings") }}
          </div>
          <template v-if="totalEUA !== undefined">
            <div class="mt-4 text-4xl font-bold">
              {{ getCurrencyFormatter("EUA").format(totalEUA) }}
            </div>

            <div class="mt-3 text-sm font-medium text-marine-400">
              = {{ getNumberFormatter().format(totalEUA) }}
              {{ $t("portfolio.CO2tons") }}
              >
            </div>
          </template>
          <div v-else class="mt-4 h-[72px] w-full rounded bg-gray-100" />
        </div>

        <div class="px-4 sm:px-6">
          <PortfolioHistoryMiniChart />
        </div>
      </div>
    </div>

    <div class="mt-6 flex gap-6">
      <div class="basis-1/2 rounded-lg border bg-white py-6 shadow-sm">
        <div class="px-6">
          <div class="flex items-center">
            <div class="mb-1.5 mr-2 text-xl">🇪🇺</div>
            <div class="font-display text-3xl font-bold text-marine-800">
              {{ $t("home.EUAllowances") }}
            </div>
            <div class="flex-1" />
          </div>
          <div class="mt-1 font-display text-sm font-medium text-marine-400">
            {{ $t("home.EUAllowancesText") }}
          </div>
        </div>

        <div class="mt-6 flex gap-2">
          <div class="basis-1/3 border-t sm:px-6 md:border-l md:border-t-0">
            <div
              class="flex items-center font-display font-semibold text-marine-700"
            >
              {{ $t("home.lastPrice") }}
            </div>
            <div
              v-if="lastPrice !== undefined"
              class="mt-4 w-full grow basis-2/3 text-4xl font-bold"
            >
              {{ getCurrencyFormatter("EUR").format(lastPrice.price) }}
            </div>
            <div v-else class="mt-4 h-10 w-full rounded bg-gray-100" />
          </div>

          <div class="px-2 sm:px-6">
            <EUAHistoryMiniChart />
          </div>
        </div>
      </div>
      <div class="basis-1/2">
        <BuyMore
          :title="$t('portfolio.buyMore')"
          :button-text="$t('portfolio.joinOurNextRound')"
        />
      </div>
    </div>
  </div>
</template>
