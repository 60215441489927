<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import axios from "@/lib/axios";

import OnboardingSidebar from "@/containers/onboarding/OnboardingSidebar.vue";
import Loader from "@/components/Loader.vue";

import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const router = useRouter();

const contractLoading = ref(true);
const currentOrder = ref();
const signingLink = ref();

const generateContract = async () => {
  if (currentOrder.value === undefined) return;

  const request = await axios.post(`/orders/${currentOrder.value}/contract`);
  signingLink.value = request.data.signature_link;
  contractLoading.value = false;

  setTimeout(checkOrderSignature, 1000);
};

const checkOrderSignature = async () => {
  const orders = await axios.get("/orders/");
  if (orders.data[0].signature_done) {
    user.value!.status = "deposit_pending";
    router.push({ name: "onboarding-deposit" });
  } else {
    setTimeout(checkOrderSignature, 1000);
  }
};

const loadOrder = async () => {
  const orders = await axios.get("/orders/");
  currentOrder.value = orders.data[0].id;

  if (orders.data[0].signature_link) {
    signingLink.value = orders.data[0].signature_link;
    contractLoading.value = false;

    setTimeout(checkOrderSignature, 1000);
  } else {
    await generateContract();
  }
};

loadOrder();
</script>

<template>
  <div class="flex h-full w-full">
    <OnboardingSidebar current-step="signature" />

    <div
      class="flex h-full flex-1 flex-col items-center overflow-y-auto p-6 sm:p-8"
    >
      <OnboardingHeader />

      <div class="mt-4 flex w-full flex-1 justify-center">
        <div
          class="relative flex h-full w-full max-w-md flex-col items-center justify-center py-16 text-marine-800"
        >
          <div
            class="text-center font-display text-4xl font-bold text-marine-800"
          >
            <template v-if="contractLoading">
              {{ $t("onboarding.steps.signature.content.generatingContract") }}
            </template>
            <template v-else>
              {{ $t("onboarding.steps.signature.content.waitingSignature") }}
            </template>
          </div>

          <Loader class="mx-auto my-10 h-20 w-20 text-marine-800" />

          <a
            v-if="signingLink"
            :href="signingLink"
            target="_blank"
            class="mt-5 block w-full"
          >
            <TBtn class="w-full">
              {{ $t("onboarding.steps.signature.content.openContract") }}
            </TBtn>
          </a>
          <div v-else class="text-center font-display text-2xl text-marine-500">
            {{ $t("onboarding.steps.signature.content.wait") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
