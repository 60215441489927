import { ref, onUnmounted } from 'vue';

export function useDebounce<T extends (...args: any[]) => void>(callback: T, delay: number): (...args: Parameters<T>) => void {
    const timeout = ref<number | null>(null);

    const debounce = (...args: Parameters<T>): void => {
        if (timeout.value !== null) {
            clearTimeout(timeout.value);
        }
        timeout.value = window.setTimeout(() => {
            callback(...args);
        }, delay);
    };

    onUnmounted(() => {
        if (timeout.value !== null) {
            clearTimeout(timeout.value);
        }
    });

    return debounce;
}
