export enum TransactionType {
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdrawal",
  FEE = "fee",
  TRANSFER = "transfer",
}

export enum TransactionStatus {
  PROCESSED = "processed",
  PROCESSING = "processing",
  CANCELLED = "cancelled",
  UPCOMING = "upcoming",
}

export enum TransactionAccount {
  EXTERNAL = "external",
  HOMAIO = "homaio",
  EURO = "eur",
  EUA = "eua",
  BATCH = "batch",
}

export type Transaction = {
  id: number;
  date: string;
  type: TransactionType;
  description: string | null;
  status: TransactionStatus;
  from_amount: number;
  from_account: TransactionAccount;
  to_amount: number;
  to_account: TransactionAccount;
  metadata: any;
};
