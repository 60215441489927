<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, nextTick } from "vue";
import { useRouter } from "vue-router";
import { getCurrencyFormatter, nFormatter, parseAmount } from "@/utils/numbers";

import axios from "@/lib/axios";
import Check from "@/components/Check.vue";
import Cross from "@/components/Cross.vue";
import SRRI from "@/components/SRRI.vue";
import investmentScheme from "@/assets/investmentScheme.gif";

import Banner from "@/containers/onboarding/Banner.vue";
import Loader from "@/components/Loader.vue";

import { useAuthStore } from "@/stores/auth";
import { usePortfolioStore } from "@/stores/portfolio";
import { useBatchesStore } from "@/stores/batches";
import { useOrdersStore } from "@/stores/orders";

const portfolioStore = usePortfolioStore();
const authStore = useAuthStore();
const batchesStore = useBatchesStore();
const ordersStore = useOrdersStore();

const router = useRouter();

const { lastPrice } = storeToRefs(portfolioStore);
const { currentBatch } = storeToRefs(batchesStore);
const { user } = storeToRefs(authStore);
const { latestOrder } = storeToRefs(ordersStore);

const contractLoading = ref<boolean>(false);
const contractReady = ref<boolean>(false);
const contractOpened = ref<boolean>(false);
const amount = ref<number>(1000);
const signingLink = ref();

const props = withDefaults(
  defineProps<{
    reBuy?: boolean;
  }>(),
  {
    reBuy: false,
  },
);

const canInvest = computed(() => amount.value >= 1000);
const hasReducedFees = computed(() => amount.value >= 20_000);
const batchHasContracts = computed(() => currentBatch.value?.hasContracts);

const currentFees = computed(() => {
  if (!currentBatch.value)
    return {
      entryFee: 0.02,
      custodyFee: 0.015,
      lockup: 6,
    };

  return {
    entryFee:
      currentBatch.value[
        hasReducedFees.value ? "reducedEntryFee" : "basicEntryFee"
      ],
    custodyFee:
      currentBatch.value[
        hasReducedFees.value ? "reducedCustodyFee" : "basicCustodyFee"
      ],
    lockup:
      currentBatch.value[
        hasReducedFees.value ? "reducedLockup" : "basicLockup"
      ],
  };
});

const increase = () => {
  amount.value = Math.ceil((amount.value + 1000) / 100) * 100;
};

const decrease = () => {
  amount.value = Math.ceil((amount.value - 1000) / 100) * 100;
};

const loadAmount = async () => {
  if (!user.value?.amount) return;

  amount.value = user.value?.amount;

  if (latestOrder.value?.signature_link) {
    signingLink.value = latestOrder.value.signature_link;
    contractReady.value = true;
    await openContract();
  }
};

const checkOrderSignature = async () => {
  await ordersStore.getLatestOrder();

  if (latestOrder.value.signature_done) {
    user.value!.status = "deposit_pending";

    if (!props.reBuy) {
      router.push({ name: "onboarding-deposit" });
    }
  } else {
    setTimeout(checkOrderSignature, 1000);
  }
};

const generateContract = async () => {
  if (latestOrder.value === undefined || !amount.value) return;

  try {
    await axios.patch("/user", { amount: amount.value });

    const orderRequest = await axios.patch(`/orders/${latestOrder.value.id}`, {
      amount: amount.value,
    });

    if (orderRequest.status !== 200) return;

    contractLoading.value = true;
    contractReady.value = false;
    const request = await axios.post(
      `/orders/${latestOrder.value.id}/contract`,
    );
    contractLoading.value = false;
    signingLink.value = request.data.signature_link;
    user.value!.status = "signature_pending";

    contractReady.value = !!signingLink.value;
  } catch (err) {
    console.error(err);
  }
};

const openContract = async () => {
  contractOpened.value = true;
  document.querySelector("main")!.classList.add("yousign-iframe-open");

  await nextTick();

  // @ts-ignore
  new Yousign({
    signatureLink: signingLink.value,
    iframeContainerId: "signature-container",
    isSandbox: process.env.NODE_ENV !== "production",
  });

  setTimeout(checkOrderSignature, 1000);
};

const closeContract = () => {
  contractOpened.value = false;
  document.querySelector("main")!.classList.remove("yousign-iframe-open");
};

Promise.all([
  loadAmount(),
  batchesStore.getActiveBatch(),
  ordersStore.getLatestOrder(),
  portfolioStore.fetchLastPrice(),
]);
</script>

<template>
  <div
    class="w-full md:flex"
    :class="{
      'relative h-[85svh] overflow-auto rounded-lg p-8': props.reBuy,
      'overflow-hidden': contractReady,
    }"
  >
    <div class="flex flex-col gap-4">
      <div class="font-display">
        <span class="text-4xl font-bold text-marine-800">
          {{ $t("onboarding.steps.terms.content.title") }}
        </span>
        <br />
        <span class="text-lg font-medium md:text-2xl">
          {{ $t("onboarding.steps.terms.content.subtitle") }}
        </span>
      </div>

      <div class="rounded-lg border border-gray-200 bg-white p-6">
        <div class="text-xl text-marine-900">
          {{ $t("onboarding.steps.terms.content.yourInvestment") }}
        </div>
        <div class="text-sm text-marine-400">
          {{ $t("onboarding.steps.terms.content.modifyYourInvestment") }}
        </div>

        <div
          class="align-center mt-8 flex flex-wrap items-center justify-start gap-6 md:flex-row md:flex-nowrap md:gap-0"
        >
          <div
            class="relative flex h-[50px] w-full flex-row items-center justify-between rounded-lg border p-4 md:basis-1/2 md:p-8 2xl:basis-3/5"
          >
            <div
              class="h-[16px] w-[16px] cursor-pointer select-none rounded-full border border-marine-800 text-center leading-3"
              @click="!contractReady && decrease()"
            >
              -
            </div>
            <input
              class="w-[100%] rounded-md bg-white text-center text-2xl font-bold md:text-3xl"
              :class="{
                'opacity-60': contractLoading,
              }"
              :value="
                amount === undefined
                  ? undefined
                  : getCurrencyFormatter('EUR', 0).format(amount)
              "
              :placeholder="getCurrencyFormatter('EUR', 0).format(0)"
              :disabled="contractReady"
              @input="
                (event) =>
                  (amount =
                    amount < 100
                      ? parseAmount((event.target as HTMLInputElement).value)
                      : Math.ceil(
                          parseAmount(
                            (event.target as HTMLInputElement).value,
                          ) / 100,
                        ) * 100)
              "
            />
            <div
              class="h-[16px] w-[16px] cursor-pointer select-none rounded-full border border-marine-800 text-center leading-3"
              @click="!contractReady && increase()"
            >
              +
            </div>
            <div
              v-if="!canInvest"
              class="absolute -bottom-5 -left-0 inline-block text-left text-xs font-medium text-red-400 md:-bottom-6 md:w-[300px] 2xl:w-full 2xl:text-center 2xl:text-base"
            >
              {{ $t("onboarding.steps.invest.content.minimumAmount") }}
            </div>
          </div>

          <div
            class="w-full px-2 text-center md:basis-1/4 2xl:basis-1/5 2xl:px-6"
          >
            <div class="font-medium 2xl:text-base">
              =
              {{ nFormatter(amount ? amount / lastPrice?.price : 0, 2) }}
              EUAs
            </div>
            <div
              class="text-display text-center text-xs text-marine-500 2xl:text-sm"
            >
              <div>
                {{ nFormatter(amount ? amount / lastPrice?.price : 0, 2) }}
                t eqCO2
              </div>
              {{ $t("onboarding.steps.invest.content.atCurrentPrice") }}
            </div>
          </div>

          <ul class="grow px-2 text-sm">
            <li
              class="flex items-center justify-center text-marine-900 md:justify-start"
            >
              <Check class="h-3 w-3" />
              <span class="ml-1">
                {{
                  $t("onboarding.steps.invest.content.accessToDashboard")
                }}</span
              >
            </li>
            <li
              class="flex items-center justify-center text-marine-900 md:justify-start"
            >
              <Check class="h-3 w-3" :disabled="amount < 20000" />
              <span class="ml-1">
                {{ $t("onboarding.steps.invest.content.reducedFees") }}</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="rounded-lg border border-gray-200 bg-white p-6">
        <div class="text-lg text-marine-900">
          {{ $t("onboarding.steps.terms.content.summaryInvestmentTerms") }}
        </div>

        <div class="mt-6 flex w-full flex-col gap-4">
          <div
            v-for="term in [
              {
                id: 'entryFee',
                value: currentFees?.entryFee * 100,
              },
              {
                id: 'custodyFee',
                value: currentFees?.custodyFee * 100,
              },
              {
                id: 'sellDuration',
              },
              {
                id: 'lockup',
                value: currentFees?.lockup,
              },
              {
                id: 'securityAgent',
              },
            ]"
            :key="term.id"
            class="flex flex-wrap"
          >
            <div
              class="basis-full text-left font-display text-marine-400 md:basis-1/2"
            >
              {{ $t(`onboarding.steps.terms.content.terms.${term.id}.label`) }}
            </div>
            <div class="text-left font-medium text-marine-900 md:basis-1/2">
              <span
                v-if="term.value === 0 || term.value"
                class="font-bold"
                :class="{ 'text-green-400': hasReducedFees }"
                >{{ term.value }}</span
              >
              {{ $t(`onboarding.steps.terms.content.terms.${term.id}.text`) }}
            </div>
          </div>
        </div>
      </div>

      <div class="rounded-lg border border-gray-200 bg-white p-6">
        <div class="text-xl text-marine-900">
          {{ $t("onboarding.steps.terms.content.SRRI.title") }}
        </div>

        <div
          class="mt-8 flex w-full flex-row flex-wrap justify-center gap-4 md:flex-row-reverse 2xl:justify-between"
        >
          <div class="align-center scale-75 md:-mt-6 md:ml-0 md:scale-100">
            <SRRI />
          </div>
          <div class="text-display text-justify text-marine-200 2xl:basis-1/2">
            {{ $t("onboarding.steps.terms.content.SRRI.description") }}
          </div>
        </div>
      </div>

      <div
        class="rounded-lg border border-gray-200 bg-white p-6"
        :class="{ 'pb-32': props.reBuy }"
      >
        <div class="text-lg text-marine-900">
          {{ $t("onboarding.steps.terms.content.investmentScheme") }}
        </div>

        <div
          class="mt-4 hidden h-[430px] w-full rounded-sm bg-cover bg-no-repeat shadow md:block 2xl:h-[380px] min-[1600px]:h-[540px] 3xl:h-[910px]"
          :style="{ backgroundImage: `url(${investmentScheme})` }"
        ></div>
        <div class="mt-6 flex w-full flex-col gap-4">
          <div
            v-for="info in [
              'issuer',
              'assetType',
              'underlyingAssets',
              'custodyAccount',
            ]"
            :key="info"
            class="flex flex-wrap"
          >
            <div
              class="basis-full text-left font-display text-marine-400 md:basis-1/2"
            >
              {{ $t(`onboarding.steps.terms.content.infos.${info}.label`) }}
            </div>
            <div class="text-left font-medium text-marine-900 md:basis-1/2">
              {{ $t(`onboarding.steps.terms.content.infos.${info}.value`) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <Banner
      :class="{
        '!bottom-14 left-[0.5rem] w-[calc(100%-1rem)] md:!bottom-12 md:!left-[4.5rem] md:!w-[calc(100%-9rem)] 2xl:bottom-24':
          props.reBuy,
        '!left-3 md:!bottom-0 md:!left-[24.5rem] md:!w-[calc(100%-26.5rem)]':
          !props.reBuy,
        'z-10': contractReady,
      }"
    >
      <template #button>
        <div
          class="relative mt-2 md:mt-0 md:basis-1/3 xl:max-w-[315px] xl:basis-full"
        >
          <div
            v-if="contractReady || !batchHasContracts"
            :class="{
              '-top-[4.5em] md:-top-[5.5em] 2xl:-top-[4.75em]': contractReady,
              '-top-[6.5em] md:-top-[9.5em] 2xl:-top-[7.75em]':
                !batchHasContracts,
            }"
            class="arrow-bottom-center absolute block w-full rounded-xl bg-white p-2 text-center font-display text-sm font-bold text-marine-800 shadow-md md:py-5 2xl:text-lg"
          >
            {{
              contractReady
                ? $t(`onboarding.steps.terms.content.contractGenerated`)
                : $t(`onboarding.steps.terms.content.noContracts`)
            }}
          </div>
          <button
            class="block w-full rounded-xl p-4 font-display font-bold tracking-[0.16px] md:mt-0 md:p-4 xl:px-6"
            :class="{
              'pointer-events-none border border-marine-200 bg-white text-marine-400':
                !canInvest,
              'border-none bg-marine-900 text-white': canInvest,
              'bg-marine-700': contractLoading,
              'opacity-40': !batchHasContracts,
            }"
            :disabled="!canInvest || contractLoading || !batchHasContracts"
            @click="contractReady ? openContract() : generateContract()"
          >
            <span v-if="contractReady"
              >{{ $t(`onboarding.steps.terms.content.viewAndSignContract`) }}
            </span>
            <span v-else-if="!contractLoading">{{
              $t(`onboarding.steps.terms.content.continueToContract`)
            }}</span>
            <span v-else>
              <Loader class="mx-auto mr-2 inline h-5 w-5 text-marine-200" />

              {{ $t("onboarding.steps.signature.content.generatingContract") }}

              <span class="ml-2 text-marine-300">30sec...</span>
            </span>
          </button>
        </div>
      </template>
    </Banner>

    <div
      class="absolute left-0 top-0 h-full w-full bg-black pt-24 md:pt-8"
      :class="{
        'z-3 bg-opacity-10': contractReady,
        'z-50 bg-opacity-50': contractOpened,
        hidden: !contractOpened && !contractReady,
      }"
    >
      <div
        v-if="contractOpened"
        class="flex h-full w-full flex-row justify-center"
      >
        <div
          id="signature-container"
          ref="iframe"
          class="w-full rounded-lg md:w-2/3"
        ></div>

        <button
          class="absolute top-8 inline-flex rounded-[60px] bg-black bg-opacity-50 p-2 px-6 text-white hover:bg-white hover:bg-opacity-5 md:right-24 2xl:right-48 2xl:h-10 3xl:right-72"
          @click="closeContract"
        >
          <Cross /> {{ $t("global.close") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style>
#yousign-iframe {
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
</style>
