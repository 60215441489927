<script setup lang="ts">
const props = defineProps<{
  asset?: string;
}>();

import backgroundImage from "@/assets/eua.png";
</script>

<template>
  <div class="overflow-hidden rounded-2xl bg-white">
    <div
      class="relative h-[120px] w-full bg-cover bg-center bg-no-repeat px-7 py-5"
      :style="{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImage})`,
      }"
    >
      <div class="text-xl font-medium text-white">
        {{ $t(`onboarding.steps.invest.content.assets.${props.asset}.name`) }}
      </div>

      <div class="text-base font-medium uppercase text-white text-opacity-50">
        {{ asset }}
      </div>
      <!-- // <div
        class="absolute right-2 top-2 rounded-full bg-[#3265E7] px-3 py-1 font-display text-xs font-semibold text-white 3xl:right-3 3xl:top-3"
       >
         {{
           asset.nextBatchEnd
             ? $t("onboarding.steps.invest.content.live")
             : $t("onboarding.steps.invest.content.comingSoon")
         }}
       </div> -->
    </div>
    <div class="flew-row flex min-h-[100px] justify-between px-7 py-6">
      <div class="basis-2/5">
        <div class="text-xs font-medium text-marine-800">
          {{ $t("onboarding.steps.invest.content.performance.label") }}
        </div>
        <div class="mt-2">
          <div class="text-2xl font-semibold text-marine-900">
            {{
              $t(
                `onboarding.steps.invest.content.assets.${props.asset}.performance`,
              )
            }}
            <span class="text-lg">%</span>
          </div>
          <div class="text-[10px] font-medium text-marine-400">
            {{ $t("onboarding.steps.invest.content.performance.suffix") }}
          </div>
        </div>
      </div>
      <div class="border-r border-marine-50" />
      <div class="basis-2/5">
        <div class="text-xs font-medium text-marine-800">
          {{ $t("onboarding.steps.invest.content.impact.label") }}
        </div>
        <div class="mt-2">
          <div class="text-2xl font-semibold text-marine-900">
            {{
              $t(`onboarding.steps.invest.content.assets.${props.asset}.impact`)
            }}
            <span class="text-lg">%</span>
          </div>
          <div class="text-[10px] font-medium text-marine-400">
            {{ $t("onboarding.steps.invest.content.impact.suffix") }}
          </div>
        </div>
      </div>
    </div>
    <div class="h-[100px] w-full bg-snuff-25"></div>
    <!-- <div
      class="flex h-[100px] flex-row justify-center gap-6 bg-snuff-25 py-7 2xl:gap-24"
    >
      <template v-if="asset.nextBatchEnd">
        <div class="text-left text-marine-400">
          <div class="text-xs">
            {{ $t("onboarding.steps.invest.content.nextRoundClosing") }}:
          </div>
          <div class="font-display text-xl font-bold">
            {{ asset.nextBatchEnd.format("DD MMM YYYY") }}
          </div>
        </div>
        <button
          class="block rounded-lg bg-marine-900 px-5 font-display font-bold tracking-[0.16px] text-white"
          @click="step = 1"
        >
          {{ $t("onboarding.steps.invest.content.startInvesting") }}
        </button>
      </template>
      <template v-else>
        <div
          class="flex items-center text-center font-display text-xl font-bold text-marine-300"
        >
          {{ $t("onboarding.steps.invest.content.comingSoon") }}
        </div>
      </template>
    </div> -->
  </div>
</template>
