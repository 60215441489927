<template>
  <div class="border border-marine-50 rounded-lg p-2">
    <div class="flex gap-4 items-center cursor-pointer" @click="toggle">
      <TIcon v-if="icon" :icon="icon" />
      <p class="flex-1 text-sm font-semibold">{{ title }}</p>
      <TIcon :icon="isOpen ? 'navArrowUp' : 'navArrowDown'" />
    </div>
    <div v-if="isOpen" class="text-marine-400 text-sm px-10 py-1">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IconType } from "@/constants/iconsConstants";
import TIcon from "@/components/designSystem/TIcon.vue";
import { ref } from 'vue';

const props = withDefaults(
    defineProps<{
      icon?: keyof typeof IconType,
      title: string,
    }>(),
    {
      title: '',
      icon: undefined,
    },
);

const isOpen = ref(false);

const toggle = () => {
  isOpen.value = !isOpen.value;
}
</script>
