import type { Router } from "vue-router";

import posthog from "posthog-js";

export const initPosthog = (router: Router) => {
  if (!import.meta.env.VITE_POSTHOG_KEY) return;

  posthog.init(import.meta.env.VITE_POSTHOG_KEY, {
    api_host: "https://app.posthog.com",
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
        color: false,
        date: false,
        "datetime-local": false,
        email: false,
        month: false,
        number: false,
        range: false,
        search: false,
        tel: false,
        text: false,
        time: false,
        url: false,
        week: false,
        textarea: false,
        select: false,
      },
    },
  });

  router.afterEach((to) => {
    posthog.capture("$pageview", {
      $current_url: to.fullPath,
    });
  });
};
