<template>
  <div class="absolute h-full">
    <div class="flex justify-between px-4 pt-4 lg:hidden">
      <TIcon icon="arrowLeft" class="cursor-pointer" @click.stop="goPrevStep" />
      <TIcon icon="cross" class="cursor-pointer" @click.stop="closeModal" />
    </div>
    <div
      class="flex h-full flex-col overflow-scroll lg:grid lg:grid-cols-10 lg:overflow-hidden"
    >
      <div class="lg:col-span-6 lg:max-h-[730px]">
        <SellModalBankInfo
          v-model="transaction"
          @modal-close="closeModal"
          @next-step="continueToNextStep"
          @prev-step="goPrevStep"
        />
      </div>
      <div class="lg:col-span-4 lg:h-full lg:overflow-hidden">
        <div class="p-4 pb-10 lg:h-full lg:pb-4">
          <SellModalTransactionPreview
            v-model="transaction"
            @next-step="continueToNextStep"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SellModalTransactionPreview from "@/containers/modals/sell/SellModalTransactionPreview.vue";
import SellTransaction from "@/models/sell-transaction";
import SellModalBankInfo from "@/containers/modals/sell/SellModalBankInfo.vue";
import { SellModalStep } from "@/constants/sell-modal-constants";

const transaction = defineModel<SellTransaction>({ required: true });

const emit = defineEmits(["modal-close", "next-step", "prev-step"]);

const continueToNextStep = (step: string) => {
  transaction.value.bankInfoVerified = step === SellModalStep.overview;
  emit("next-step", step);
};

const goPrevStep = () => {
  transaction.value.transactionLocked = false;
  emit("prev-step");
};

const closeModal = () => {
  emit("modal-close");
};
</script>
