<template>
  <div class="mt-12">
    <h2 class="text-lg font-semibold text-marine-900">
      {{ $t("sell.modal.portfolioOverview.title") }}
    </h2>
    <hr class="my-4 border-t border-marine-50" />
    <p class="font-semibold text-marine-400">
      {{ $t("sell.modal.portfolioOverview.netValue") }}
    </p>
    <p class="mt-4 text-4xl font-semibold">
      {{ portfolio.balance.toFixed(2) }} €
    </p>
    <p class="mt-2 text-sm text-marine-400">
      {{ $t("sell.modal.portfolioOverview.initialInvestement") }}
      {{ portfolio.totalInvested.toFixed(2) }} €
    </p>
    <div class="mt-4 grid grid-cols-2 gap-2">
      <div
        v-for="(card, index) in cards"
        :key="index"
        class="rounded-lg bg-transparent p-4 ring-1 ring-marine-50"
      >
        <p class="text-sm font-semibold text-marine-400">{{ card.title }}</p>
        <p :class="cn('mt-2 text-xl font-semibold', card.statClasses)">
          {{ card.stat }}
        </p>
        <p :class="cn('text-sm text-marine-400', card.statInfoClasses)">
          {{ card.statInfo }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cn } from "@/helpers/tailwind-helpers";
import SellTransaction from "@/models/sell-transaction";
import { usePortfolioStore } from "@/stores/portfolio";
import { type Portfolio } from "@/types/portfolio";
import { useI18n } from "vue-i18n";
import { getPercentageFormatter } from "@/utils/numbers";
import _ from "lodash";

const { locale, t: $t } = useI18n();

const portfolioStore = usePortfolioStore();
const portfolio: Portfolio = portfolioStore.portfolio!;

const transaction = defineModel<SellTransaction>({ required: true });

const totalEua = portfolio.totalHoldings.toFixed(2);
const totalHolding = portfolio.totalHoldings.toFixed(2);
const averageBondValue = (portfolio.balance / portfolio.totalBonds).toFixed(2);
const euaPrice = portfolioStore.lastPrice.price.toFixed(2);
const averageEuaPrice = (
  portfolio.totalInvested / portfolio.totalHoldings
).toFixed(2);
const netEarning = portfolio.netEarnings.toFixed(2);
const isRoiNegative = portfolio.netROI < 0;
const successClasses = "bg-success-200 text-success-600";
const dangerClasses = "bg-danger-200 text-danger-600";

const cards = [
  {
    title: $t("sell.modal.portfolioOverview.euas"),
    stat: totalEua,
    statInfo: `= ${totalHolding} ${$t("sell.modal.portfolioOverview.tonsCo2")}`,
    statClasses: "",
    statInfoClasses: "",
  },
  {
    title: _.capitalize($t("global.bonds", 2)),
    stat: `${portfolio.totalBonds} HCH`,
    statInfo: `${$t("sell.modal.portfolioOverview.averageValue")} ${averageBondValue}€`,
    statClasses: "",
    statInfoClasses: "",
  },
  {
    title: $t("sell.modal.portfolioOverview.euaPrice"),
    stat: `${euaPrice} €`,
    statInfo: `${$t("sell.modal.portfolioOverview.averageEntryPrice")} ${averageEuaPrice} €`,
    statClasses: "",
    statInfoClasses: "",
  },
  {
    title: $t("sell.modal.portfolioOverview.netEarnings"),
    stat: `${!isRoiNegative ? "+ " : ""}${netEarning} €`,
    statInfo: `${!isRoiNegative ? "+" : ""}${getPercentageFormatter().format(portfolio.netROI)}`,
    statClasses: isRoiNegative ? "text-danger-500" : "text-success-500",
    statInfoClasses: `inline-block mt-1 px-2 py-1 font-semibold  rounded-lg ${isRoiNegative ? dangerClasses : successClasses}`,
  },
];
</script>
