<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { usePopper } from "@/hooks/use-popper";
import { nFormatter } from "@/utils/numbers";
import dayjs from "dayjs";

const props = withDefaults(
  defineProps<{
    values: { timestamp: string; value: number }[] | undefined;
    legendFormatter: Function;
    verticalLegend?: boolean;
    tooltip?: boolean;
    cursor?: boolean;
    height?: number;
  }>(),
  {
    verticalLegend: true,
    tooltip: true,
    cursor: true,
    height: 320,
  },
);

type PointValue = {
  x: number;
  y: number;
  index: number;
  value: number;
  date: dayjs.Dayjs;
};

// Chart

const [tooltipAnchor, tooltipElement, tooltipPopperInstance] = usePopper({
  placement: "top-start",
  modifiers: [{ name: "offset", options: { offset: [0, 8] } }],
});

const chartWidth = ref<number>();
const chartHeight = ref<number>(props.height);
const usableWidth = computed(() => {
  if (!chartWidth.value) return;
  return chartWidth.value - verticalLegendWidth.value;
});
const usableHeight = computed(() => {
  return chartHeight.value - 32;
});

const mobileBreakpoint = 420;

const container = ref<HTMLElement>();
onMounted(() => {
  if (container.value) {
    chartWidth.value = container.value.clientWidth;
    if (chartWidth.value < mobileBreakpoint) {
      chartHeight.value = 140;
    } else {
      chartHeight.value = props.height;
    }

    const resizeObserver = new ResizeObserver(() => {
      if (container.value) {
        chartWidth.value = container.value.clientWidth;
        if (chartWidth.value < mobileBreakpoint) {
          chartHeight.value = 140;
        } else {
          chartHeight.value = props.height;
        }
      }
    });
    resizeObserver.observe(container.value);

    container.value.addEventListener(
      "mousemove",
      (evt: MouseEvent) => {
        const rect = container.value!.getBoundingClientRect();
        mousePosition.value = {
          x: evt.clientX - rect.left,
          y: evt.clientY - rect.top,
        };
        if (tooltipPopperInstance.value) {
          tooltipPopperInstance.value.update();
        }
      },
      false,
    );
    container.value.addEventListener("mouseleave", () => {
      mousePosition.value = undefined;
    });
  }
});

const getPointValue = (point: number): PointValue | undefined => {
  if (!props.values) return;

  const maxValue = Math.max(...props.values.map((v) => v.value)) * 1.05;
  const minValue = Math.min(...props.values.map((v) => v.value)) * 0.95;

  let xPosition: number | undefined = undefined;
  let yPosition: number | undefined = undefined;
  let index: number | undefined = undefined;
  let value: number | undefined = undefined;
  let date: dayjs.Dayjs | undefined = undefined;
  props.values.forEach((v, i) => {
    const x =
      (usableWidth.value! / (props.values!.length - 1)) * i +
      2 +
      verticalLegendWidth.value;
    const xNext =
      (usableWidth.value! / (props.values!.length - 1)) * (i + 1) +
      2 +
      verticalLegendWidth.value;
    const y =
      usableHeight.value -
      (usableHeight.value / (maxValue - minValue)) * (v.value - minValue);

    if (point > x && point <= xNext) {
      xPosition = x;
      yPosition = y;
      value = v.value;
      index = i;
      date = dayjs(v.timestamp);
    }
  });

  if (
    index === undefined ||
    value === undefined ||
    date === undefined ||
    xPosition === undefined ||
    yPosition === undefined
  )
    return;

  return { x: xPosition, y: yPosition, index, value, date };
};

// Mouse cursor

const mousePosition = ref<{ x: number; y: number }>();
const mousePositionValue = computed<PointValue | undefined>(() => {
  if (!mousePosition.value) return;
  else if (mousePosition.value.x <= verticalLegendWidth.value + 2) return;

  return getPointValue(mousePosition.value.x);
});

defineExpose({ mousePositionValue });

// Points

const chartPoints = computed(() => {
  if (!chartWidth.value) return;
  else if (!props.values) return;

  const maxValue = Math.max(...props.values.map((v) => v.value)) * 1.05;
  const minValue = Math.min(...props.values.map((v) => v.value)) * 0.95;

  let pointsString = "";
  props.values.forEach((value, index) => {
    let prefix = "L";
    if (index === 0) prefix = "M";

    const x =
      (usableWidth.value! / (props.values!.length - 1)) * index +
      2 +
      verticalLegendWidth.value;
    const y =
      usableHeight.value -
      (usableHeight.value / (maxValue - minValue)) * (value.value - minValue);

    pointsString = pointsString.concat(`${prefix}${x},${y}`);
  });

  return pointsString;
});

const chartFill = computed(() => {
  if (!chartPoints.value) return;

  let pointsString = chartPoints.value!;
  const lastX = usableWidth.value! + 2 + verticalLegendWidth.value;

  pointsString = pointsString.concat(
    `L${lastX},${usableHeight.value}L${2 + verticalLegendWidth.value},${
      usableHeight.value
    }`,
  );

  return pointsString;
});

// Horizontal legend

const legends = computed(() => {
  if (!usableWidth.value) return;
  else if (!chartWidth.value) return;

  const legendNumber = chartWidth.value > mobileBreakpoint ? 12 : 6;
  const legendSize = 60;

  const legends: { x: number; text: string }[] = [];
  for (let i = 0; i <= legendNumber; i++) {
    const x =
      verticalLegendWidth.value +
      legendSize / 2 +
      (usableWidth.value / legendNumber) * i;
    if (x < usableWidth.value + verticalLegendWidth.value) {
      const legendValue = getPointValue(x + 8);
      if (legendValue) {
        const text = props.legendFormatter(legendValue.date);
        legends.push({ x, text });
      }
    }
  }

  return legends;
});

// Vertical legend

const verticalLegendWidth = computed(() => {
  if (!props.verticalLegend) return 0;
  else if (!chartWidth.value || chartWidth.value < mobileBreakpoint) return 0;
  else return 60;
});
const verticalLegends = computed(() => {
  const MAX_NUMBER_FLOORS = 5;

  if (!props.values) return;
  const maxValue = Math.max(...props.values.map((v) => v.value)) * 1.05;
  const minValue = Math.min(...props.values.map((v) => v.value)) * 0.95;

  const valuesScale = Math.floor(Math.log10(maxValue - minValue)) - 1;
  const valuesRoundMax = 10 ** valuesScale;
  const floorsScaleMax = Math.floor((maxValue - minValue) / valuesRoundMax);

  let numberFloors = MAX_NUMBER_FLOORS + 1;
  let floorsValueMax = 0;

  while (
    maxValue - minValue - floorsValueMax > floorsValueMax / numberFloors &&
    numberFloors > 2
  ) {
    numberFloors -= 1;

    const floorsScaleMaxDiv = floorsScaleMax - (floorsScaleMax % numberFloors);
    floorsValueMax = floorsScaleMaxDiv * valuesRoundMax;
  }

  const floorHeightMax =
    (floorsValueMax * usableHeight.value) / (maxValue - minValue);

  const floors = [];
  for (let i = 1; i <= numberFloors; i++) {
    const floorValue = (floorsValueMax / numberFloors) * i + minValue;
    const name = `${nFormatter(floorValue, 1)}€`;

    floors.push({
      y: usableHeight.value + 4 - (floorHeightMax / numberFloors) * i,
      text: name,
    });
  }
  return floors;
});
</script>

<template>
  <div ref="container" class="relative">
    <svg
      v-if="chartWidth && chartPoints"
      :width="chartWidth"
      :height="chartHeight"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="867.047"
          y1="91.4147"
          x2="455.058"
          y2="-380.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#041952" />
          <stop offset="1" stop-color="#2E678A" />
        </linearGradient>
        <pattern
          id=":r1u:"
          x="0"
          y="0"
          width="6"
          height="6"
          patternUnits="userSpaceOnUse"
        >
          <g>
            <rect fill="transparent" height="4" width="4"></rect>
            <circle
              cx="3"
              cy="3"
              r="1"
              fill="#041952"
              fill-opacity="0.2"
            ></circle>
          </g>
        </pattern>
      </defs>
      <g transform="translate(0, 2)">
        <g>
          <rect
            x="2"
            :y="usableHeight + 4"
            height="1"
            :width="chartWidth"
            class="fill-gray-200"
          />
          <text
            v-for="(legend, i) in legends"
            :key="i"
            :x="legend.x"
            :y="chartHeight - 8"
            class="text-sm fill-marine-500"
          >
            {{ legend.text }}
          </text>
        </g>
        <g v-if="verticalLegendWidth > 0">
          <text
            v-for="(verticalLegend, i) in verticalLegends"
            :key="i"
            x="48"
            :y="verticalLegend.y"
            class="text-sm fill-marine-500"
            text-anchor="end"
          >
            {{ verticalLegend.text }}
          </text>
        </g>

        <path
          stroke="url(#gradient)"
          stroke-width="3"
          stroke-linejoin="round"
          stroke-linecap="round"
          fill="transparent"
          :d="chartPoints"
        ></path>
        <path fill="url(#:r1u:)" :d="chartFill"></path>

        <template v-if="mousePositionValue && cursor">
          <circle
            ref="mousePoint"
            :cx="mousePositionValue.x"
            :cy="mousePositionValue.y"
            r="6"
            fill="#041952"
            stroke="white"
            stroke-width="3"
            class="drop-shadow-xl"
          ></circle>
          <line
            :x1="mousePositionValue.x"
            :y1="usableHeight"
            :x2="mousePositionValue.x"
            :y2="mousePositionValue.y"
            stroke="#041952"
            stroke-width="2"
            stroke-dasharray="0 4 0"
          />
        </template>
      </g>
    </svg>
    <div
      v-else
      :style="{ height: `${chartHeight}px` }"
      class="flex items-center justify-center bg-gray-100 rounded-lg"
    ></div>

    <template v-if="mousePositionValue && tooltip">
      <div
        ref="tooltipAnchor"
        class="absolute"
        :style="{
          top: `${mousePositionValue.y}px`,
          left: `${mousePositionValue.x}px`,
        }"
      />

      <div
        ref="tooltipElement"
        class="shadow-xl bg-white border text-marine-600 absolute p-3 rounded"
      >
        <slot name="tooltip" :value="mousePositionValue" />
      </div>
    </template>
  </div>
</template>
