<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import axios from "@/lib/axios";

import Logo from "@/components/Logo.vue";
import PasswordInput from "@/components/auth/PasswordInput.vue";

const route = useRoute();
const router = useRouter();

const error = ref(false);
const password = ref<string>();
const passwordValid = ref(false);

const resetPassword = async () => {
  if (!password.value) return;
  else if (!passwordValid.value) return;

  try {
    await axios.post("/auth/reset/verify", {
      token: route.query.token as string,
      password: password.value,
    });

    router.push("/login");
  } catch (e) {
    error.value = true;
  }
};

const jwtData = computed(() => {
  try {
    const token = route.query.token as string;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    return undefined;
  }
});
</script>

<template>
  <div class="h-full w-full p-8 flex flex-col items-center">
    <a href="https://homaio.com">
      <Logo />
    </a>

    <div class="flex-1 flex justify-center items-center w-full">
      <div class="w-full max-w-lg text-marine-800">
        <div class="text-5xl font-bold font-display">Forgot password?</div>

        <template v-if="jwtData">
          <div class="font-medium mt-3">
            Set new password for
            <span class="font-bold">
              {{ jwtData.identity.email }}
            </span>
          </div>

          <PasswordInput
            v-model="password"
            class="mt-10"
            @update:valid="passwordValid = $event"
            @submit="resetPassword"
          />

          <TBtn
            class="w-full mt-6"
            large
            :disabled="!password || !passwordValid"
            @click="resetPassword"
          >
            Reset password
          </TBtn>

          <div
            v-if="error"
            class="rounded w-full px-6 py-4 font-medium bg-red-400 text-white mt-4 shadow"
          >
            It seems that the link you used is expired or has already been used.
          </div>
        </template>
        <template v-else>
          <div class="font-medium text-red-400 mt-10">
            The reset password link you just followed is invalid.
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
