<template>
  <button type="button" :class="classes">
    <div class="flex w-full items-center justify-center gap-2">
      <div class="flex-1">
        <TIcon v-if="icon && iconPosition === 'left'" :icon="icon" size="sm" />
      </div>
      <div class="text-center">
        <slot />
      </div>
      <div class="flex-1">
        <TIcon v-if="icon && iconPosition === 'right'" :icon="icon" size="sm" />
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { IconType } from "@/constants/iconsConstants";
import TIcon from "@/components/designSystem/TIcon.vue";
import { cva } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

type BtnVariantType = "primary" | "danger" | "primary-light";
type BtnStyleType = "solid" | "outline";
type BtnSizeType = "xs" | "sm" | "md" | "lg";
type IconPosition = "right" | "left";

const props = withDefaults(
  defineProps<{
    icon?: keyof typeof IconType;
    iconPosition?: IconPosition;
    variant?: BtnVariantType;
    styleType?: BtnStyleType;
    size?: BtnSizeType;
  }>(),
  {
    icon: undefined,
    iconPosition: "left",
    outlined: false,
    variant: "primary",
    styleType: "solid",
    size: "md",
  },
);

const btnVariants = cva(
  "inline-flex items-center px-3.5 transition rounded-lg disabled:pointer-events-none font-[600] font-display",
  {
    variants: {
      variant: {
        primary:
          "text-white bg-marine-900 hover:bg-marine-800 active:bg-marine-900 disabled:bg-marine-200",
        "primary-light":
          "text-marine-900 bg-marine-50 hover:bg-marine-25 active:bg-marine-100 disabled:text-marine-300",
        danger:
          "text-white bg-danger-400 hover:bg-danger-300 active:bg-danger-500 disabled:bg-danger-100",
      },
      size: {
        xs: "h-9 px-4 py-1",
        sm: "h-10 px-4 py-1.5",
        md: "h-11 px-5 py-2",
        lg: "h-13 px-6 py-3",
      },
      styleType: {
        solid: "",
        outline: "border bg-transparent",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        styleType: "outline",
        class: [
          "border-marine-900 text-marine-900",
          "hover:text-marine-500 hover:border-marine-500 hover:bg-transparent",
          "active:text-white active:border-marine-500",
          "disabled:hover:bg-marine-200 disabled:bg-marine-50 disabled:cursor-not-allowed",
        ],
      },
      {
        variant: "danger",
        styleType: "outline",
        class: [
          "border-red-400 text-red-400",
          "hover:text-red-300 hover:border-red-300 hover:bg-transparent",
          "active:text-white active:border-red-500",
          "disabled:hover:bg-red-100 disabled:bg-red-100 disabled:cursor-not-allowed",
        ],
      },
      {
        variant: "primary-light",
        styleType: "outline",
        class: [
          "border-marine-100 text-marine-400",
          "hover:text-marine-200 hover:border-marine-50 hover:bg-transparent",
          "active:text-marine-500 active:border-marine-100",
          "disabled:cursor-not-allowed",
        ],
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "md",
    },
  },
);

const classes = computed(() => {
  return twMerge(
    btnVariants({
      variant: props.variant,
      size: props.size,
      styleType: props.styleType,
    }),
  );
});
</script>
