<script setup lang="ts">
import { ref, computed } from "vue";
import { RouterView, useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { storeToRefs } from "pinia";

import banner from "@/assets/banner.png";

import Header from "@/containers/header/Header.vue";
import Notifications from "@/containers/Notifications.vue";
import LocaleSelector from "@/components/LocaleSelector.vue";

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const { user } = storeToRefs(authStore);

const loading = ref(true);

const loadRouter = async () => {
  await router.isReady();

  loading.value = false;
};

const showLanguageSelector = computed(() => {
  return (!user.value && route.meta.fullscreen) || route.name === "404";
});

loadRouter();
</script>

<template>
  <Transition
    enter-active-class="transition-opacity duration-150"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition-opacity duration-75 absolute"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-if="!loading"
      class="no-scrollbar flex h-full w-full flex-col items-center overflow-y-auto bg-gray-50"
    >
      <Notifications />

      <template v-if="!route.meta.fullscreen">
        <div
          class="absolute left-0 top-0 z-0 h-[420px] w-full border-b bg-cover"
          :style="{
            background: 'linear-gradient(90deg, #D8D9F1, #FAEAF4, #FFE6D2)',
          }"
        />
        <Header />
      </template>

      <main
        class="relative flex w-full flex-1 flex-col text-marine-800"
        :class="
          route.meta.fullscreen
            ? 'overflow-hidden'
            : 'mb-10 min-w-0 max-w-7xl px-4 py-8'
        "
      >
        <RouterView v-slot="{ Component }">
          <Transition name="fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
        <LocaleSelector
          v-if="showLanguageSelector"
          class="absolute bottom-4 right-4 z-20"
        />
      </main>
    </div>
    <div
      v-else
      class="flex h-full w-full items-center justify-center text-marine-700"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="45"
        height="45"
        viewBox="0 0 45 45"
        stroke="currentColor"
      >
        <g
          fill="none"
          fill-rule="evenodd"
          transform="translate(1 1)"
          stroke-width="2"
        >
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate
              attributeName="r"
              begin="1.5s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="1.5s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="1.5s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="6" stroke-opacity="0">
            <animate
              attributeName="r"
              begin="3s"
              dur="3s"
              values="6;22"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="3s"
              dur="3s"
              values="1;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-width"
              begin="3s"
              dur="3s"
              values="2;0"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="8">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.5s"
              values="6;1;2;3;4;5;6"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </div>
  </Transition>
</template>

<style scoped>
.gradient-container {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 420px;
  background: linear-gradient(94deg, #f3f0f7 7.5%, #fff4eb 97.56%);
  z-index: 0;
}
</style>
