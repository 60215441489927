import { defineStore } from "pinia";
import { ref } from "vue";

import type { Notification } from "@/types/notifications";

export const useNotificationsStore = defineStore("notifications", () => {
  let lastId = 0;
  const notifications = ref<Notification[]>([]);

  const addNotification = (
    text: string,
    status: "success" | "error",
    subtext?: string,
  ) => {
    const nextId = lastId + 1;
    lastId = nextId;

    notifications.value.push({
      id: nextId,
      text,
      status,
      subtext,
    });

    setTimeout(() => {
      dismissNotification(nextId);
    }, 3000);
  };

  const dismissNotification = (id: number) => {
    const index = notifications.value.findIndex((n) => n.id === id);
    if (index === -1) return;
    notifications.value.splice(index, 1);
  };

  return {
    notifications,

    addNotification,
    dismissNotification,
  };
});
