<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M14.6417 10.1319C13.8992 10.9058 12.9547 11.307 11.8696 11.307C10.7845 11.307 9.83992 10.9058 9.04036 10.1319C8.26935 9.32936 7.86957 8.38132 7.86957 7.29218C7.86957 6.20304 8.26935 5.22634 9.04036 4.45247C9.83992 3.64995 10.7845 3.27734 11.8696 3.27734C12.9547 3.27734 13.8992 3.64995 14.6417 4.45247C15.4413 5.22634 15.8125 6.17438 15.8125 7.29218C15.8125 8.40998 15.4413 9.32936 14.6417 10.1319Z"
      fill="currentColor"
    />
    <path
      d="M37.1396 29.6458C36.3971 30.4197 35.4526 30.8209 34.3675 30.8209C33.2823 30.8209 32.3378 30.4197 31.5382 29.6458C30.7672 28.8433 30.3674 27.8952 30.3674 26.8061C30.3674 25.717 30.7672 24.7403 31.5382 23.9664C32.3378 23.1639 33.2823 22.7913 34.3675 22.7913C35.4526 22.7913 36.3971 23.1639 37.1396 23.9664C37.9391 24.7403 38.3104 25.6883 38.3104 26.8061C38.3104 27.9239 37.9391 28.8433 37.1396 29.6458Z"
      fill="currentColor"
    />
    <path
      d="M12.5508 27.9921C10.6793 26.1136 9.72382 23.8471 9.72382 21.2125C9.72382 18.5778 10.6793 16.3113 12.5508 14.4527C14.4223 12.5545 16.6607 11.6152 19.2856 11.6152C21.9106 11.6152 24.1687 12.5545 26.0402 14.4527C27.9117 16.3113 28.8474 18.558 28.8474 21.2125C28.8474 23.867 27.9117 26.1136 26.0402 27.9921C24.1687 29.8705 21.9303 30.8097 19.2856 30.8097C16.6409 30.8097 14.4223 29.8705 12.5508 27.9921V27.9921ZM15.7447 17.6584C14.7892 18.6395 14.2993 19.8235 14.2993 21.2125C14.2993 22.6015 14.787 23.7656 15.7447 24.7665C16.742 25.7476 17.9215 26.2371 19.2856 26.2371C20.6497 26.2371 21.8491 25.7476 22.8266 24.7665C23.8041 23.7656 24.2917 22.5816 24.2917 21.2125C24.2917 19.8433 23.8041 18.6395 22.8266 17.6584C21.8491 16.6773 20.6695 16.1879 19.2856 16.1879C17.9018 16.1879 16.742 16.6773 15.7447 17.6584V17.6584Z"
      fill="currentColor"
    />
  </svg>
</template>
