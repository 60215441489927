<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";

import { usePopper } from "@/hooks/use-popper";

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

const { locale } = useI18n();

const languages = ref([{ value: "en" }, { value: "fr" }]);
const closingTimeout: Ref<ReturnType<typeof setTimeout> | undefined> = ref();

const [trigger, container] = usePopper({
  placement: "top-start",
});

const onMouseLeave = (open: boolean) => {
  if (!open) return;

  closingTimeout.value = setTimeout(() => {
    container.value.$el.dispatchEvent(
      new KeyboardEvent("keydown", {
        key: "Escape",
        bubbles: true,
        cancelable: true,
      }),
    );
  }, 200);
};

const onMouseEnter = (open: boolean) => {
  clearTimeout(closingTimeout.value);
  return !open && trigger.value.$el.click();
};

onMounted(() => {
  const storedLocale = localStorage.getItem("locale");
  if (storedLocale) {
    locale.value = storedLocale;
  }
});

watch(locale, () => {
  localStorage.setItem("locale", locale.value);
});
</script>

<template>
  <div>
    <Listbox v-slot="{ open }" v-model="locale" class="text">
      <div
        @mouseenter="() => onMouseEnter(open)"
        @mouseleave="() => onMouseLeave(open)"
      >
        <ListboxButton
          ref="trigger"
          class="relative cursor-pointer py-2 pl-3 pr-5 text-left sm:text-sm"
        >
          <span class="block truncate uppercase">{{ locale }}</span>
          <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center"
          >
            <i class="mdi mdi-chevron-down" aria-hidden="true" />
          </span>
        </ListboxButton>
        <ListboxOptions
          ref="container"
          class="divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none z-20 relative cursor-pointer"
          @mouseenter="() => onMouseEnter(open)"
        >
          <div>
            <ListboxOption
              v-for="item in languages"
              :key="item.value"
              :value="item.value"
              class="h-10 pl-3 py-2 text-left sm:text-sm"
            >
              <div class="mr-4 uppercase">
                {{ item.value }}
              </div>
            </ListboxOption>
          </div>
        </ListboxOptions>
      </div>
    </Listbox>
  </div>
</template>
