<template>
  <div class="flex h-full flex-col">
    <div class="hidden justify-between py-4 pl-4 lg:flex">
      <div />
      <TIcon icon="cross" class="cursor-pointer" @click="closeModal" />
    </div>
    <transition
      enter-active-class="transition-opacity duration-500"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition-opacity duration-500"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      appear
    >
      <div class="h-full space-y-4 overflow-y-scroll px-4 py-4 md:px-12">
        <h2 class="font-display text-xl font-[600] text-marine-900">
          {{ $t("sell.modal.transaction.title") }}
        </h2>
        <div class="relative flex flex-col gap-1">
          <div class="w-full rounded-lg border border-marine-50 bg-white p-4">
            <p class="mb-6">{{ $t("sell.modal.transaction.sellBonds") }}</p>
            <div class="mb-4 flex items-end gap-2">
              <input
                ref="bondsInput"
                v-model="transaction.bondToSell"
                class="w-20 text-4xl placeholder-marine-100"
                :class="{ 'text-danger-500': transaction.hasError }"
                type="number"
                min="0"
                placeholder="0"
                @input="validateNumberInput"
              />
              <p class="text-sm text-marine-200">
                = {{ transaction.euaToSell.toFixed(2) }} EUAs
              </p>
              <div class="translate-y-[-25%]">
                <img
                  class="h-4 w-4 rounded-full object-cover"
                  :src="euaImage"
                  alt=""
                />
              </div>
            </div>
            <p>
              <span class="cursor-pointer text-[#2B60EF]" @click="setMaxBond"
                >Max</span
              >
              {{ portfolio.availableBonds }}
              {{ $t("global.bonds", portfolio.availableBonds) }}
            </p>
          </div>
          <div class="relative m-0">
            <div
              class="absolute left-[50%] top-0 top-[-20px] flex translate-x-[-25%] items-center justify-center rounded-lg bg-marine-900 p-3"
            >
              <TIcon icon="arrowDown" color="neutral-50" />
            </div>
          </div>
          <div class="w-full rounded-lg border border-marine-50 bg-white p-4">
            <p class="mb-6">{{ $t("sell.modal.transaction.receive") }}</p>
            <p
              class="mb-4 text-4xl"
              :class="{
                'text-marine-100': !willReceiveMoney && !transaction.hasError,
                'text-marine-900': willReceiveMoney && !transaction.hasError,
                'text-danger-500': transaction.hasError,
              }"
            >
              {{ moneyToReceive }} €
            </p>
            <p class="hidden">
              <span class="text-[#2B60EF]">Max</span> {{ maxMoneyToReceive }} €
            </p>
          </div>
        </div>
        <div class="">
          <TCollapsableCallout
            :title="$t('sell.modal.transaction.infoCallout.title')"
            icon="warningTriangleOutline"
          >
            <p>
              {{ $t("sell.modal.transaction.infoCallout.content") }}
            </p>
          </TCollapsableCallout>
        </div>
        <div class="space-y-6">
          <SellModalPortfolioOverview v-model="transaction" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref, onMounted } from "vue";
import TCollapsableCallout from "@/components/designSystem/TCollapsableCallout.vue";
import SellModalPortfolioOverview from "@/containers/modals/sell/SellModalPortfolioOverview.vue";
import SellTransaction from "@/models/sell-transaction";
import euaImage from "@/assets/imgs/devises/eua.png";
import { usePortfolioStore } from "@/stores/portfolio";
import { type Portfolio } from "@/types/portfolio";
import { useOrdersStore } from "@/stores/orders";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import type { EstimatedSellingData } from "@/types/estimated-selling-data";

const { t: $t } = useI18n();

const portfolioStore = usePortfolioStore();
const ordersStore = useOrdersStore();
const portfolio: Portfolio = portfolioStore.portfolio!;

const bondsInput = ref();

const inputValid = ref<boolean>(true);

const emit = defineEmits(["modal-close", "next-step", "show-error"]);

const transaction = defineModel<SellTransaction>({ required: true });

const moneyToReceive = computed(() => {
  if (transaction.value.euaPrice === 0) return 0;

  return transaction.value.euaPrice.toFixed(2);
});

const willReceiveMoney = computed(() => {
  return transaction.value.euaPrice !== 0;
});

const maxMoneyToReceive = computed(() => {
  return (
    transaction.value.maxBond *
    transaction.value.euaToSell *
    transaction.value.euaPrice
  ).toFixed(2);
});

function isValidInput(input: HTMLInputElement) {
  if (!input.value) return true;

  const numericRegex = /^[0-9]+(\.[0-9]+)?$/;
  return numericRegex.test(input.value);
}

const validateNumberInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  inputValid.value = isValidInput(input);
  input.value = input.value.replace(/[^0-9]/g, "");

  if (inputValid.value) {
    transaction.value.bondToSell = Number(input.value);
  }
};

const updateEstimation = async () => {
  const estimation = await ordersStore.getEstimatedSellingData(
    transaction.value.bondToSell,
  );

  transaction.value.updateWithEstimation(estimation!);
};

const resetEstimation = async () => {
  const estimation: EstimatedSellingData = {
    custodyFees: 0,
    sellingPrice: 0,
    sellingAssetsQuantity: 0,
  };

  transaction.value.updateWithEstimation(estimation!);
};

const debouncedUpdateEstimation = _.debounce(updateEstimation, 200);

const setMaxBond = () => {
  transaction.value.bondToSell = transaction.value.maxBond;
};

onMounted(() => {
  bondsInput.value.focus();
});

watch(
  () => transaction.value.bondToSell,
  (number, oldNumber) => {
    transaction.value.hasError = false;
    if (number < 0 || oldNumber < 0) {
      transaction.value.bondToSell = 0;
    }

    if (number > transaction.value.maxBond) {
      showError($t("sell.modal.transaction.errors.insufficientFunds"));
    }

    if (!transaction.value.hasError) {
      debouncedUpdateEstimation();
    } else {
      resetEstimation();
    }
  },
);

const showError = (message: string) => {
  transaction.value.hasError = true;
  emit("show-error", message);
};

const closeModal = () => {
  emit("modal-close");
};
</script>
